import React, { FC, useMemo, useState } from 'react';
import {
  PageInfo,
  SortEnum,
  SubscriberEdge,
  useAudienceDomainSubscribersQuery,
  InputMaybe,
  OrderBy,
} from '../../generated/graphql';
import email from '../SuperTable/columns/email';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import { useSubscriberDrawer } from '../Subscribers/DetailDrawer';
import { usePagination } from '../SuperTable/Pagination';
import type { ColumnsType } from 'antd/es/table';
import { MinimalTable } from '../SuperTable';
import { Typography } from 'antd';
import { Key, SortOrder } from 'antd/lib/table/interface';
import Time from '../Time';

type TableState = {
  orderBy?: {
    field: string | Key | undefined,
    sort: SortOrder
  }
};

const getSortDirection = (sortDirection?: SortEnum) => {
  if (!sortDirection) {
    return null;
  }

  return sortDirection === SortEnum.Asc ? 'ascend' : 'descend';
};

const DomainSubscribersTable: FC<{ id: string }> = ({ id }) => {
  const projectId = useProjectId();
  const [tableState, updateTableState] = useState<TableState>();

  const { subscriberDrawer, showSubscriberDrawer } = useSubscriberDrawer();

  const { previousPage, nextPage, updatePageSize, first, before, after } = usePagination();

  let orderBy: InputMaybe<OrderBy> | undefined;

  if (tableState?.orderBy?.field) {
    orderBy = {
      field: tableState.orderBy.field.toString(),
      sort: tableState.orderBy.sort === 'descend' ? SortEnum.Desc : SortEnum.Asc,
    };
  }
  const { data, isLoading } = useAudienceDomainSubscribersQuery({
    projectId,
    id,
    first,
    before,
    after,
    orderBy: orderBy || {
      field: 'createdAt',
      sort: SortEnum.Desc,
    },
  });

  const columns: ColumnsType<SubscriberEdge> = useMemo(() => [
    email<SubscriberEdge>('Email', ['node'], 'email', {
      onClick: showSubscriberDrawer,
      subscriberIdIndex: ['node', 'id'],
      width: '60%',
    }),
    {
      title: 'Votes',
      key: 'voteCount',
      width: '10%',
      dataIndex: ['node', 'voteCount'],
      sorter: false,
      render: (voteCount) => (<Typography.Text>{voteCount}</Typography.Text>),
    },
    {
      title: 'Feedback',
      key: 'feedbackCount',
      width: '10%',
      dataIndex: ['node', 'feedbackCount'],
      sorter: false,
      render: (feedbackCount) => (<Typography.Text>{feedbackCount}</Typography.Text>),
    },
    {
      title: 'Subscribed',
      dataIndex: ['createdAt'],
      key: 'createdAt',
      sorter: true,
      width: '20%',
      sortOrder: orderBy?.field === 'createdAt' ? getSortDirection(orderBy.sort) : null,
      render: (value: string) => <Time timestamp={value} />,
    },
  ], [showSubscriberDrawer, orderBy?.field, orderBy?.sort]);

  const SubscriberTable = MinimalTable<SubscriberEdge>();

  return (
    <>
      <SubscriberTable
        first={first}
        totalCount={data?.project?.audienceDomain?.subscribers.totalCount}
        updatePageSize={updatePageSize}
        isLoading={isLoading}
        pageInfo={data?.project?.audienceDomain?.subscribers.pageInfo as PageInfo}
        columns={columns}
        previousPage={previousPage}
        data={data?.project?.audienceDomain?.subscribers.edges as SubscriberEdge[]}
        nextPage={nextPage}
        bordered
        onChange={(_, __, sorting) => {
          let field, sort;
          if (Array.isArray(sorting)) {
            sort = sorting[0].order;
            field = sorting[0].columnKey;
          } else {
            sort = sorting.order;
            field = sorting.columnKey;
          }

          if (sort === undefined) {
            updateTableState({
              ...tableState,
              orderBy: undefined,
            });
          } else {
            updateTableState({
              ...tableState,
              orderBy: { field, sort },
            });
          }
        }}
      />

      {subscriberDrawer}
    </>
  );
};

export default DomainSubscribersTable;
