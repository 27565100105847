import React, { FC } from 'react'
import { lnTokens } from '../../Router';
import { User } from '../../generated/graphql'
import { Avatar, AvatarProps as AntAvatarProps } from 'antd'
import UserAvatar from './UserAvatar'
import { GroupProps } from 'antd/lib/avatar'

export type UserAvatarGroup = {
  maxCount?: GroupProps['maxCount']
  size?: GroupProps['size']
  users: User[]
  gap?: AntAvatarProps['gap']
}

const UserAvatarGroup: FC<UserAvatarGroup> = ({
  maxCount,
  size,
  users,
  gap,
}) => {
  if (users.length < 1) return null

  return (
    <Avatar.Group
      maxCount={maxCount}
      size={size}
      maxStyle={{ backgroundColor: lnTokens.cosmicDust, color: lnTokens.comet }}
    >
      {
        users.map((user) => (
          <UserAvatar
            user={user}
            key={user.id}
            gap={gap}
          />
        ))
      }
    </Avatar.Group>
  )
}

export default UserAvatarGroup
