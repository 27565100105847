import React, { FC, useCallback, useEffect, useState } from 'react';
import { Card, Col, ConfigProvider, Row, Space, Spin, Typography, Upload, message } from 'antd';
import {
  UploadFieldImageFragment,
  useDirectUploadMutation,
} from '../../../generated/graphql';
import ImgCrop, { ImgCropProps } from 'antd-img-crop';
import { BlobUpload } from '@rails/activestorage/src/blob_upload';
import calculateChecksum from './calculateChecksum';
import Preview, { PreviewProps } from './Preview';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon';
import { faImage } from '@fortawesome/pro-regular-svg-icons';
const { Dragger } = Upload;

/* eslint-disable @typescript-eslint/ban-types */

function getBase64(img: RcFile | undefined, callback: Function) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  if (img) {
    reader.readAsDataURL(img);
  }
}

const VALID_HERO_IMAGE_UPLOAD_TYPES = ['image/png', 'image/jpeg', 'image/gif', 'video/mp4'];

type Props = {
  aspect?: ImgCropProps['aspect'];
  aspectSlider?: ImgCropProps['aspectSlider'];
  avatar?: PreviewProps['avatar'];
  image: UploadFieldImageFragment | null;
  imgCrop?: boolean;
  button?: (loading: boolean) => React.ReactNode;
  labelText?: string | null
  sublabelText?: string | null
  previewHeight?: string | null,
  onRemove: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onUpload: (signedId: string) => void;
  style?: React.CSSProperties;
  className?: string;
  readOnly?: boolean;
}

type UploadCardProps = {
  header: JSX.Element;
  content: JSX.Element;
};

export const UploadCard: FC<UploadCardProps> = (props: UploadCardProps) => {
  const { header, content } = props;
  return (
    <ConfigProvider typography={{
      style: {
        marginBottom: 1,
      },
    }}>
      <Card
        style={{ background: 'transparent',
          boxShadow: 'none',
          border: 'none',
          textWrap: 'nowrap',
          height: '100%',
        }}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <Row justify={'start'} style={{ paddingBottom: 8 }}>
          <FontAwesomeIcon icon={faImage} fontSize='22px'/>
        </Row>
        <Row justify={'start'}>
          <Col>
            <Row align={'bottom'}>{header}</Row>
            <Row align={'top'}>{content}</Row>
          </Col>
        </Row>
      </Card>
    </ConfigProvider>
  );
};

const UploadField: React.FC<Props> = ({
  image,
  button,
  onRemove,
  onUpload,
  readOnly = false,
  aspect,
  aspectSlider,
  avatar,
  imgCrop,
  labelText = 'Drop image or click to upload image',
  sublabelText = 'Upload a PNG or JPG',
  previewHeight,
  className = '',
  ...props
}) => {
  const { mutateAsync } = useDirectUploadMutation();
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null as unknown);

  useEffect(() => {
    if (image && image.url) {
      setPreviewUrl(image.url);
    }
  }, [setPreviewUrl, image]);

  const handleChange = useCallback(
    (info: UploadChangeParam<UploadFile>) => {
      if (info.file.status === 'uploading') {
        setLoading(true);
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl: string) => {
          setLoading(false);
          setPreviewUrl(imageUrl);
        });
      }
    },
    [setLoading, setPreviewUrl],
  );

  const customRequest = useCallback(
    async (options: {
      file: File;
      onProgress: Function;
      onError: Function;
      onSuccess: Function;
    }): Promise<void> => {
      const { file } = options;

      await mutateAsync({
        input: {
          checksum: await calculateChecksum(file),
          filename: file.name,
          contentType: file.type,
          byteSize: file.size,
        },
      }).then((value) => {
        const uploadBlob = value.createDirectUpload?.blob;
        if (!uploadBlob) {
          return;
        }
        const upload = new BlobUpload({
          file,
          directUploadData: {
            headers: JSON.parse(uploadBlob.headers) as Record<string, string>,
            url: uploadBlob.url,
          },
        });

        upload.xhr.addEventListener('progress', (event) => {
          const percent = (event.loaded / event.total) * 100;
          options.onProgress({ percent }, file);
        });

        upload.create((error: Error, response: object) => {
          if (error) {
            options.onError(error);
          } else {
            options.onSuccess(response, file);
            onUpload(uploadBlob.signedId);
          }
        });
      });
    },
    [mutateAsync, onUpload],
  );

  const handleRemove = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      setPreviewUrl(null);
      onRemove(event);
    },
    [setPreviewUrl, onRemove],
  );

  if (previewUrl) {
    return (
      <Space>
        <Preview
          avatar={avatar}
          src={previewUrl as string}
          filename={image?.filename}
          onRemove={handleRemove}
          className={className}
          previewHeight={previewHeight}
        />
      </Space>
    );
  }

  const handleBeforeUpload = (file: RcFile) => {
    if (VALID_HERO_IMAGE_UPLOAD_TYPES.indexOf(file.type) === -1) {
      message.error(
        'We don\'t support this file type. Please upload a .png, .jpg, or .gif',
      );
      return Upload.LIST_IGNORE;
    } else if (file.size > 10000000) {
      message.error(
        'Image size is too large. Please limit size to 10 MB or less',
      );
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  let uploadButton;
  if (button) {
    uploadButton = button(loading);
  } else {
    uploadButton = (
      loading ? <Spin /> :
        <UploadCard
          header={ <Typography.Text strong>{ labelText }</Typography.Text> }
          content={ <Typography.Paragraph type="secondary">{ sublabelText }</Typography.Paragraph> }
        />
    );
  }

  return imgCrop ?
    (
      <ImgCrop aspect={aspect} aspectSlider={aspectSlider} rotationSlider>
        <Dragger
          className={`uploader ${className}`}
          method='put'
          listType="picture-card"
          multiple={false}
          showUploadList={false}
          customRequest={customRequest}
          disabled={readOnly}
          onChange={handleChange}
          beforeUpload={file => handleBeforeUpload(file)}
          {...props}
          style={{background: '#FBFAF7', padding: 8}}
        >
          {uploadButton}
        </Dragger>
      </ImgCrop>
    )
    :
    (
      <Dragger
        className={`uploader ${className}`}
        method='put'
        listType="picture-card"
        multiple={false}
        showUploadList={false}
        customRequest={customRequest}
        disabled={readOnly}
        onChange={handleChange}
        beforeUpload={file => handleBeforeUpload(file)}
        style={{background: '#FBFAF7', padding: 8}}
        {...props}
      >
        {uploadButton}
      </Dragger>
    );
};

export default UploadField;
