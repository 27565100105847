import React from "react";
import { Form, Modal, Typography, Button, Space } from "antd";
import { OrganizeFeedbackSection } from "../OrganizeFeedbackSection";
import { SubscribeSection } from "../SubscribeSection";
import { feedbackLabel } from "../utils";
import { useFeedbackModalController } from "../hooks/useFeedbackModalController";
import { FeedbackableSelect } from "../../Select/FeedbackableSelect";
import useProjectId from "@launchnotes/common-hooks/useProjectId";
import { FeedbackTableFeedback } from "../FeedbackTable";
import {
  Feedback,
  OrganizeFeedbackModalQuery,
  useOrganizeFeedbackModalQuery,
} from "../../../generated/graphql";

const { Paragraph, Text } = Typography;

type OrganizeFeedbackModalProps = {
  feedbacks: FeedbackTableFeedback[] | Feedback[];
  visible: boolean;
  setVisible: (visible: boolean) => void;
  successCallback?: () => void;
};

export const OrganizeFeedbackModal: React.FC<OrganizeFeedbackModalProps> = ({
  feedbacks,
  visible,
  setVisible,
  successCallback,
}) => {
  const projectId = useProjectId();

  const { data } = useOrganizeFeedbackModalQuery({ projectId, feedbackId: feedbacks[0].id });

  const announcements = (data?.project.announcements?.nodes || []).filter((node): node is NonNullable<typeof node> => !!node);
  const ideas = (data?.project.ideas?.nodes || []).filter((node): node is NonNullable<typeof node> => !!node);
  const workItems = (data?.project.workItems?.nodes || []).filter((node): node is NonNullable<typeof node> => !!node);

  return (
    <>
      {data && data?.feedback && (
        <OrganizeFeedbackModalInternal
          feedbacks={feedbacks}
          visible={visible}
          setVisible={setVisible}
          ideas={ideas}
          announcements={announcements}
          workItems={workItems}
          data={data}
          successCallback={successCallback}
        />
      )}
    </>
  );
};

type OrganizeFeedbackModalInternalProps = OrganizeFeedbackModalProps & {
  data: OrganizeFeedbackModalQuery;
  ideas: { id: string, name: string }[];
  announcements: { id: string, name: string }[];
  workItems: { id: string, name: string }[];
};

const OrganizeFeedbackModalInternal: React.FC<OrganizeFeedbackModalInternalProps> = ({
  feedbacks,
  visible,
  setVisible,
  ideas,
  announcements,
  workItems,
  data,
  successCallback,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const { state, stateFunctions, mutators } = useFeedbackModalController({
    announcements,
    ideas,
    workItems,
    form,
    data,
    setVisbility: setVisible,
    successCallback,
  });
  const { feedbackType, options } = state;
  const { updateFeedbackType, onSubmit } = stateFunctions;
  const { organizeFeedback } = mutators;

  const [isSubscribeSelected, setSubscribeSelected] = React.useState(
    data?.feedback?.affectedCustomerEmail !== null
  );

  const handleCancel = () => {
    updateFeedbackType(undefined);
    setVisible(false);
  };

  const feedbackableValue = {
    key: data?.feedback?.feedbackable?.id,
    value: data?.feedback?.feedbackable?.name,
  };

  return (<>
    <Modal
      title="Organize Feedback"
      className="organize-feedback-form"
      centered
      open={visible}
      onCancel={handleCancel}
      onOk={() => onSubmit()}
      footer={[
        <Space size={"large"} className="organize-feedback-form footer">
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={onSubmit}
            disabled={loading || feedbackType === undefined}
          >
            Organize
          </Button>
        </Space>,
      ]}
    >
      <Paragraph className="organize-feedback-form description">
        Add feedback to ideas, roadmap items, or announcements.
      </Paragraph>
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          if (!!values.feedbackableId && feedbackType) {
            organizeFeedback(
              feedbacks,
              values.feedbackableId,
              feedbackType
            );
          }
        }}
      >
        <OrganizeFeedbackSection
          feedbackableType={feedbackType as LN.Feedbackables | undefined}
          setFeedbackableType={updateFeedbackType}
        />

        {feedbackType && (
          <Form.Item
            className="feedback-modal-select"
            label={`Select ${feedbackLabel(feedbackType).label}`}
            name={"feedbackableId"}
            initialValue={options?.find(({ id }) => id === feedbackableValue.key) ? feedbackableValue.key : undefined}
            required
            rules={[
              {
                type: "string",
                required: true,
                message:
                  "Please select an idea, roadmap item, or announcement.",
              },
            ]}
          >
            <FeedbackableSelect
              type={feedbackType}
              options={options}
              loading={loading}
              setLoading={setLoading}
              onIdeaCreated={onSubmit}
            />
          </Form.Item>
        )}

        {feedbackType === "WorkItem" &&
          data?.feedback?.affectedCustomerEmail && (
            <SubscribeSection
              description={
                <Text>
                  Subscribe <b>{data.feedback.affectedCustomerEmail}</b> to
                  roadmap item
                </Text>
              }
              isSelected={isSubscribeSelected}
              setIsSelected={setSubscribeSelected}
            />
          )}
      </Form>
    </Modal>
  </>);
};
