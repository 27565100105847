import React, { useState, useMemo, useCallback } from "react";
import type { MockedAnnouncementProps } from "./";
import { Button, Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";
import useCreateTestAnnouncement from "./hooks/useCreateTestAnnouncement";
import { WizardAnnouncementStepFragment } from '../../../generated/graphql';
const { Text, Paragraph } = Typography;

type Props = {
  project: WizardAnnouncementStepFragment;
  mockedAnnouncement: MockedAnnouncementProps;
};

const AnnouncementStep = (props: Props) => {
  const project: WizardAnnouncementStepFragment = props.project;
  const previousUrl = useMemo(() => `/projects/${project.id}/welcome/subscribers`, [project]);
  const [announcementPosted, setAnnouncementPosted] = useState<boolean>(false);
  const { createTestAnnouncement } = useCreateTestAnnouncement();

  const showAnnouncement = useCallback(() => {
    const announcement = document.getElementById("mocked-announcement");
    if (announcement) {
      announcement.style.display = "block";
      window.setTimeout(() => {
        announcement.style.opacity = "1";
        announcement.style.marginTop = "0";
      }, 5);
    }
    setAnnouncementPosted(true);
    createTestAnnouncement({
      projectId: project.id,
      headline: props.mockedAnnouncement.headline,
      contentMarkdown: props.mockedAnnouncement.content,
      shouldNotifyPageSubscribers: true,
      categories: props.mockedAnnouncement.categories,
    });
  }, [project, props.mockedAnnouncement, createTestAnnouncement]);

  return (
    <>
      <Row>
        <Col className="onboarding-fields announcement">
          { !announcementPosted ? (
            <>
              <Paragraph>
                <Text strong>
                  Now let’s create a test announcement to see LaunchNotes in
                  action.
                </Text>
              </Paragraph>
              <Button
                size="large"
                type="primary"
                block
                onClick={showAnnouncement}
              >
                Create Test Announcement
              </Button>
            </>
          ) : (
            <>
              <Paragraph><Text strong>Awesome!</Text></Paragraph>
              <Paragraph>
                The announcement was created and a notification email was sent
                to subscribers.
              </Paragraph>
              <Paragraph>
                Since you subscribed in the last step, that includes you (check it
                out in your email).
              </Paragraph>
              <Link to={`/projects/${project.id}/onboarding`}>
                <Button size="large" type="primary" block>
                  Go to dashboard
                </Button>
              </Link>
            </>
          ) }
        </Col>
      </Row>
      <Row justify="center" className="buttons-container">
        <Col>
          <Link to={previousUrl}>
            <Button size="small" type="text">Back</Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col className="step-progress">5/5</Col>
      </Row>
    </>
  );
};

export default AnnouncementStep;
