import { useCallback, useContext } from 'react';
import { message } from 'antd';
import {TokenContext} from '../Authenticated/TokenAuthenticated';
import BACKEND_HOST from '../API_URL';

const useKillSession = () => {
  const tokenContext = useContext(TokenContext);

  return useCallback(() => {
    tokenContext.clearToken();
    window.location.href = window.location.origin;
  }, [tokenContext]);
};

export default useKillSession;
