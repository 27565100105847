import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import Authenticated from './Authenticated';
import reportWebVitals from './reportWebVitals';
import { AppContext, ContextDataShape, defaultContextData } from './AppContext';
import { SplitSdk, SplitFactory } from '@splitsoftware/splitio-react';
import { FallbackRender } from '@sentry/react/dist/errorboundary';
import ErrorFallback from './ErrorFallback';
import SplitIO from '@splitsoftware/splitio-react/types/splitio/splitio';
import {
  init as initSentry,
  ErrorBoundary as SentryErrorBoundary,
} from '@sentry/react';
import {
  applyPolyfills,
  defineCustomElements,
} from '@launchnotes/embed/dist/loader';

export const environment =
  process.env.REACT_APP_VERCEL_ENV || process.env.NODE_ENV;

if (environment === 'production') {
  initSentry({
    dsn: 'https://3f28e4e060b54451abc839fd888ca5fa@o269410.ingest.sentry.io/5718206',
    release: process.env.RELEASE,
    environment: environment,
  });
}

const factory: SplitIO.ISDK = SplitSdk({
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_BROWSER_API_KEY as string,
    key: 'global',
  },
});

const audience = process.env.REACT_APP_VERCEL_GIT_COMMIT_REF
  ? 'https://branch.app.launchnotes.dev/graphql'
  : process.env.REACT_APP_LAUNCHNOTES_API_URL;

const App = () => {
  const [context, updateContext] =
    useState<ContextDataShape>(defaultContextData);

  useEffect(() => {
    if (context.user && environment === 'production') {
      const script = document.createElement('script');

      script.text = `
        var totango_options = {
          service_id: "SP-268310-01", 
          
          user: {
            id: '${context.user.id}',
            name: '${context.user.name}'
          },
          account: {
            id: '${context.organization.id}',
            name: '${context.organization.name}',
          },
          enableHierarchy: false
        };
        
        (function() {
          var tracker_name=window.totango_options.tracker_name||"totango";window.totango_tmp_stack=[];window[tracker_name]={go:function(){return -1;},setAccountAttributes:function(){},identify:function(){},track:function(t,o,n,a){window.totango_tmp_stack.push({activity:t,module:o,org:n,user:a}); return -1;}};
          var e = document.createElement('script'); e.type = 'text/javascript'; e.async = true;
          e.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'tracker.totango.com/totango4.0.3.js';
          var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(e, s);
        })();
      `;

      document.body.appendChild(script);
    }
  }, [context]);

  return (
    <SentryErrorBoundary fallback={ErrorFallback as FallbackRender}>
      <SplitFactory factory={factory}>
        <AppContext.Provider value={{ context, updateContext }}>
          <Router>
            <Authenticated />
          </Router>
        </AppContext.Provider>
      </SplitFactory>
    </SentryErrorBoundary>
  );
};

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<App />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

applyPolyfills().then(() => {
  defineCustomElements();
});
