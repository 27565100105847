import { useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useUpdateFeedbackMutation } from '../../../generated/graphql';

export const useUpdateFeedback = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (e?: Error) => void;
} = {}) => {
  const queryClient = useQueryClient();

  const { mutate } = useUpdateFeedbackMutation({
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['FeedbackTable']
      });
      message.success('Feedback updated');
      onSuccess?.();
    },
    onError(e?: Error) {
      message.error(`Feedback could not be updated ${e?.message}`);
      onError?.(e);
    },
  });

  return { updateFeedback: mutate };
};
