import { useProjectContext } from '../AppContext';
import { useFeatureFlags, DOMAIN_DRAWER_ACCESS } from '../FeatureFlags';

const useIsValidPlanLevel = (plans: Array<string>) => {
  const { features } = useFeatureFlags([DOMAIN_DRAWER_ACCESS]);

  const grantedAccess = features[DOMAIN_DRAWER_ACCESS];

  const project = useProjectContext();

  if (!project) return grantedAccess;

  const status = project.billingStatus;

  return plans.includes(status) || grantedAccess;
};

export default useIsValidPlanLevel;