import React, { FC } from "react";
import { Button, Space } from "antd";
import { Waypoint } from 'react-waypoint';
import { useThrottleFn } from 'ahooks';

type LoadMoreProps = {
  hasMore: boolean;
  loadMore: () => void;
  loading: boolean;
  scroll?: boolean;
  children?: React.ReactNode;
};

const LoadMore: FC<LoadMoreProps> = ({
  hasMore,
  loadMore,
  loading,
  scroll = false,
  children
}) => {

  const { run } = useThrottleFn(() => loadMore(), { wait: 2000 });

  if (!hasMore) return null;
  return (
    <Space>
      <Waypoint
        onEnter={() => scroll && run()}
      />
      <Button type="primary" ghost onClick={run} loading={loading}>
        {children}
      </Button>
    </Space>
  );
};

export default LoadMore;
