import React from 'react';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faLightbulb,
  faMegaphone,
  faFlask,
} from '@fortawesome/pro-regular-svg-icons'

type FeedbackLabel = {
  label: string;
  icon: JSX.Element;
  emptyState: string;
  placeholder: string;
}

export const feedbackLabel = (feedbackableType: string | null | undefined): FeedbackLabel => {
  const response: FeedbackLabel = {
    label: '',
    icon: <></>,
    emptyState: '',
    placeholder: '',
  };
  if (feedbackableType === 'Idea') {
    response.label = 'idea';
    response.icon = <FontAwesomeIcon icon={faLightbulb} />;
    response.emptyState = 'Create an idea to organize feedback to it';
    response.placeholder = 'Type to search or create an Idea';
  } else if (feedbackableType === 'Announcement') {
    response.label = 'announcement';
    response.icon = <FontAwesomeIcon icon={faMegaphone} />;
    response.emptyState = 'Create an announcement to organize feedback to it';
    response.placeholder = 'Type to search Roadmap Items';
  } else if (feedbackableType === 'WorkItem') {
    response.label = 'roadmap item';
    response.icon = <FontAwesomeIcon icon={faFlask} />;
    response.emptyState = 'Create a roadmap item to organize feedback to it';
    response.placeholder = 'Type to search Announcements';
  }
  return response;
};