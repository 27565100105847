import { useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useCreateFeedbackMutation } from '../../../generated/graphql';

export const useCreateFeedback = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (e?: Error) => void;
} = {}) => {
  const queryClient = useQueryClient();

  const { mutate } = useCreateFeedbackMutation({
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['FeedbackTable']
      });
      message.success('Feedback successfully created');
      onSuccess?.();
    },
    onError(e?: Error) {
      message.error(`Feedback could not be created ${e?.message}`);
      onError?.(e);
    },
  });

  return { createFeedback: mutate };
};
