import React, { CSSProperties, FC, ReactNode, useMemo } from 'react';
import chroma from 'chroma-js';
import { Badge, Space, Tooltip, Typography } from 'antd';
import { Maybe } from '../../generated/graphql';
import { lnTokens } from '../../Router';

const SentimentIndicator: FC<{ sentimentScore?: Maybe<number>, titleOverride?: string, style?: CSSProperties, displayScore?: boolean}> = ({ sentimentScore, titleOverride, displayScore = false, ...props }) => {
  const scale = chroma.scale([lnTokens.error, lnTokens.venus, lnTokens.titan]).domain([-1, 1]);
  const roundedScore: string | null = useMemo(() => {
    if (sentimentScore === null) return null;
    return sentimentScore.toFixed(2);
  }, [sentimentScore]);

  let title: ReactNode;
  if (sentimentScore !== null) {
    title = <>
      {
        titleOverride || (<>Sentiment score: {roundedScore}.</>)
      }
      <br/>
      <br/>
      <>Scores are between -1 and 1</>
    </>;
  } else {
    title = 'A sentiment score could not be determined.';
  }

  if (displayScore && sentimentScore !== null) {
    return (
      <Tooltip title={title}>
        <Space>
          <Typography.Text />
          {
            displayScore && <Badge color={scale(sentimentScore).hex()} text={roundedScore} />
          }
        </Space>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={title}>
      <Typography.Text />
    </Tooltip>
  );
};

export default SentimentIndicator;