import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Table,
  Space,
  Typography,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import useProjectId from '@launchnotes/common-hooks/useProjectId';

const { Paragraph, Text, Title } = Typography;

type InvalidPlanType = {
  email: string;
  name: string;
}

const InvalidPlanPanel: FC = () => {
  const projectId = useProjectId();
  const BILLING_PATH = `/projects/${projectId}/admin/billing`;

  const dummyItems: Array<InvalidPlanType> = [
    {
      email: 'aryan@rapture.free',
      name: 'Andrew Ryan',
    },
    {
      email: 'ww@rv.lab',
      name: 'Heisenberg',
    },
    {
      email: 'themin@kyrat.gov',
      name: 'Pagan Min',
    },
  ];

  const columns: ColumnsType<InvalidPlanType> = [
    {
      title: 'Email',
      key: 'email',
      render: (item: InvalidPlanType) => (<Paragraph>{item.email}</Paragraph>),
    },
    {
      title: 'Name',
      key: 'name',
      render: (item: InvalidPlanType) => (<Paragraph>{item.name}</Paragraph>),
    },
  ];

  return (
    <Space>
      <Table
        columns={columns}
        dataSource={dummyItems}
        pagination={false}
      />
      <Space>
        <Title>Access domain level info</Title>
        <Text>Upgrade to a business plan to see consolidated and detailed information related to each domain.</Text>
        <Button type='primary'><Link to={BILLING_PATH}>Upgrade to Business</Link></Button>
      </Space>
    </Space>
  );
};

export default InvalidPlanPanel;