import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Input, Row, Space, Typography, theme } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { PageHeader as ProPageHeader } from '@ant-design/pro-layout';
import styled from 'styled-components';
const { useToken } = theme;

type EditableInput = {
  id?: string;
  value?: string | null;
  onChange?: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  placeholder?: string;
};

type Props = {
  children?: React.ReactNode;
  titleText?: React.ReactNode;
  titleTag?: React.ReactNode;
  titleTextEditable?: EditableInput;
  secondaryText?: React.ReactNode;
  secondaryTextEditable?: EditableInput;
};

const PageHeaderWrapper = styled.div`
  .ant-page-header-heading-extra {
    align-self: center;
  }
  .ant-page-header-content {
    padding-block-start: 0;
  }
  .ant-page-header-heading-title {
    max-width: 800px;
   white-space: break-spaces;
  }
`

const PageHeader: FC<Props> = ({
  titleText,
  titleTag,
  secondaryText,
  children,
  ...props
}) => {
  const { token } = useToken();

  /* Style lock: Override background color */
  return (
    <PageHeaderWrapper>
    <ProPageHeader
      title={
        <ContentEditable
          type='title'
          text={titleText}
          tag={titleTag}
          editable={!!props.titleTextEditable}
          {...props.titleTextEditable}
        />
      }

      extra={children}
      style={{
        backgroundColor: token.colorBgLayout,
        padding: 0,
        paddingTop: '4px'
      }}
    >
      {
        (secondaryText || !!props.secondaryTextEditable) && (
          <ContentEditable
            type='subtitle'
            text={secondaryText}
            tag={titleTag}
            editable={!!props.secondaryTextEditable}
            {...props.secondaryTextEditable}
          />
        )
      }
      </ProPageHeader>
      </PageHeaderWrapper>
  );
};

type TitleProps = {
  type?: 'title' | 'subtitle';
  id?: string;
  text?: ReactNode;
  tag?: ReactNode;
  editable?: boolean;
} & EditableInput;

const ContentEditable: FC<TitleProps> = ({
  id,
  type = 'title',
  tag,
  text,
  editable = false,
  value,
  onChange,
  placeholder,
}) => {
  const [localTitle, updateLocalTitleText] = useState<
    string | undefined | null
  >(value);

  useEffect(() => {
    if (value !== localTitle) {
      updateLocalTitleText(value);
    }
  }, [localTitle, value]);

  let InputElement, TypographyElement, size;
  if (type === 'title') {
    InputElement = Input;
    TypographyElement = Typography.Title;
    size = 'large';
  } else {
    InputElement = Input.TextArea;
    TypographyElement = Typography.Text;
    size = 'middle';
  }

  if (editable) {
    return (
      <InputElement
        id={id}
        className={type}
        size={size}
        defaultValue={localTitle as string}
        onChange={(
          event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
        ) => {
          updateLocalTitleText(event.target.value);
          onChange && onChange(event);
        }}
        placeholder={placeholder}
        bordered={false}
        autoSize
      />
    );
  } else {
    return (
      <TypographyElement className={type} level={2} style={{ padding: 0 }} data-cy='page-header-title'>
        <Space>
          {text}
          {tag}
        </Space>
      </TypographyElement>
    );
  }
};

export default PageHeader;
