import { PropsWithChildren } from 'react'
import { Modal } from 'antd';
import type { ModalStaticFunctions } from 'antd/es/modal/confirm'

/*let modal: Omit<ModalStaticFunctions, 'warn'> & {
    useModal: typeof AntModal.useModal;
  } = AntModal;*/

let modal: Omit<ModalStaticFunctions, 'warn'>

const AntdGlobals: React.FC<PropsWithChildren> = () => {
    const [instance, context] = Modal.useModal();
    modal = instance;

    return context;
}
/*
export default () => {
  const staticFunction = App.useApp();
  modal = {
    ...staticFunction.modal,
    useModal: AntModal.useModal,
  };
  return null;
};
*/

export default AntdGlobals
export { modal };
