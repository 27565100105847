import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import FontAwesomeIcon from "@launchnotes/icons/FontAwesomeIcon";
import { Button, Drawer, Form, Space } from "antd";
import React, { FC, PropsWithChildren, useCallback, useState } from "react";
import { useMemo } from "react";
import styled, { css } from "styled-components";

type FilterDrawerWrapperProps = {
  filterButtonContent: React.ReactNode;
  activeFilterCount?: number;
  onClearFilters?: () => void;
  forceRender?: boolean;
};

const FilterButton = styled(Button)<{ active?: boolean }>`
  box-shadow: none;

  &:hover {
    color: #126c9f !important;
    border-color: #126c9f !important;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #f7f5ee;
      &:hover {
        color: rgba(25, 42, 62, 0.88) !important;
        border-color: #deddd6 !important;
        background-color: #deddd6;
      }
    `}
`;

const ClearButton = styled(Button)`
  box-shadow: none;
  background-color: #f7f5ee;
  &:hover {
    color: rgba(25, 42, 62, 0.88) !important;
    border-color: #deddd6 !important;
    background-color: #deddd6;
  }
`;

export const FilterDrawerWrapper: FC<PropsWithChildren<FilterDrawerWrapperProps>> = ({
  filterButtonContent,
  activeFilterCount,
  onClearFilters,
  children,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Space.Compact direction="horizontal">
        <FilterButton
          type="default"
          onClick={showDrawer}
          icon={<FontAwesomeIcon icon={faFilter} />}
          active={activeFilterCount && activeFilterCount > 0}
        >
          {filterButtonContent}
        </FilterButton>
        {activeFilterCount > 0 && (
          <ClearButton type="default" onClick={onClearFilters}>
            Clear all
          </ClearButton>
        )}
      </Space.Compact>

      <Drawer
        title="Filters"
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button
              type="link"
              onClick={() => {
                onClearFilters();
              }}
            >
              Clear all
            </Button>
          </Space>
        }
        {...rest}
      >
        {children}
      </Drawer>
    </>
  );
};

type FilterDrawerProps = {
  filters: Record<string, any>;
  updateFilters: (filters: Record<string, any>) => void;
};

const FilterDrawer: FC<PropsWithChildren<FilterDrawerProps>> = ({
  filters,
  updateFilters,
  children,
}) => {
  const activeFilterCount = useMemo(
    () => Object.keys(filters).length,
    [filters]
  );

  const filterButtonContent = useMemo(() => {
    if (activeFilterCount > 0) {
      return <>Filters ({activeFilterCount})</>;
    } else {
      return <>Filters</>;
    }
  }, [activeFilterCount]);

  const clearFilters = useCallback(() => {
    let obj = { ...filters };
    Object.keys(filters).forEach((key) => {
      obj[key] = undefined;
    });

    updateFilters(obj);
  }, [filters, updateFilters]);

  return (
    <FilterDrawerWrapper
      filters={filters}
      updateFilters={updateFilters}
      filterButtonContent={filterButtonContent}
      activeFilterCount={activeFilterCount}
      onClearFilters={clearFilters}
    >
      <Form layout="vertical">{children}</Form>
    </FilterDrawerWrapper>
  )
}


export default FilterDrawer;
