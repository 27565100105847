import React, { useCallback, useEffect, useState } from 'react';
import { useUserContext } from '../../../AppContext';
import { Button, Card, Col, Form, Image, Input, message, Row, Select, Typography } from "antd";
import { Redirect } from "react-router-dom";
import { 
  useCreateOrganizationProfileMutation,
  UserRoleEnum, 
  UserReferralSourceEnum, 
} from "../../../generated/graphql";
import LoadingTakeover from '../../../components/Loading/Takeover';

const { Text, Title, Paragraph } = Typography;
const { Option } = Select;

const OnboardingSetup = () => {
  const user = useUserContext();
  const [loading, setLoading] = useState<Boolean>(false);
  const [form] = Form.useForm();

  const {mutate} = useCreateOrganizationProfileMutation({
    onSuccess: (data) => {
      console.log("Success")
      const id = data.createOrganizationProfile.projectId
      window.location.href = `/projects/${id}`
    },
    onError: (e) => { 
      console.log(e)
      message.error(`Could not create the organization`)
    }
  });

  const handleSubmit = useCallback((name: string, userRole: UserRoleEnum, userReferralSource: UserReferralSourceEnum) => {
    setLoading(true);
    console.log(`Loading: ${loading}`)
    mutate({
      name: name, 
      creatorId: user.id, 
      userRole: userRole, 
      userReferralSource: userReferralSource
    });
  }, [mutate]);

  return (
    <Row style={{minHeight: "100vh"}} justify="space-around" align="middle">
      <Col
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row style={{minWidth: "440px", padding: "16px 0px 106px"}}>
          <Col span={24}>
            { loading ? (
              <>
                <LoadingTakeover title="Prepping your seat on the rocket..." />
              </>
            ) : (
              <>
                <Row justify={'center'}>
                  <Image alt="Logo" preview={false} height={48} src="https://assets.workoscdn.com/app-branding/environment_01F7C1XGHT2S014TCMHT5Q9E9R/01HWB4ZZC08N8AV7TFWZPM5F5Y" />
                </Row>
                <Paragraph style={{textAlign: "center"}}>
                  <Title style={{fontSize: "24px"}}> Welcome to LaunchNotes!</Title>
                  <Text>After these 3 quick questions you’ll have access to your trial.</Text>
                </Paragraph>
                <Card bordered style={{padding: "32px", borderColor: "#241E012B"}}>
                <Form
                  layout="vertical"
                  form={form}
                  initialValues={{billingEmail: user.email}}
                  onFinish={(values) => handleSubmit(values.name, values.userRole, values.userReferralSource)}
                >
                  <Form.Item
                    label="What's your company name?"
                    name="name"
                    rules={[
                      { required: true, message: 'Required' },
                    ]}
                  >
                    <Input
                      placeholder="Your company name"
                    />
                  </Form.Item>

                  <Form.Item
                    label="What is your role?"
                    name="userRole"
                    rules={[
                      { required: true, message: 'Required' },
                    ]}
                  >
                    <Select>
                      <Option value="customerSuccess">Customer success</Option>
                      <Option value="customerSupport">Customer support</Option>
                      <Option value="engineering">Engineering</Option>
                      <Option value="executive">Executive</Option>
                      <Option value="marketing">Marketing</Option>
                      <Option value="product">Product</Option>
                      <Option value="all">All of the above</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Where did you first hear about us?"
                    name="userReferralSource"
                    rules={[
                      { required: true, message: 'Required' },
                    ]}
                  >
                    <Select>
                      <Option value="search_engine">Search engine</Option>
                      <Option value="recommendation">Recommended by a friend or community</Option>
                      <Option value="blog_publication">A blog or publication</Option>
                      <Option value="powered_by">Saw another LaunchNotes customer's page</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                </Form>
                <Button
                  key="submit"
                  size="large"
                  type="primary"
                  block
                  onClick={() => form.submit()}
                >
                  Continue
                </Button>
                </Card>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );

};

export default OnboardingSetup;
