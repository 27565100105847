import React from 'react';
import { Modal, Radio, Space, Typography } from 'antd';
import { OtherUser, OtherProject, Project } from '../../AppContext';

export const RequestAccessModal: React.FC<{
  project: OtherProject | null;
  visible: boolean;
  subject: string;
  body: string;
  setVisible: (visible: boolean) => void;
}> = (props) => {

  const [selectedAdmin, setSelectedAdmin] = React.useState<OtherUser | undefined>();
  const mailtoBody = selectedAdmin && encodeURIComponent(`Hi${selectedAdmin.firstName ? ` ${selectedAdmin.firstName}` : ''},\r\n\r\n${props.body}`);
  const mailtoUrl = selectedAdmin && `mailto:${selectedAdmin.email}?subject=${props.subject}&body=${mailtoBody}`;

  const close = React.useCallback(() => {
    props.setVisible(false);
    setSelectedAdmin(undefined);
  }, [props]);

  return (
    (<Modal
      title="This page is restricted"
      className="request-access-modal"
      open={props.visible}
      onCancel={close}
      okText="Send a request email"
      okButtonProps={{
        href: mailtoUrl,
        target: '_blank',
        disabled: !selectedAdmin,
      }}
      destroyOnClose
    >
      <Typography.Paragraph className="instructions">
        Ask one of the admins to give you permission:
      </Typography.Paragraph>
      <Space>
        <Radio.Group
          value={selectedAdmin?.id}
          onChange={(e) => {
            const admin = props.project?.admins?.find((a) => a.id === e.target.value);
            setSelectedAdmin(admin);
          }}
        >
          {props.project?.admins?.map((u) => (
            <Radio value={u.id} key={u.id}>
              <Typography.Text>
                {u.name?.trim() ? u.name : u.email}
              </Typography.Text>
            </Radio>
          )) || (
            <Typography.Text>
              No admins found.
            </Typography.Text>
          )}
        </Radio.Group>
      </Space>
    </Modal>)
  );
};

export const DashboardRequestAccessModal: React.FC<{
  project?: Project;
  visible: boolean;
  subject: string;
  body: string;
  setVisible: (visible: boolean) => void;
}> = (props) => {

  const [selectedAdmin, setSelectedAdmin] = React.useState<OtherUser | undefined>();
  const mailtoBody = selectedAdmin && encodeURIComponent(`Hi${selectedAdmin.firstName ? ` ${selectedAdmin.firstName}` : ''},\r\n\r\n${props.body}`);
  const mailtoUrl = selectedAdmin && `mailto:${selectedAdmin.email}?subject=${props.subject}&body=${mailtoBody}`;

  const close = React.useCallback(() => {
    props.setVisible(false);
    setSelectedAdmin(undefined);
  }, [props]);

  return (
    (<Modal
      title="This page is restricted"
      className="request-access-modal"
      open={props.visible}
      onCancel={close}
      okText="Send a request email"
      okButtonProps={{
        href: mailtoUrl,
        target: '_blank',
        disabled: !selectedAdmin,
      }}
      destroyOnClose
    >
    </Modal>)
  );
};
