import { useState, useCallback } from "react";
import { useDebounceFn } from 'ahooks';
import { message } from 'antd';
import { useUpdateOnboardingTasks } from "../../Tasks/hooks/UpdateOnboardingTasks";
import { useInvalidateQuery } from '@launchnotes/common-hooks/useInvalidateQuery';
import {
  UpdateOnboardingTasksAttributes,
  UpdateOnboardingProjectMutation,
  UpdateProjectAttributes,
  useWizardQuery,
  useUpdateOnboardingProjectMutation,
  useWizardRemoveLogoMutation,
} from '../../../../generated/graphql';
import useProjectId from '@launchnotes/common-hooks/useProjectId';

type UpdateOnboardingFormattedError = {
  name: String;
  errors: Array<String>;
}

export const useUpdateProject = (project: {
  id: string,
  emailSettings: {
    id: string,
  },
}) => {
  const [errors, setErrors] = useState<Array<UpdateOnboardingFormattedError>>([]);
  const [updateOnboardingTasks] = useUpdateOnboardingTasks();
  const projectId = useProjectId();
  const { mutate, isPending } = useUpdateOnboardingProjectMutation();
  const [invalidate] = useInvalidateQuery(useWizardQuery.getKey({projectId}));

  const { run } = useDebounceFn(useCallback(
    (projectChanges: Omit<UpdateProjectAttributes, 'id'>) => {
      const onboardingTasks: UpdateOnboardingTasksAttributes = {};
      const mailAppSettingChanges: {
        logo?: UpdateProjectAttributes['logo'],
        primaryColor?: UpdateProjectAttributes['primaryColor'],
      } = {};

      if (projectChanges.slug) {
        onboardingTasks.claimSubdomain = true;
      }

      if (projectChanges.logo) {
        onboardingTasks.uploadLogo = true;
        mailAppSettingChanges.logo = projectChanges.logo;
      }
      if (projectChanges.primaryColor) {
        onboardingTasks.customizeColors = true;
        mailAppSettingChanges.primaryColor = projectChanges.primaryColor;
      }

      mutate({
        project: {
          ...projectChanges,
          id: project.id,
        },
        mailAppSetting: {
          ...mailAppSettingChanges,
          id: project.emailSettings.id,
        },
      }, {
        onSuccess: (data: UpdateOnboardingProjectMutation) => {
          const errors = data?.updateProject?.errors;
          if (errors && errors?.length > 0) {
            const formattedErrors = errors?.map((error: any) => {
              let attribute = error["path"][1];
              return {
                name: attribute,
                errors: [`${attribute.charAt(0).toUpperCase() + attribute.slice(1)} ${error["message"]}`]
              }
            });
            setErrors(formattedErrors);
          } else {
            setErrors([]);
            updateOnboardingTasks(onboardingTasks);
            invalidate();
          }
        },
        onError: () => { message.error('There was a problem updating your settings') },
      });
    }, [mutate, project.emailSettings.id, project.id, updateOnboardingTasks, invalidate]),
    { wait: 500 },
  );

  return {
    updateProject : run,
    isLoading: isPending,
    errors,
  };
}

export const useRemoveLogo = (project: {
  id: string,
  emailSettings: {
    id: string,
  },
}) => {
  const [updateOnboardingTasks] = useUpdateOnboardingTasks();
  const projectId = useProjectId();
  const [invalidate] = useInvalidateQuery(useWizardQuery.getKey({projectId}));
  const { mutate } = useWizardRemoveLogoMutation({
    onSuccess: () => {
      updateOnboardingTasks({ uploadLogo: false });
      invalidate();
    },
    onError: () => {
      message.error('There was an error removing this logo');
    },
  });

  const onSubmit = useCallback(() => {
    mutate({
      projectId: project.id,
      emailSettingsId: project.emailSettings.id,
    });
  }, [mutate, project.id, project.emailSettings.id]);

  return [onSubmit];
};
