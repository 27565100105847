import React, { FC, useMemo } from 'react';

import {
  HasEvent,
  HasNotification,
  HasFeedback,
  FeedbackInfo,
  AnnouncementInfo,
  ProjectSubscriptionInfo,
  WorkItemInfo,
} from './types';
import { Importance } from '../../../generated/graphql';

import LowImportance from '@launchnotes/icons/LowImportance';
import MediumImportance from '@launchnotes/icons/MediumImportance';
import HighImportance from '@launchnotes/icons/HighImportance';

import {
  Tag,
  Typography,
} from 'antd';

const ImportanceIndicator: FC<HasFeedback> = ({ feedback }) => {
  if (!feedback.importance) return <></>;

  switch (feedback.importance) {
  case Importance.High:
    return <HighImportance />;
  case Importance.Medium:
    return <MediumImportance />;
  case Importance.Low:
    return <LowImportance />;
  }
};

const FeedbackTitle: FC<FeedbackInfo> = ({ feedback }) => {
  if (feedback.feedbackable) {
    const feedbackableType: string = feedback.feedbackable.__typename ?? '';
    return (
      <Typography.Paragraph style={{ marginBottom: 0 }}>
        Gave <Typography.Text strong>Feedback</Typography.Text> on {feedbackableType} <Typography.Link target="_blank" href={feedback.feedbackable.privatePermalink}>{feedback.feedbackable.name}{' '}</Typography.Link>
        <Typography.Text>
          <ImportanceIndicator feedback={feedback} />
        </Typography.Text>
      </Typography.Paragraph>
    );
  }

  return (
    <Typography.Text>
      Gave general <Typography.Text strong>Feedback</Typography.Text>
    </Typography.Text>
  );
};

const capitalized = (item: string) => {
  return item.charAt(0).toUpperCase() + item.slice(1);
};

const AnnoucnementTitle: FC<AnnouncementInfo> = ({ announcement, notification }) => {
  const transport = notification?.transport.__typename === 'SlackAppTransport' ? 'Slack' : 'Email';
  const notificationStatus = useMemo(() => {
    if (transport !== 'Email') return <></>;
    if (!notification?.status) return <></>;

    return (
      <Tag>{capitalized(notification?.status)}</Tag>
    );
  }, [notification?.status, transport]);

  if (!notification) {
    return <Typography.Text>{announcement.name}</Typography.Text>;
  }

  return (
    <Typography.Text>
      <Typography.Text strong>Sent</Typography.Text> Announcement <Typography.Link ellipsis style={{ maxWidth: '400px'}} target="_blank" href={announcement.privatePermalink}>{announcement.name}</Typography.Link> via {transport} {notificationStatus}
    </Typography.Text>
  );
};

const ProjectSubscriptionTitle: FC<ProjectSubscriptionInfo> = ({
  event,
  projectSubscription,
}) => {
  let type = '';
  let preposition = 'to';

  switch(event.eventType) {
  case 'project_subscription.created':
    type = 'Subscription Created';
    break;
  case 'project_subscription.confirmed':
    type = 'Subscription Confirmed';
    break;
  case 'project_subscription.unsubscribed':
    type = 'Unsubscribed';
    preposition = 'from';
    break;
  case 'project_subscription.updated':
    type = 'Subscription Updated';
    preposition = 'to';
    break;
  case 'project_subscription.resubscribed':
    type = 'Resubscribed';
    break;
  default:
    type = event.eventType;
    break;
  }

  return (
    <Typography.Paragraph style={{ marginBottom: 0 }}>
      <Typography.Text strong>{type}</Typography.Text> {preposition} {event.project.name}
    </Typography.Paragraph>
  );
};

const WorkItemTitle: FC<WorkItemInfo> = ({
  event,
  workItem,
}) => {
  let type = '';
  let preposition = 'to';

  if (event.eventType === 'work_item.update_added') {
    return (
        <Typography.Text>
          Received an update regarding Work Item <Typography.Link target="_blank" href={workItem.privatePermalink}>{workItem.name}</Typography.Link>
        </Typography.Text>
    )
  }

  switch(event.eventType) {
    case 'work_item.subscribed':
      type = 'Subscribed';
      break;
    case 'work_item.unsubscribed':
      type = 'Unsubscribed';
      preposition = 'from';
      break;
    default:
      type = event.eventType;
      break;
  }

  return (
    <Typography.Paragraph style={{ marginBottom: 0 }}>
      <Typography.Text strong>{type}</Typography.Text> {preposition} Work Item <Typography.Link target="_blank" href={workItem.privatePermalink}>{workItem.name}</Typography.Link>
    </Typography.Paragraph>
  );
};

const EventRowTitle: FC<HasEvent & HasNotification> = ({ event, notification }) => {
  switch(event.eventObject.__typename) {
  case 'Idea':
    return (
      <Typography.Paragraph style={{ marginBottom: 0 }}>
        <Typography.Text strong>{event.eventType === 'idea.voted' ? 'Voted' : 'Unvoted' }</Typography.Text> for Idea <Typography.Link target="_blank" href={event.eventObject.privatePermalink}>{event.eventObject.name}</Typography.Link>
      </Typography.Paragraph>
    );
  case 'Feedback':
    return <FeedbackTitle event={event} feedback={event.eventObject} />;
  case 'Announcement':
    return <AnnoucnementTitle
      event={event}
      announcement={event.eventObject}
      notification={notification}
    />;
  case 'ProjectSubscription':
    return (
      <ProjectSubscriptionTitle
        event={event}
        projectSubscription={event.eventObject}
      />
    );
  case 'WorkItem':
    return (
      <WorkItemTitle
        event={event}
        workItem={event.eventObject}
      />
    );
  default:
    throw new Error('unknown event type');
  }
};

export default EventRowTitle;