import React, { useEffect, useState } from 'react';
import { Button, Layout, Space, Alert, Typography, Col, Row } from 'antd';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import { PageHeader } from '@launchnotes/components/Layout';
import { FeedbackTable } from '@launchnotes/components/Feedback/FeedbackTable';
import { CreateFeedbackModal } from '@launchnotes/components/Feedback/modals/CreateFeedbackModal';
import { useBreadcrumbs } from '../../AppContext/Breadcrumbs';
import ImportFeedbackButton from '@launchnotes/components/Feedback/ImportFeedbackButton';
import { PageContent } from '@launchnotes/components/Layout';
import { useExportFeedbackQuery } from '../../generated/graphql';
import ExportLink from '@launchnotes/components/ExportLink';
import useBouncer, { FeatureList } from '@launchnotes/common-hooks/useBouncer';

export enum FeedbackOrganizationState {
  Unorganized = "unorganized",
  Organized = "organized",
  Roadmap = "roadmap",
  Idea = "idea",
  Announcement = "announcement"
}

export type FeedbackExportShape = {
  id: string;
  email: string;
  content: string;
  importance: string;
  reaction: string;
  createdAt: string;
  archived: boolean;
}

export const FeedbackInbox: React.FC = () => {
  useBreadcrumbs([{ title: 'Ideas & feedback' }, { title: 'Feedback inbox' }]);
  const projectId = useProjectId();
  const bouncer = useBouncer()
  const [createFeedbackModalVisible, setCreateFeedbackModalVisible] = useState(false);
  const [importAlertVisible, setImportAlertVisible] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [exportShape, updateFeedbackExportShape] = useState<FeedbackExportShape[]>([]);
  const {
    data: exportData,
    refetch: exportRefetch,
    isLoading: exportIsLoading,
  } = useExportFeedbackQuery({ feedbackableId: projectId }, { enabled: false });

  const handleAlertClose = () => {
    setImportAlertVisible(false);
  };

  useEffect(() => {
    if (!exportData || exportIsLoading) return;
    const shape: FeedbackExportShape[] = exportData?.node?.exportFeedbacks?.map((feedback) => {
      return {
        id: feedback.id,
        email: feedback.affectedCustomerEmail,
        createdAt: feedback.createdAt,
        content: feedback.content,
        importance: feedback.importance,
        reaction: feedback.reaction,
        archived: feedback.archived
      };
    })
    updateFeedbackExportShape(shape);
  }, [exportData, exportIsLoading])

  return (
    <Layout>
      <PageContent largeWidth={true}>
        <PageHeader titleText="Feedback inbox">
          <Row gutter={[16, 16]} align={'bottom'}>
            { bouncer(FeatureList.IMPORT_FEEDBACK_IDEAS) &&
              <Col>
                <ImportFeedbackButton
                  buttonType="link"
                  setImportAlertVisible={setImportAlertVisible}
                  setAlertDescription={setAlertDescription}
                />
              </Col>
            }
            <Col>
              <ExportLink
                defaultFilename={`launchnotes_feedback_list`}
                exportData={exportShape}
                queryTrigger={exportRefetch}
                title='Export feedback'
                type='feedback'
              />
            </Col>
            { bouncer(FeatureList.RECORD_FOR_SUBSCRIBER) &&
              <Col>
                <Button type='primary' onClick={() => setCreateFeedbackModalVisible(true)}>
                + New feedback
                </Button>
              </Col>
            }
          </Row>
        </PageHeader>
        {importAlertVisible && (
          <Alert
            message={<Typography.Text>Feedback importing</Typography.Text>}
            description={<Typography.Text>{alertDescription}</Typography.Text>}
            type='success'
            closable
            afterClose={handleAlertClose}
            showIcon
          />
        )}
        <Space direction="vertical" style={{ width: '100%' }}>
          <FeedbackTable feedbackableId={projectId} organizedState={FeedbackOrganizationState.Unorganized} organize />
        </Space>
        {createFeedbackModalVisible && (
          <CreateFeedbackModal
            visible={createFeedbackModalVisible}
            setVisible={setCreateFeedbackModalVisible}
          />
        )}
      </PageContent>
    </Layout>
  );
};
