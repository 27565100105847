import { useCallback } from 'react';
import { QueryKey, useQueryClient } from '@tanstack/react-query';

// A shorthand way to invalidate RQ queries.
export const useInvalidateQuery = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();
  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: queryKey});
  }, [queryClient, queryKey]);

  return [invalidate];
};
