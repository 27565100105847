import React, { FC } from 'react';
import { SubscriberEdge } from '../../generated/graphql';
import { statusBadge } from '../SuperTable/columns/status';
import {
  Col,
  Divider,
  Row,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import Time from '../Time';
import { useDomainDrawer } from '../Domains/DetailDrawer';
import { StaticCategories } from '@launchnotes/components/Categories/CategoryTags';
import useBouncer, { FeatureList } from '@launchnotes/common-hooks/useBouncer';
import { UserAvatar } from '@launchnotes/components/Avatar';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faEnvelope
} from '@fortawesome/pro-regular-svg-icons'
import {
  faSlack
} from '@fortawesome/free-brands-svg-icons'

type SubscriberHeaderProps = {
  subscriber: SubscriberEdge
  isLoading: boolean
}

const SubscriberHeader: FC<SubscriberHeaderProps> = ({ subscriber, isLoading }) => {
  const { domainDrawer, showDomainDrawer } = useDomainDrawer();
  const bouncer = useBouncer()

  const [username, domain] = (subscriber.node.email as string).split('@');

  if (isLoading) {
    return (<Skeleton />);
  }

  const channels = [];

  if (subscriber.hasMailTransport) {
    channels.push(<Typography.Text><FontAwesomeIcon icon={faEnvelope} /> Email</Typography.Text>);
  }

  if (subscriber.hasSlackTransport) {
    channels.push(<Typography.Text><FontAwesomeIcon icon={faSlack} /> Slack</Typography.Text>);
  }

  return (
    <>
      <Row gutter={20} align="middle">
        <Col span={2}>
          <UserAvatar
            alt={subscriber.node.email as string}
            color={subscriber.node.avatarColor as string}
            email={subscriber.node.email as string}
            initials={subscriber.node.initials}
            size={40}
          />
        </Col>
        <Col span={22}>
          <Row align="middle" gutter={36}>
            <Col>
              <Typography.Title
                level={3}
                copyable={{
                  text: subscriber.node.email,
                }}
              >
                {username}
                @
                <Typography.Link
                  onClick={() => showDomainDrawer(subscriber.audienceDomainId)}
                  style={{ fontSize: 16}}
                >
                  {domain}
                </Typography.Link>
              </Typography.Title>
            </Col>
            <Col>
              {statusBadge(subscriber.status)}
            </Col>
          </Row>
          <Typography.Text type="secondary">
            Subscribed <Time timestamp={subscriber.createdAt} />
          </Typography.Text>

        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={2}></Col>
        <Col span={7} style={{ paddingTop: 24}}>
          <Space direction='vertical'>
            <Typography.Text strong>Notification channels</Typography.Text>
            {
              channels.length > 0 ?
                <Space size="large">{channels}</Space> :
                <Typography.Text>--</Typography.Text>
            }
          </Space>
        </Col>
        <Col span={15} style={{ paddingTop: 24}}>
          { bouncer(FeatureList.CATEGORIES) && (
            <>
              <Typography.Text strong>Subscribed categories</Typography.Text>
              {
                subscriber.categories && subscriber.categories.length > 0 ?
                  <Row>
                    <StaticCategories categories={subscriber.categories} />
                  </Row>
                  :
                  <Row>
                    <Typography.Text>
                    All categories
                    </Typography.Text>
                  </Row>
              }
            </>
          )}
        </Col>
      </Row>

      {domainDrawer}
    </>
  );
};

export default SubscriberHeader;
