import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { lnTokens } from '../../Router';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ConfigProvider,
  Divider,
  Flex,
  Select,
  Tag,
  Typography,
  theme,
  Row,
  Col,
} from 'antd';
import type { SelectProps } from 'antd';
import {
  useProjectContext,
  useOrganizationContext,
  useUserContext,
} from '../../AppContext';
import { NewPageModal } from '../Projects/NewPageModal';
import { RequestAccessModal } from '../Projects/RequestAccessModal';
import { useAuthenticatedFetch } from '@launchnotes/common-hooks/useAuthenticatedFetch';
import API_URL from '../../API_URL';
import {
  indicator,
} from './styles/styles';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faLock,
  faGlobe,
  faPlus,
  faSpinner,
  faVial
} from '@fortawesome/pro-regular-svg-icons'

type ListProject = {
  id: string;
  name: string;
  sandbox: boolean;
  secured: boolean;
  admins: [];
  userHasAccess: boolean;
};

const { useToken } = theme;

export const ProjectSelect: React.FC<{
  newPage?: boolean;
  requestAccess?: boolean;
}> = (props) => {
  const history = useHistory();
  const organization = useOrganizationContext();
  const project = useProjectContext();
  const user = useUserContext();
  const { authenticatedFetch } = useAuthenticatedFetch();
  const [projects, setProjectList] = useState<ListProject[]>([]);
  const { token } = useToken();
  const [requestAccessModalProject, setRequestAccessModalProject] =
    React.useState<ListProject | null>(null);
  const [newPageModalVisible, setNewPageModalVisible] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(projects.length === 0);

  const mailSubject = encodeURIComponent(
    `Requesting access to LaunchNotes ${requestAccessModalProject?.name} page`
  );
  const mailBody = `I am requesting access to the ${requestAccessModalProject?.name} page in LaunchNotes. Please send me an invite by adding me as a user at: ${
    process.env.REACT_APP_SPA_ROOT || 'https://app.launchnotes.com/'
  }admin/page-users\r\n\r\n- ${user?.firstName ? user.firstName : user?.email}`;

  useEffect(() => {
    const fetchProjects = async () => {
      const url = `${API_URL}/project_list`;
      const response = await authenticatedFetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Version': 'beta',
        }
      });

      if (response.ok) {
        const data = await response.json();
        setProjectList(data.allProjects);
        setLoading(false);
      }
    };

    fetchProjects();
  }, [authenticatedFetch, project.id]);

  const newPageDisabled = useMemo(() => {
    if (!organization || !user) {
      return true;
    }

    if (organization.remainingProjects <= 0) {
      return 'project-limit';
    }

    if (!user.isOrgAdmin) {
      return 'admin-only';
    }

    return false;
  }, [organization, user]);

  const onChange = useCallback(
    (id: string) => {
      history.push(`/projects/${id}`);
    },
    [history]
  );

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { projectName: string; }
  ) => {
    return (option?.projectName ?? '').toString().toLowerCase().includes(input.toLowerCase());
  };

  const options: SelectProps['options'] = projects.map((p) => ({
      value: p.id,
      label: p.name,
      sandbox: p.sandbox,
      secured: p.secured,
      userHasAccess: p.userHasAccess,
      disabled: !p.userHasAccess,
      project: p,
      projectName: p.name,
    })
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            optionSelectedColor: lnTokens.cosmicDust,
            selectorBg: lnTokens.cosmicDust,
            colorText: lnTokens.cosmicDust,
            colorTextQuaternary: lnTokens.cosmicDust,
          },
        },
      }}
    >
      <Row
        align='middle'
        wrap={false}
        style={{
          width: '100%',
          borderBottom: `2px solid ${lnTokens.nebula}`,
          paddingLeft: lnTokens.mainMenuIndent,
          paddingRight: '8px',
        }}
      >
        <Col flex='none'>
          <Typography.Text style={{color: lnTokens.cosmicDust}}>
            { project.secured ?
              (<FontAwesomeIcon icon={faLock} />) :
              (<FontAwesomeIcon icon={faGlobe} />)
            }
          </Typography.Text>
        </Col>
        <Col flex='auto'>
          <Select
            showSearch
            className='project-select'
            options={options}
            popupMatchSelectWidth={425}
            optionFilterProp='label'
            onChange={onChange}
            filterOption={filterOption}
            variant='borderless'
            defaultValue={loading ? null : project.id}
            notFoundContent={
                <Typography.Paragraph
                  style={{
                    textAlign: 'center',
                    padding: '10px 0',
                  }}
                >
                  {loading ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      size='2x'
                      spin
                     
                    />
                  ) : (
                    <Typography.Text>No projects found</Typography.Text>
                  )}
                </Typography.Paragraph>
            }
            style={{
              width: '100%'
            }}
            placeholder={(
              <ConfigProvider
                theme={{
                  components: {
                    Typography: {
                      colorText: lnTokens.cosmicDust,
                    },
                  },
                }}
              >
                <Typography>
                  <Typography.Text ellipsis>{project.name}</Typography.Text>
                </Typography>
              </ConfigProvider>
            )}
            optionRender={(option) => (
              <Row wrap={false}>
                <Col flex="auto">
                  <Typography>
                    <Typography.Text>
                      { option.data.secured ?
                        (<FontAwesomeIcon icon={faLock} />) :
                        (<FontAwesomeIcon icon={faGlobe} />)
                      }
                    </Typography.Text>
                    <Typography.Text ellipsis style={{paddingLeft: 8}}>
                      {option.data.label}
                    </Typography.Text>
                  </Typography>
                </Col>
                <Col flex='none'>
                  { option.data.sandbox && (
                    <Tag color='processing'>Sandbox</Tag>
                  )}
                </Col>
                {props.requestAccess && !option.data.userHasAccess && (
                  <Col flex="none">
                    <Button
                      type='primary'
                      size='small'
                      onClick={(e) => {
                        e.stopPropagation();
                        setRequestAccessModalProject(option.data.project);
                      }}
                    >
                      Request Access
                    </Button>
                  </Col>
                )}
              </Row>
            )}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Flex
                  justify='space-between'
                  style={{ padding: '0 8px 4px' }}
                >
                  <Button
                    type="text"
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={c => setNewPageModalVisible(true)}
                    data-cy='new-page-btn'
                    disabled={!!newPageDisabled}
                  >
                    New page
                  </Button>

                  <Typography.Text
                    style={indicator(token)}
                    data-cy='new-page-disclaimer'
                  >
                    {newPageDisabled === 'project-limit' ? (
                      <Button type='primary' size='small' id='support-toggle'>
                        Contact Us
                      </Button>
                    ) : newPageDisabled === 'admin-only' ? (
                      <Tag>Admin Only</Tag>
                    ) : null}
                  </Typography.Text>
                </Flex>
              </>
            )}
          />
        </Col>
      </Row>
      {props.newPage && (
        <NewPageModal
          visible={newPageModalVisible}
          setVisible={setNewPageModalVisible}
        />
      )}
      <RequestAccessModal
        project={requestAccessModalProject}
        subject={mailSubject}
        body={mailBody}
        visible={!!requestAccessModalProject}
        setVisible={() => setRequestAccessModalProject(null)}
      />
    </ConfigProvider>
  );
};
