import React, { ReactNode } from 'react';
import {
  Dropdown,
  Button,
  Menu,
  MenuProps,
  DropdownProps,
} from 'antd';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';

const defaultToggleElement = <Button type='text' icon={(<FontAwesomeIcon icon={faEllipsisVertical} size='lg' />)} style={{width: '24px'}} />;

type ActionsProps = {
  menu?: MenuProps;
  toggleElement?: ReactNode;
  dropdownProps?: DropdownProps;
}

const Actions = ({ menu = {}, toggleElement = defaultToggleElement, dropdownProps = {} }: ActionsProps ) => {
  return (
    <Dropdown trigger={['click']} className="actions-dropdown-trigger" menu={menu} {...dropdownProps}>
      {toggleElement}
    </Dropdown>
  );
};

// Outdated way to call Dropdown, kept for compatibility with old code.
export const ActionsWithChildren = ({ children, toggleElement = defaultToggleElement, dropdownProps = {}, ...props }: any ) => {
  const childrenWithProps = React.Children.map(children, child => {
    return child && React.cloneElement(child, props);
  });

  const menu = (
    <Menu>
      {childrenWithProps}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} className="actions-dropdown-trigger" menu={childrenWithProps} {...dropdownProps}>
      {toggleElement}
    </Dropdown>
  );
};

export default Actions;
