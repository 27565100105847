import React, { FC } from 'react';
import { lnTokens } from '../../../Router';
import { Tooltip, Row } from 'antd';
import { ColumnType } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';
import { SearchState } from 'pages/Subscribers/index';
import { Typography } from 'antd';
import { CompareFn } from 'antd/lib/table/interface';
import { UserAvatar } from '@launchnotes/components/Avatar';

type RenderedEmailProps = {
  email: string;
  id: string;
  initials: string;
  avatarColor?: string;
  search?: SearchState;
  isProjectSubscriber?: boolean;
  onClick?: (id: string) => void;
};

export const RenderedEmail: FC<RenderedEmailProps> = ({
  email,
  avatarColor,
  id,
  initials,
  search,
  onClick,
  isProjectSubscriber,
}) => {
  let emailEl;
  if (search) {
    emailEl = (
      <Highlighter
        highlightStyle={{ backgroundColor: lnTokens.cosmicDust, padding: 0 }}
        searchWords={[search.value]}
        autoEscape
        textToHighlight={email}
      />
    );
  } else {
    emailEl = email;
  }

  if (!onClick) {
    return <Typography.Text> {emailEl}</Typography.Text>;
  }

  if (!isProjectSubscriber) {
    return (
      <Tooltip title={'This email is not associated with a subscription'}>
        <Row>
          <UserAvatar alt={email} initials={initials} email={email} size={24} />{' '}
          {emailEl}
        </Row>
      </Tooltip>
    );
  }

  return (
    <Row>
      <UserAvatar alt={email} color={avatarColor} initials={initials} email={email} size={24} />
      <Typography.Link
        className='email-with-domain'
        onClick={() => onClick(id)}
        style={{ marginLeft: '8px' }}
      >
        {emailEl}
      </Typography.Link>
    </Row>
  );
};

const email = <T extends Record<string, unknown>>(
  title: string,
  dataIndex: string[],
  key: string,
  options?: {
    search?: SearchState;
    onClick?: (id: string) => void;
    subscriberIdIndex?: string[];
    sorter?: CompareFn<T>;
    width?: string | number;
  }
): ColumnType<T> => {
  return {
    title,
    dataIndex,
    key,
    sorter: options?.sorter,
    width: options?.width,
    render: (customer, record) => {
      if (options && options.onClick && options.subscriberIdIndex) {
        const id = options.subscriberIdIndex.reduce((object, key) => {
          if (key in object) {
            return object[key];
          } else {
            throw new Error('Subscriber ID index references an invalid key');
          }
        }, record);
        return (
          <RenderedEmail
            email={customer.email}
            avatarColor={customer.avatarColor}
            id={id}
            initials={customer.initials}
            search={options.search}
            onClick={options.onClick}
            isProjectSubscriber={customer.isProjectSubscriber}
          />
        );
      } else {
        return customer.email;
      }
    },
  };
};

export default email;
