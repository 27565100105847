import React from 'react';
import { Col, Image, Row, Typography, Space } from 'antd';
import { UserAvatar } from '@launchnotes/components/Avatar';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon';
import {
  faTrash,
  faPaperclip,
} from '@fortawesome/pro-regular-svg-icons';

const { Text } = Typography;

export type PreviewProps = {
  avatar?: boolean,
  src: string,
  alt?: string,
  filename: string | null | undefined,
  className: string | null | undefined,
  previewHeight?: string | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRemove: any
}

const Preview = ({ avatar, src, alt, filename, className, onRemove, previewHeight }: PreviewProps) => {
  const height = parseInt(previewHeight) || null;
  className = className || '';

  const renderAvatar = <UserAvatar
    size={100}
    alt={alt}
    initials={''}
    src={src}
  />;

  const renderImage = height ?
    <Image src={src} alt={alt} height={height}/> :
    <Image src={src} alt={alt} />;

  return (
    <Space direction="vertical" className={'upload-field-preview ' + className} style={{ marginBottom: 8 }}>
      <Row>
        <Col>
          {avatar ? renderAvatar : renderImage}
        </Col>
      </Row>
      {
        filename &&
        <Row wrap={false} className="file-data my-4">
          <Col flex="auto" className="preview-filename">
            <FontAwesomeIcon icon={faPaperclip} /> <Text >{filename || src}</Text>
          </Col>
          <Col flex="25px" className="icon-wrapper" onClick={onRemove}>
            <FontAwesomeIcon icon={faTrash} type="danger" style={{cursor: 'pointer'}} />
          </Col>
        </Row>
      }
    </Space>
  );
};

export default Preview;