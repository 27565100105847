import React from 'react';
import { format, isMatch, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

type TimeProps = {
  timestamp: string;
  local?: boolean;
  timeFormat?: string;
}


// Formats timestamps to something like April 29th, 1453.
export const defaultTimeFormat = 'PPP';

// Formats timestamps to something like Mon, April 29th, 1453.
export const fullTimeFormat = 'E, PP';

// Formats timestamps to something like 1453-04-29 14:53:23 UTC
export const utcTimeFormat = 'yyyy-MM-dd HH:mm:ss \'UTC\'';

// Formats timestamps to something like 1453-04-29T09:53:23-0500.
export const isoTimeFormat = 'yyyy-MM-dd\'T\'HH:mm:ssXX';

export const localTimeString = ({timestamp, local=true, timeFormat='PPP'}: TimeProps) => {
  let date:Date;

  // The timestamp string can be in the format "2022-04-01 06:00:00 UTC", which
  // is not a valid ISO 8601 string. Chrome can deal with this, but Firefox
  // cannot. So, we need to explicitly match the format so that it can coerce
  // it into a usable format. Assume all other timestamps are in ISO 8601.
  if (isMatch(timestamp, utcTimeFormat)) {
    date = utcToZonedTime(timestamp, 'UTC');
  } else {
    date = parseISO(timestamp);
  }

  // date-fns converts everything into local time. So, if we don't want the time
  // to be local, we'll have to create a new date object with the offset
  // included.
  if (!local) {
    date = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
  }

  return format(date, timeFormat);
};

/**
 * Component that automatically converts timestamp strings into specified formats.
 * @param timestamp A UTC or ISO 8601 timestamp.
 * @param local If true, the timestamp will be converted to local time. False by default.
 * @param timeFormat A date-fns format string. 'PP' by default.
 */
const Time = ({
  timestamp,
  local=false,
  timeFormat='PPP',
}: TimeProps) => {
  return (
    <time
      dateTime={localTimeString({
        timestamp,
        local,
        timeFormat: isoTimeFormat,
      })}
    >
      {localTimeString({ timestamp, timeFormat, local })}
    </time>
  );
};

export default Time;
