import React from "react";
import Icon from "@ant-design/icons";

const Svg = () => (
  <svg
    width="11"
    height="13"
    viewBox="0 0 9 5.3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#2E9E59"
      d="M8.3,5.2C8,5.2,7.9,5.2,7.7,5L4.5,1.8L1.3,5C1,5.3,0.5,5.3,0.2,5c-0.3-0.3-0.3-0.8,0-1.1L4,0.2
	c0.3-0.3,0.8-0.3,1.1,0L8.8,4c0.3,0.3,0.3,0.8,0,1.1C8.6,5.2,8.4,5.2,8.3,5.2z"
    />
  </svg>
);
<path
  fill="#E66A47"
  d="M8.3,5.2C8,5.2,7.9,5.2,7.7,5L4.5,1.8L1.3,5C1,5.3,0.5,5.3,0.2,5c-0.3-0.3-0.3-0.8,0-1.1L4,0.2
	c0.3-0.3,0.8-0.3,1.1,0L8.8,4c0.3,0.3,0.3,0.8,0,1.1C8.6,5.2,8.4,5.2,8.3,5.2z"
/>;
const SelectedSvg = () => (
  <svg
    width="11"
    height="13"
    viewBox="0 0 9 5.3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#E66A47"
      d="M8.3,5.2C8,5.2,7.9,5.2,7.7,5L4.5,1.8L1.3,5C1,5.3,0.5,5.3,0.2,5c-0.3-0.3-0.3-0.8,0-1.1L4,0.2
	c0.3-0.3,0.8-0.3,1.1,0L8.8,4c0.3,0.3,0.3,0.8,0,1.1C8.6,5.2,8.4,5.2,8.3,5.2z"
    />
  </svg>
);

const LowImportance = (props: any) => <Icon component={Svg} {...props} />;
const SelectedLowImportance = (props: any) => (
  <Icon component={SelectedSvg} {...props} />
);

export default LowImportance;
