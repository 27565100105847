import React, { useContext } from 'react';

type Privileges = Record<string, string[]>;

export type OtherUser = {
  id: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
}

export type OtherProject = {
  id: string;
  name: string;
  secured: boolean;
  admins: OtherUser[];
};

export type User = {
  id: string,
  email: string,
  name: string,
  displayName: string,
  firstName: string,
  lastName: string,
  initials: string,
  avatarColor: string,
  avatarImage: {
    filename: string,
    url: string,
  },
  project_privileges: Privileges,
  organization_privileges: Privileges,
  status: 'locked' | 'deactivated' | 'unconfirmed' | 'invited' | 'active',
  defaultProject?: string,
  isOrgAdmin: boolean,
  intercomIdentityHash: string,
};

type Organization = {
  id: string,
  name: string,
  isTrial: boolean,
  projectCount: number,
  remainingProjects: number,
};

type Category = {
  id: string,
  name: string,
  description?: string,
  position?: number,
  textColor?: string,
  backgroundColor?: string,
};

type Stage = {
  id: string,
  name: string,
  description?: string,
  published_at?: string,
  position?: number,
}

export type Project = {
  id: string,
  author: string;
  name: string,
  publicLink: string,
  createdAt: string,
  published: boolean,
  hasAnnouncements: boolean,
  hasSubscribers: boolean,
  hasFeedbacks: boolean,
  sandbox: boolean,
  secured: boolean,
  activeSecureConnection: boolean,
  feedbackEnabled: boolean,
  votingEnabled: boolean,
  roadmapEnabled: boolean,
  secureConnectionId?: string,
  authenticationType: 'SSO' | 'SAML' | 'JWT' | null,
  stripeId: string,
  billingStatus: 'suspended' | 'trial' | 'premium' | 'business' | 'enterprise' | 'comped' | 'community' | 'basic' | 'essentials',
  activeTier: 'business' | 'essentials' | 'community' | 'comped' | 'enterprise' | 'premium' | null,
  isOnboarding: boolean,
  organizationId: string,
  trialExpirationDatetime?: string,
  categories?: Category[],
  stages?: Stage[],
  editable: boolean,
};

export type ContextDataShape = {
  user?: User,
  organization?: Organization,
  project?: Project,
  features: string[],
  refreshUserContext?: () => void,
};

export type ContextShape = {
  context: ContextDataShape,
  updateContext?: React.Dispatch<React.SetStateAction<ContextDataShape>>,
};

export const defaultContextData: ContextDataShape = {
  features: []
};

export const defaultContext: ContextShape = {
  context: defaultContextData,
};

export const useUserContext = () => {
  const { context } = useContext<ContextShape>(AppContext);
  if (context?.user) {
    return context.user;
  }
};

export const useOrganizationContext = () => {
  const { context } = useContext<ContextShape>(AppContext);
  if (context?.organization) {
    return context.organization;
  }
};

export const useProjectContext = () => {
  const { context } = useContext<ContextShape>(AppContext);
  if (context?.project) {
    return context.project;
  }
};

export const useAccessibleFeatures = () => {
  const { context } = useContext<ContextShape>(AppContext)

  return context.features
};

export const AppContext = React.createContext(defaultContext);
