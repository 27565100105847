import React, { FC, SetStateAction, Dispatch } from 'react';
import { useHistory } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { Button, message } from 'antd';
import { useUserContext } from '../../AppContext';
import { useImportFeedbackMutation, BatchFeedback } from '../../generated/graphql';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import { Flatfile } from '@flatfile/sdk';
import API_URL from '../../API_URL';
import { useAuthenticatedFetch } from '@launchnotes/common-hooks/useAuthenticatedFetch';

type Props = {
  buttonType: 'primary' | 'link',
  setImportAlertVisible: Dispatch<SetStateAction<boolean>>,
  setAlertDescription: Dispatch<SetStateAction<string>>,
};

const ImportFeedbackButton: FC<Props> = ({ buttonType, setImportAlertVisible, setAlertDescription }) => {
  const posthog = usePostHog();
  const user = useUserContext();
  const { mutateAsync } = useImportFeedbackMutation({});
  const projectId = useProjectId();
  const { authenticatedFetch } = useAuthenticatedFetch();
  const history = useHistory();

  if (!user) return null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onData = (chunk: any, next: () => void) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const feedbacks = chunk.records.map(({ data }) => {
      let ideaCategories;
      if (data.ideaCategories) {
        if (data.ideaCategories.indexOf(',') > 0) {
          ideaCategories = data.ideaCategories.split(',').map((category: string) => category.trim());
        } else {
          ideaCategories = [data.ideaCategories.trim()];
        }
      }

      let published;
      if (data.ideaPublished) {
        published = (data.ideaPublished === 'true');
      }

      const parsedData = { ...data, ideaCategories: ideaCategories, ideaPublished: published };

      return parsedData;
    });
    const dataLength = feedbacks.length;
    const feedbackChunkSize = 500;

    for (let i = 0; i < dataLength; i += feedbackChunkSize) {
      const slice = feedbacks.slice(i, i + feedbackChunkSize);

      // Flatfile returns empty strings for empty values
      // so need to filter those out because every field
      // isn't required for feedback import
      const cleanedSlice: BatchFeedback[] = slice.map((f: BatchFeedback) => {
        return Object.fromEntries(Object.entries(f).filter(([_, value]) => value !== ''));
      });

      mutateAsync({ projectId, feedbacks: cleanedSlice });
    }
    next();
    setAlertDescription(`${dataLength} pieces of feedback are being added to your LaunchNotes workspace. Please refresh the page if they’re not yet visible. A summary email will be sent to ${user.email} when the import is complete.`);
  };

  const onComplete = () => {
    history.push(`/projects/${projectId}/feedback/inbox`);
    setImportAlertVisible(true);
    posthog.capture('completed import feedback');
  };

  const onError = (payload: { error: Error }) => {
    message.error(`There was an error importing your feedback. Please try again. ${payload.error}`);
  };

  const handleClick = () => {
    Flatfile.requestDataFromUser({
      token: async () => {
        try {
          const url = `${API_URL}/projects/${projectId}/flatfile/authenticate`;
          const response = await authenticatedFetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const { token } = await response.json();
            return token;
          } else {
            message.error('We could not authenticate this request.');
          }
        } catch (error) {
          message.error('We could not authenticate this request.');
        }
      },
      onData,
      onComplete,
      onError,
    });
    posthog.capture('clicked import feedback');
  };

  return (
    <Button
      type={buttonType}
      onClick={handleClick}
    >
      Import
    </Button>
  );
};

export default ImportFeedbackButton;
