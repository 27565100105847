import React, { FC, useState } from 'react';
import {
  HasEvent,
  HasNotification,
  EventWithDateDivider,
  Toggleable,
} from './types';
import { Button, Col, Row, Timeline, Typography } from 'antd';
import EventRowTitle from './EventRowTitle';
import EventRowBody from './EventRowBody';
import EventRowIndicator from './EventRowIndicator';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faChevronDown,
  faChevronUp
} from '@fortawesome/pro-regular-svg-icons'

import { format, parseISO } from 'date-fns';

import styled from 'styled-components';

const DateDivider: FC<EventWithDateDivider> = ({
  event,
  shouldDisplayDate,
}) => (
  <>
    {shouldDisplayDate && (
      <Typography.Paragraph strong>
        {format(parseISO(event.createdAt), 'MMMM d, yyyy')}
      </Typography.Paragraph>
    )}
  </>
);

const Header: FC<HasEvent & HasNotification & Toggleable> = ({
  event,
  notification,
  toggleState,
  hasToggle,
  updateToggleState,
}) => {
  let toggle = hasToggle && <></>;
  if (
    hasToggle &&
    event.eventType === 'announcement.published' &&
    notification
  ) {
    if (
      notification.openedAt ||
      notification.clickedThroughAt
    ) {
      toggle = toggleState ? (
        <>
          <FontAwesomeIcon icon={faChevronDown} />
          Hide Details
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faChevronUp} />
          Show Details
        </>
      );
    }
  }

  return (
    <>
      <Row>
        <Col flex='auto'>
          <EventRowTitle event={event} notification={notification} />
        </Col>
      </Row>
      <Row align={'bottom'}> 
        <Typography.Text type='secondary'>
          {format(parseISO(event.createdAt), 'h:mm a')}
        </Typography.Text>
        {hasToggle && (
          <Button type='link' onClick={() => updateToggleState?.(!toggleState)}>
            {toggle}
          </Button>
        )}
      </Row>
    </>
  );
};

const EventRow: FC<EventWithDateDivider> = ({
  event,
  shouldDisplayDate,
  shouldHideTail,
}) => {
  const [showBody, toggleShowBody] = useState(false);
  const TimelineWrapper = styled.div`
  .ant-timeline-item {
    margin-bottom: 16px;
  }
  `

  return (
    <TimelineWrapper>
      <DateDivider event={event} shouldDisplayDate={shouldDisplayDate} />
      {
        event.eventObject.__typename === 'Announcement' &&
          event.notifications &&
          event.notifications.totalCount > 0 ? (
            event.notifications.nodes?.map((notification) => (
              <Timeline.Item key={event.id}>
                <Row>
                  <Col span={1} style={{ display: 'flex', justifyContent: 'center' }}>
                    <EventRowIndicator
                      eventObject={event.eventObject}
                      notification={notification}
                      event={event}
                    />
                  </Col>
                  <Col span={23}>
                    <Header
                      event={event}
                      notification={notification}
                      hasToggle={
                        notification?.status &&
                        notification.status !== 'undelivered'
                      }
                      toggleState={showBody}
                      updateToggleState={toggleShowBody}
                    />
                  </Col>
                </Row>
                <EventRowBody
                  event={event}
                  notification={notification}
                  toggleState={
                    showBody &&
                    notification?.status &&
                    notification.status !== 'undelivered'
                  }
                />
              </Timeline.Item>
            ))
          ) : (
            <Timeline.Item key={event.id}>
              <Row gutter={8}>
                <Col span={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <EventRowIndicator
                    eventObject={event.eventObject}
                    event={event}
                  />
                </Col>
                <Col span={23}>
                  <Header event={event} />
                </Col>
              </Row>
              <EventRowBody event={event} />
            </Timeline.Item>
          )}
    </TimelineWrapper>
  );
};

export default EventRow;
