import React, { FC, Key, useEffect, useState, useMemo } from 'react';
import {
  Button,
  Card,
  Checkbox,
  ConfigProvider,
  message, Row,
  Skeleton,
  Space,
  Table,
  TableProps,
  Typography,
} from 'antd';
import { useSearchParams } from '@launchnotes/common-hooks/useSearchParams';
import { useColumns } from './hooks/useColumns';
import { useArchiveFeedbacks } from './hooks/useArchiveFeedbacks';
import { useUnarchiveFeedbacks } from './hooks/useUnarchiveFeedbacks';
import { OrganizeFeedbackModal } from './modals/OrganizeFeedbackModal';
import { UpdateFeedbackModal } from './modals/UpdateFeedbackModal';
import { actionsColumn, createdAtColumn, importanceColumn, organizeColumn, reactionColumn } from './Columns';
import { BulkActions } from './BulkActions';
import {
  Attribute,
  Feedback,
  FeedbackTableQuery,
  Importance,
  Maybe,
  PageInfo,
  Reaction,
  SortEnum,
  useFeedbackAttributeDefinitionsQuery,
  useFeedbackTableQuery,
  useUpdateFeedbackAttributeMutation,
} from '../../generated/graphql';
import { FeedbackOrganizationState } from '@launchnotes/pages/Feedback/FeedbackInbox';
import EmptyState, { EmptyStateFilteredTable } from '@launchnotes/components/EmptyState';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import highlightedContent from '../../utils/highlightedContent';
import email from '../SuperTable/columns/email';
import { useSubscriberDrawer } from '../Subscribers/DetailDrawer';
import Pagination, { usePagination } from '../SuperTable/Pagination';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import CommentSingleOutlined from '@launchnotes/icons/CommentSingleOutlined';
import useIsValidPlanLevel from '@launchnotes/common-hooks/useIsValidPlanLevel';
import { BorderlessTable } from '../../Theme/styles/globalTableStyle';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon';
import { faComments } from '@fortawesome/pro-thin-svg-icons';
import { faClipboardCheck } from '@fortawesome/pro-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';

const { Paragraph, Text } = Typography;

type Unpacked<T> = T extends (infer U)[] ? U : T;
type FeedbackTableQueryFiltered = {
  __typename?: 'Query', feedbackable: Extract<FeedbackTableQuery['feedbackable'],
    | { __typename?: 'Announcement' }
    | { __typename?: 'Idea' }
    | { __typename?: 'Project' }
    | { __typename?: 'WorkItem' }
  >
};
export type FeedbackTableFeedback = NonNullable<NonNullable<Unpacked<NonNullable<NonNullable<FeedbackTableQueryFiltered['feedbackable']['feedbacks']>['edges']>>>['node']>;

export type FeedbackTableProps = {
  feedbackableId: string,
  archived?: boolean;
  organized?: boolean;
  organizedState?: string;
  organize?: boolean;
  reorganize?: boolean;
};

export const FeedbackTable: React.FC<FeedbackTableProps> = ({
  feedbackableId,
  archived = false,
  organized = true,
  organizedState = FeedbackOrganizationState.Unorganized,
  reorganize = true,
  organize = false,
}) => {
  const projectId = useProjectId();

  const { searchParams, updateSearchParams } = useSearchParams<{
    organizedState: string,
    reaction: Reaction,
    importance: Importance,
    cohortId: string,
    search: string,
    token: string,
    sort: string,
    sortOrder: SortEnum,
  }>();

  const clearAllFilters = () => {
    updateSearchParams({
      organizedState: undefined,
      reaction: undefined,
      importance: undefined,
      cohortId: undefined,
      search: undefined,
      token: undefined,
      sort: undefined,
      sortOrder: undefined,
    });
  };

  const resetFilters = () => {
    updateSearchParams({
      organizedState: FeedbackOrganizationState.Unorganized,
      reaction: undefined,
      importance: undefined,
      cohortId: undefined,
      search: undefined,
      token: undefined,
      sort: undefined,
      sortOrder: undefined,
    });
  };

  // ===========================================================================

  const queryClient = useQueryClient();
  const updateAttribute = useUpdateFeedbackAttributeMutation({
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["FeedbackTable", {"archived":false, "feedbackableId":projectId}]})
    }
  });

  const attributeDefinitionsResponse = useFeedbackAttributeDefinitionsQuery({ project: projectId });
  const attributeDefinitions = attributeDefinitionsResponse.data?.project?.attributeDefinitions?.nodes;

  const customAttributeColumns = useMemo(() => {
    if (attributeDefinitions && attributeDefinitions.length > 0) {
      return attributeDefinitions.map((attributeDefinition) => ({
        title: attributeDefinition.name,
        key: `attributes.${attributeDefinition.key}`,
        sorter: true,
        render: ({ attributes, id }) => {
          let found: Attribute;
          if (attributes && attributes.length > 0) {
            found = attributes.find(({ key }) => key === attributeDefinition.key);
          }
          if (attributeDefinition.valueType === 'boolean') {
            return (
              <Checkbox
                checked={found?.value}
                onChange={(e) => {
                  updateAttribute.mutate({
                    feedback: id,
                    key: attributeDefinition.key,
                    value: e.target.checked
                  });
                }}
              />
            )
          } else {
            return (
              <Typography.Text
                style={{ cursor: "pointer" }}
                editable={{
                  text: found?.value ? found?.value?.toString() : undefined,
                  triggerType: found?.value ? ["text"] : ["icon"],
                  autoSize: { maxRows: 1 },
                  onChange(value) {
                    if (value === found?.value?.toString()) return;

                    let normalizedValue: string | number = value;
                    if (
                      attributeDefinition.valueType === "integer" ||
                      attributeDefinition.valueType === "float"
                    ) {
                      normalizedValue = Number(value);
                    }

                    updateAttribute.mutate({
                      feedback: id,
                      key: attributeDefinition.key,
                      value: normalizedValue,
                    });
                  },
                }}
                placeholder="empty"
              >
                {found?.value}
              </Typography.Text>
            );
          }
        },
      }));
    } else {
      return [];
    }
  }, [attributeDefinitions]);

  const where = useMemo(() => {
    let rules = [];
    if (!attributeDefinitions || !searchParams) return undefined;

    attributeDefinitions.forEach((attributeDefinition) => {
      if (attributeDefinition.valueType === 'string') {
        const searchKey = `attributes.${attributeDefinition.key}`;
        if (searchKey in searchParams && searchParams[searchKey] !== '') {
          rules.push({
            field: searchKey,
            expression: 'eq',
            value: searchParams[searchKey],
          });
        }
      } else if (attributeDefinition.valueType === 'float' || attributeDefinition.valueType === 'integer') {
        const maxKey = `attributes.${attributeDefinition.key}.max`;
        const minKey = `attributes.${attributeDefinition.key}.min`;

        if (minKey in searchParams && searchParams[minKey] !== '') {
          rules.push({
            field: `attributes.${attributeDefinition.key}`,
            expression: 'gte',
            value: Number(searchParams[minKey]),
          });
        }
        if (maxKey in searchParams && searchParams[maxKey] !== '') {
          rules.push({
            field: `attributes.${attributeDefinition.key}`,
            expression: 'lte',
            value: Number(searchParams[maxKey]),
          });
        }
      } else if (attributeDefinition.valueType === 'boolean') {
        const searchKey = `attributes.${attributeDefinition.key}`;
        if (searchKey in searchParams && searchParams[searchKey] !== '') {
          rules.push({
            field: searchKey,
            expression: 'eq',
            value: Boolean(searchParams[searchKey]),
          });
        }
      }
    });

    if (searchParams.token) {
      rules.push({ field: 'tsvTokens', expression: 'search', value: searchParams.token });
    }

    if (rules.length === 0) return undefined;

    return { rules };
  }, [attributeDefinitions, searchParams]);

  const { previousPage, nextPage, updatePageSize, first, before, after } = usePagination();

  const { data, isLoading } =
    useFeedbackTableQuery<FeedbackTableQueryFiltered>(
      {
        feedbackableId,
        count: first,
        before,
        cursor: after,
        archived: archived,
        organizedState:
          organized && organizedState
            ? searchParams.organizedState
            : FeedbackOrganizationState.Unorganized,
        reaction: searchParams.reaction,
        importance: searchParams.importance,
        cohortId: searchParams.cohortId,
        searchTerm: searchParams.search,
        orderBy: searchParams.sort
          ? { field: searchParams.sort, sort: searchParams.sortOrder }
          : { field: "createdAt", sort: SortEnum.Desc },
        where,
      },
    );

  // ===========================================================================

  const feedbacks = React.useMemo(() => {
    return data?.feedbackable?.feedbacks?.edges?.map(({ node }) => node);
  }, [data]);

  const feedbackIds = React.useMemo(() => feedbacks?.map((feedback) => feedback.id), [feedbacks]);
  const totalCount = React.useMemo(() => feedbacks?.length | 0, [feedbacks]);
  const unfilteredTotal = data?.feedbackable?.hasFeedback?.totalCount || 0;
  // ==========================================================================

  const [selectedRows, setSelectedRows] = useState<FeedbackTableFeedback[]>([]);
  useEffect(() => {
    const feedbacksById = feedbacks?.reduce<{ [id: string]: FeedbackTableFeedback }>((o, feedback) => {
      o[feedback.id] = feedback; return o;
    }, {});
    setSelectedRows((p) => p.filter(({ id }) => !!feedbacksById[id]));
  }, [feedbacks]);

  // ===========================================================================

  const handleTableChange = React.useCallback<NonNullable<TableProps<FeedbackTableFeedback>['onChange']>>((
    _pagination,
    _filters,
    sorter,
    extra,
  ) => {
    if (Array.isArray(sorter)) sorter = sorter[0];
    if (extra.action === 'sort') {
      const sort = '' + sorter.columnKey;
      if (sorter.order === 'ascend') {
        updateSearchParams({ sort, sortOrder: SortEnum.Asc });
      } else if (sorter.order === 'descend') {
        updateSearchParams({ sort, sortOrder: SortEnum.Desc });
      } else {
        updateSearchParams({ sort: undefined, sortOrder: undefined });
      }
    }
  }, [updateSearchParams]);

  // Action Handlers ===========================================================

  const { archiveFeedbacks } = useArchiveFeedbacks();
  const onArchive = React.useCallback((feedback: FeedbackTableFeedback | FeedbackTableFeedback[]) => {
    archiveFeedbacks({
      feedbackIds: (Array.isArray(feedback) ? feedback : [feedback]).map(({ id }) => id),
    });
  }, [archiveFeedbacks]);

  const [updateFeedbackModalFeedback, setUpdateFeedbackModalFeedback] = React.useState<FeedbackTableFeedback>();
  const [updateFeedbackModalVisible, setUpdateFeedbackModalVisible] = useState(false);
  const onEdit = React.useCallback((feedback: FeedbackTableFeedback) => {
    setUpdateFeedbackModalFeedback(feedback);
    setUpdateFeedbackModalVisible(true);
  }, []);

  const onCopy = React.useCallback(async (feedback: FeedbackTableFeedback | FeedbackTableFeedback[]) => {
    const emails = (Array.isArray(feedback) ? feedback : [feedback]).map(({ affectedCustomerEmail: email }) => email).join(', ');
    await navigator.clipboard.writeText(emails);
    message.success('Selected email addresses copied to clipboard');
  }, []);

  const [organizeFeedbackModalFeedbacks, setOrganizeFeedbackModalFeedbacks] = React.useState<FeedbackTableFeedback[]>([]);
  const [organizeFeedbackModalVisible, setOrganizeFeedbackModalVisible] = useState(false);
  const onOrganize = React.useCallback((feedback: FeedbackTableFeedback | FeedbackTableFeedback[]) => {
    setOrganizeFeedbackModalFeedbacks(Array.isArray(feedback) ? feedback : [feedback]);
    setOrganizeFeedbackModalVisible(true);
  }, []);

  const { unarchiveFeedbacks } = useUnarchiveFeedbacks();
  const onRestore = React.useCallback((feedback: FeedbackTableFeedback | FeedbackTableFeedback[]) => {
    unarchiveFeedbacks({
      feedbackIds: (Array.isArray(feedback) ? feedback : [feedback]).map(({ id }) => id),
    });
  }, [unarchiveFeedbacks]);

  const { subscriberDrawer, showSubscriberDrawer } = useSubscriberDrawer();

  const validPlanLevel = useIsValidPlanLevel(['business', 'enterprise']);

  const columns = [
    email<FeedbackTableFeedback>('Email', ['affectedCustomer'], 'email', {
      search: searchParams.search,
      subscriberIdIndex: ['affectedCustomer', 'id'],
      onClick: showSubscriberDrawer
    }),
    createdAtColumn,
    importanceColumn,
    reactionColumn,
    ...customAttributeColumns,
    organize && organizeColumn({
      onArchive,
      onOrganize,
    }),
    actionsColumn({
      onArchive,
      onEdit,
      onOrganize: reorganize ? onOrganize : undefined,
      onRestore,
    }),
  ];

  return (
    <Card className="feedback-inbox">
      <BulkActions
        attributeDefinitions={attributeDefinitions}
        selectedCount={selectedRows.length}
        totalCount={totalCount}
        archived={archived}
        organized={organized}
        organizedState={FeedbackOrganizationState.Unorganized}
        reorganize={reorganize}
        onArchive={() => onArchive(selectedRows)}
        onCopy={() => onCopy(selectedRows)}
        onOrganize={() => onOrganize(selectedRows)}
        onRestore={() => onRestore(selectedRows)}
      />

      <Space direction="vertical" style={{ width: "100%" }}>
        <ConfigProvider
          renderEmpty={() => {
            if (isLoading) {
              return (
                <>
                  <Skeleton />
                  <Skeleton />
                </>
              );
            } else {
              if (archived) {
                if (Object.keys(searchParams).length == 0 && totalCount === 0) {
                  return (
                    <EmptyState
                      icon={<FontAwesomeIcon icon={faComments} />}
                      title="No archived feedback"
                    />
                  );
                }
                return (
                  <EmptyStateFilteredTable buttonOnClick={clearAllFilters} />
                );
              }
              if (unfilteredTotal === 0) {
                return (
                  <EmptyState
                    icon={<FontAwesomeIcon icon={faComments} />}
                    title="Collect feedback you can actually close the loop on"
                    subTitle="Give customers a direct and dedicated channel to reach you with questions and suggestions about your roadmap, new features you're building, or even recent announcements you've made."
                  />
                );
              }
              if (
                searchParams.organizedState ===
                  FeedbackOrganizationState.Unorganized &&
                searchParams.reaction === undefined &&
                searchParams.importance === undefined &&
                searchParams.cohortId === undefined &&
                searchParams.search === undefined
              ) {
                return (
                  <EmptyState
                    icon={<FontAwesomeIcon icon={faClipboardCheck} />}
                    title="Inbox zero!"
                    subTitle="You've organized all of your feedback. Take the rest of the day off, you've earned it."
                  />
                );
              }
              return <EmptyStateFilteredTable buttonOnClick={resetFilters} />;
            }
          }}
        >
          <BorderlessTable
            rowKey="id"
            rowClassName="feedback"
            columns={useColumns(columns)}
            sticky
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedRows.map(({ id }) => id),
              onChange: (_, selectedRows: FeedbackTableFeedback[]) =>
                setSelectedRows(selectedRows),
            }}
            dataSource={feedbacks || undefined}
            expandable={{
              columnWidth: 0,
              expandedRowKeys: feedbackIds,
              showExpandColumn: false,
              defaultExpandAllRows: true,
              expandedRowRender: (record) => (
                <Space
                  key={record.id}
                  direction="vertical"
                  style={{ padding: "16px" }}
                >
                  {record.content
                    .split("\n")
                    .map((paragraph: string, index: number) => (
                      <Paragraph
                        key={index}
                        style={{ marginBottom: 0, marginLeft: 16 }}
                        className="content"
                      >
                        {highlightedContent(paragraph, [
                          searchParams.search,
                          searchParams.token,
                        ])}
                      </Paragraph>
                    ))}

                  {record.reporter?.id && (
                    <Space direction={"vertical"}>
                      <Text strong>
                        Recorded by{" "}
                        {record.reporter.name || record.reporter.email}
                      </Text>
                      {record.notes &&
                        record.notes
                          .split("\n")
                          .map((paragraph: string, index: number) => (
                            <Paragraph italic key={index} className="notes">
                              {highlightedContent(paragraph, [
                                searchParams.search,
                                searchParams.token,
                              ])}
                            </Paragraph>
                          ))}
                    </Space>
                  )}
                </Space>
              ),
            }}
            pagination={false}
            onChange={handleTableChange}
            loading={isLoading}
          />
        </ConfigProvider>

        {!isLoading && (
          <Row justify="end">
            <Pagination
              pageInfo={data?.feedbackable?.feedbacks.pageInfo}
              pageSize={first}
              totalCount={data?.feedbackable?.feedbacks.totalCount}
              onNext={() => {
                nextPage(data?.feedbackable?.feedbacks?.pageInfo?.endCursor);
              }}
              onPrevious={() => {
                previousPage(
                  data?.feedbackable?.feedbacks?.pageInfo?.startCursor
                );
              }}
              onUpdatePageSize={updatePageSize}
            />
          </Row>
        )}
      </Space>
      {updateFeedbackModalFeedback && updateFeedbackModalVisible && (
        <UpdateFeedbackModal
          feedback={updateFeedbackModalFeedback}
          visible={updateFeedbackModalVisible}
          setVisible={setUpdateFeedbackModalVisible}
        />
      )}
      {organizeFeedbackModalVisible && (
        <OrganizeFeedbackModal
          feedbacks={organizeFeedbackModalFeedbacks}
          visible={organizeFeedbackModalVisible}
          setVisible={setOrganizeFeedbackModalVisible}
        />
      )}

      {subscriberDrawer}
    </Card>
  );
};

type MinimalFeedbackTableProps = {
  isLoading?: boolean
  feedback?: Feedback[]
  columns: ColumnsType<Feedback>
  pageInfo: PageInfo
  first: number
  totalCount?: number
  nextPage: (cursor: Maybe<string> | undefined) => void
  previousPage: (cursor: Maybe<string> | undefined) => void
  updatePageSize: (size: number) => void
  showLink?: boolean
  emptyState?: () => JSX.Element
  onChange?: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<object> | SorterResult<object>[], extra: TableCurrentDataSource<object>) => void
  bordered?: boolean
}

export const MinimalFeedbackTable: FC<MinimalFeedbackTableProps> = ({
  isLoading,
  feedback,
  columns,
  first,
  pageInfo,
  totalCount,
  nextPage,
  previousPage,
  updatePageSize,
  showLink,
  emptyState,
  onChange,
  bordered,
}) => {
  const feedbackIds = React.useMemo(() => {
    if (!feedback) return [];
    return feedback.map<string>((feedback) => feedback?.id);
  }, [feedback]);

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <ConfigProvider renderEmpty={emptyState}>
        <Table
          loading={isLoading}
          size="small"
          dataSource={feedback}
          rowKey="id"
          columns={columns}
          expandable={{
            columnWidth: 0,
            showExpandColumn: false,
            defaultExpandAllRows: true,
            expandedRowKeys: feedbackIds as Key[],
            expandedRowRender: (record: Feedback) => {
              return (
                <Space direction={'vertical'} style={{ alignItems: 'flex-start' }}>
                  {record.content.split('\n').map((paragraph: string, index: number) => (
                    <Typography.Paragraph key={index} className='content'>{paragraph}</Typography.Paragraph>
                  ))}

                  {record.reporter?.id &&
                    <>
                      <Typography.Text strong>Recorded by {record.reporter.name || record.reporter.email}</Typography.Text>
                      {record.notes && record.notes.split('\n').map((paragraph: string, index: number) => (
                        <Typography.Paragraph italic key={index} className='notes'>{paragraph}</Typography.Paragraph>
                      ))}
                    </>
                  }
                  {
                    showLink && record.feedbackable && (
                      <Typography.Paragraph>
                        <Typography.Link
                          target="_blank"
                          href={record.feedbackable.privatePermalink}
                        >
                          {record.feedbackable.name}
                        </Typography.Link>
                      </Typography.Paragraph>
                    )
                  }
                </Space>
              );
            },
          }}
          onChange={onChange}
          pagination={false}
          bordered={bordered}
        />
      </ConfigProvider>

      <Row justify="end">
        <Pagination
          pageInfo={pageInfo}
          pageSize={first}
          totalCount={totalCount || 0}
          onNext={() => {
            nextPage(pageInfo.endCursor);
          }}
          onPrevious={() => {
            previousPage(pageInfo.startCursor);
          }}
          onUpdatePageSize={updatePageSize}
        />
      </Row>
    </Space>
  );
};
