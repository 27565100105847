import React, { FC } from 'react';
import { Layout } from 'antd';

interface PageContentProps {
  children: React.ReactNode;
  style?: React.CSSProperties; // Allow passing custom style
  largeWidth?: boolean;
}

export const PageContent: FC<PageContentProps> = ({ children, style, largeWidth }) => {
  const defaultStyle: React.CSSProperties = {
    padding: '0 36px 24px', // Default padding
    gap: '16px',
    flex: 'auto',
    maxWidth: largeWidth ? '1920px' : '1056px'
    // Add any other default styles you want here
  };

  // Merge default style with custom style, if provided
  const mergedStyle = { ...defaultStyle, ...style };

  return <Layout style={mergedStyle}>{children}</Layout>;
};
