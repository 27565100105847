import React, { FC, useState } from 'react';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import { useSubscriberFeedbacksQuery, PageInfo, Feedback, SortEnum, InputMaybe, OrderBy } from '../../generated/graphql';
import { usePagination } from '../SuperTable/Pagination';
import { Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dateTime from '../SuperTable/columns/dateTime';
import { MinimalFeedbackTable } from '../Feedback/FeedbackTable';
import SentimentIndicator from '../Sentiment/SentimentIndicator';
import HighImportance from '@launchnotes/icons/HighImportance';
import MediumImportance from '@launchnotes/icons/MediumImportance';
import LowImportance from '@launchnotes/icons/LowImportance';
import EmptyTableRenderer from '../Domains/EmptyTableState';
import { Key, SortOrder } from 'antd/lib/table/interface';
import { Tooltip, Typography } from 'antd';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faCircleQuestion,
} from '@fortawesome/pro-regular-svg-icons'

type TableState = {
  orderBy?: {
    field: string | Key | undefined,
    sort: SortOrder
  }
};

const getSortDirection = (sortDirection?: SortEnum) => {
  if (!sortDirection) {
    return null;
  }

  return sortDirection === SortEnum.Asc ? 'ascend' : 'descend';
};

const SubscriberFeedbackTable: FC<{ id: string }> = ({ id }) => {
  const projectId = useProjectId();
  const [tableState, updateTableState] = useState<TableState>();
  const { previousPage, nextPage, updatePageSize, first, before, after } = usePagination();

  let orderBy: InputMaybe<OrderBy> | undefined;

  if (tableState?.orderBy?.field) {
    orderBy = {
      field: tableState.orderBy.field.toString(),
      sort: tableState.orderBy.sort === 'descend' ? SortEnum.Desc : SortEnum.Asc,
    };
  }

  const { data, isLoading } = useSubscriberFeedbacksQuery({
    id,
    projectId,
    first,
    before,
    after,
    orderBy: orderBy || {
      field: 'createdAt',
      sort: SortEnum.Desc,
    },
  });

  const columns: ColumnsType<Feedback> = [
    dateTime(
      'Date',
      ['createdAt'],
      'createdAt',
    ),
    {
      title: (
        <Typography.Text>
          Sentiment{' '}
          <Tooltip title='LaunchNotes applies machine learning to identify the emotional tone behind feedback.'>
            <FontAwesomeIcon icon={faCircleQuestion} />
          </Tooltip>
        </Typography.Text>),
      key: 'sentimentScore',
      dataIndex: ['sentimentScore'],
      showSorterTooltip: false,
      sorter: true,
      width: '25%',
      render: (sentimentScore: number | undefined) => (
        <SentimentIndicator
          sentimentScore={sentimentScore}
          displayScore
          titleOverride={`This feedback has a sentiment score of ${sentimentScore?.toFixed(2)}.`}
        />
      ),
    },
    {
      title: 'Importance',
      key: 'importance',
      dataIndex: ['importance'],
      sorter: true,
      sortOrder: orderBy?.field === 'importance' ? getSortDirection(orderBy.sort) : null,
      width: '25%',
      render: (importance: 'high' | 'medium' | 'low' | undefined) => {
        if (importance === 'high') {
          return <HighImportance />;
        } else if (importance === 'medium') {
          return <MediumImportance />;
        } else if (importance === 'low') {
          return <LowImportance />;
        } else {
          return <Space className="no-reaction">-</Space>;
        }
      },
    },
  ];

  let subscriber, feedback;

  if (data?.project?.subscribers?.nodes && data?.project?.subscribers.nodes.length > 0) {
    subscriber = data?.project?.subscribers?.nodes[0];
    feedback = subscriber?.feedbacks?.nodes || [];
  }

  if (!subscriber) return null;

  return (
    <MinimalFeedbackTable
      nextPage={nextPage}
      previousPage={previousPage}
      feedback={feedback}
      columns={columns}
      pageInfo={subscriber.feedbacks.pageInfo as PageInfo}
      first={first}
      totalCount={subscriber.feedbacks.totalCount || 0}
      updatePageSize={updatePageSize}
      isLoading={isLoading}
      showLink
      emptyState={EmptyTableRenderer('No Feedback')}
      bordered
      onChange={(_, __, sorting) => {
        let field, sort;
        if (Array.isArray(sorting)) {
          sort = sorting[0].order;
          field = sorting[0].columnKey;
        } else {
          sort = sorting.order;
          field = sorting.columnKey;
        }

        if (sort === undefined) {
          updateTableState({
            ...tableState,
            orderBy: undefined,
          });
        } else {
          updateTableState({
            ...tableState,
            orderBy: { field, sort },
          });
        }
      }}
    />
  );
};

export default SubscriberFeedbackTable;