import React from 'react';
import Highlighter from 'react-highlight-words';

const highlightedContent = (content: string, search?: Array<string | undefined>) => (
  (search && search.length > 0) ? (
    <Highlighter
      /* Style lock: Required for highlighting, no AntD override */
      highlightStyle={{ backgroundColor: '#F0F8FF', padding: 0 }}
      searchWords={search as Array<string>}
      autoEscape
      textToHighlight={content}
    />)
    :
    <>{content}</>
);

export default highlightedContent;