import { Layout, Skeleton } from 'antd';
import React from 'react';
import ContentContainer from '../Container';

const { Content } = Layout;

const LoadingContent = () => (
  <ContentContainer>
    <Skeleton active />
  </ContentContainer>
);

export const LoadingContentWithSidebar = () => (
  <Content className="general-loading-content-skeleton" />
);

export default LoadingContent;