import React, { FC } from 'react';
import {
  Avatar,
  AvatarProps,
  Tooltip,
  theme,
} from 'antd';
import { User } from '../../generated/graphql';
import { User as UserContext } from '../../AppContext';

const { useToken } = theme;

export interface UserAvatarProps {
  alt?: string
  color?: string
  gap?: AvatarProps['gap']
  hidden?: boolean
  initials?: string
  size?: AvatarProps['size']
  src?: AvatarProps['src']
  user?: User | UserContext
  tooltip?: boolean
}

const UserAvatar: FC<UserAvatarProps> = ({
  alt,
  color,
  gap,
  hidden,
  initials,
  size,
  src,
  user,
  tooltip = true,
}) => {
  const { token } = useToken();

  const fontSize = () => {
    if (size === 'small') return token.fontSizeSM;
    return token.fontSize;
  };


  if (hidden) return <></>;

  if (user) {
    alt = user.displayName || user.name;
    color = user.avatarColor;
    initials = user.initials;
    src = user.avatarImage?.url;
  }

  const avatar = (
    <Avatar
      alt={alt}
      gap={gap}
      size={size}
      src={src}
      style={{
        backgroundColor: src ? null: color,
        fontSize: fontSize(),
      }}
    >
      {initials}
    </Avatar>
  )

  return tooltip ? (
    <Tooltip title={alt}>
      {avatar}
    </Tooltip>
  ) : avatar;
};

export default UserAvatar;
