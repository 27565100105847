import React from 'react';
import { Button, Space, Typography } from 'antd';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import useKillSession from '@launchnotes/common-hooks/useKillSession';

const { Title } = Typography;

type Props = {
  status?: 'unconfirmed' | 'deactivated' | 'suspended' | 'locked' | 'unauthorized',
  email:string,
};

const LockedPage:React.FC<Props> = ({ status, email }) => {
  const killSession = useKillSession();
  let headerText: string, subText: string;

  if (status === 'unconfirmed') {
    headerText = 'Please confirm your email address';
    subText = `We sent an email to ${email}. Please check your inbox and click through the confirmation email to start using LaunchNotes. `;
  } else if (status === 'deactivated') {
    headerText = 'Your account has been deactivated';
    subText = 'Please contact your project administrator to grant you access to your project';
  } else if (status === 'suspended') {
    headerText = 'Your subscription has been cancelled';
    subText = 'Please contact your project administrator to grant you access to your project';
  } else if (status === 'unauthorized') {
    headerText = 'You don\'t have access to any projects';
    subText = 'Please contact your project administrator to grant you access to your project';
  } else {
    headerText = 'Your account has been locked';
    subText = 'Please contact us at support@launchnotes.com to help getting access to your account';
  }

  return (
    <>
      <Space
        direction="vertical"
        size="large"
        style={{ textAlign: 'center', marginTop: '4rem' }}
      >
        <Title level={1}>{headerText}</Title>
        <Title level={4}>{subText}</Title>
        <Button
          onClick={() => killSession()}
        >
          Log in with a different email
        </Button>
      </Space>
    </>
  );
};

export default LockedPage;
