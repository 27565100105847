import React from 'react';
import Icon from '@ant-design/icons';

const Svg = () => (
  <svg width="16" height="12" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 1C12.8438 1 15.9688 3.46875 15.9688 6.5C15.9688 9.53125 12.8438 12 9 12C8.09375 12 7.21875 11.875 6.375 11.625C6.09375 11.5312 5.75 11.5625 5.5 11.75C4.8125 12.25 3.6875 12.8438 2.28125 13C2.65625 12.5312 3.21875 11.7188 3.5625 10.8125C3.71875 10.4688 3.625 10.0938 3.375 9.78125C2.46875 8.8125 2 7.6875 2 6.5C2 3.46875 5.125 1 9 1ZM9 0C4.5625 0 1 2.9375 1 6.5C1 8 1.59375 9.375 2.625 10.4688C2.1875 11.6875 1.21875 12.75 1.1875 12.75C0.96875 12.9688 0.9375 13.2812 1.03125 13.5625C1.15625 13.8438 1.4375 14 1.75 14C3.65625 14 5.15625 13.2188 6.09375 12.5625C7 12.8438 7.96875 13 9 13C13.4062 13 16.9688 10.0938 16.9688 6.5C16.9688 2.9375 13.4062 0 9 0Z" fill="#1C1917"/>
  </svg>
);

const MessageIcon = () => <Icon component={Svg} />;

export default MessageIcon;
