import React from 'react';
import {
  Card,
  Layout,
} from 'antd';
import { PageContent } from '../Layout';

const ContentContainer = (props: any) => {
  return (
    <Layout {...props}>
      <PageContent>
        <Card>
          {props.children}
        </Card>
      </PageContent>
    </Layout>
  );
};

export default ContentContainer;