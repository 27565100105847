import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext, ContextDataShape, ContextShape } from '../AppContext';
import backendHost from '../API_URL';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';
import useKillSession from './useKillSession';

const useUpdateUserContext = () => {
  const history = useHistory();
  const { authenticatedFetch } = useAuthenticatedFetch();
  const killSession = useKillSession();
  const { updateContext } = useContext<ContextShape>(AppContext);

  const update = useCallback((projectId?: string) => new Promise(async (resolve, reject) => {
    const url = `${backendHost}/users/auth/set_user_context`;

    const response = await authenticatedFetch(url, {
      body: JSON.stringify({
        projectId,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const { payload }: { payload: ContextDataShape } = await response.json();
      updateContext?.((c) => ({
        ...c,
        user: payload.user,
        features: payload.features,
        organization: payload.organization,
        project: payload.project,
      }));
      resolve(payload);
    } else if (projectId && response.status === 403) {
      history.push('/');
      resolve(update());
    } else {
      killSession();
      reject(response.statusText);
    }
  }), [authenticatedFetch, history, killSession, updateContext]);

  return {
    updateContext: update,
  };
};

export default useUpdateUserContext;
