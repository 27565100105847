import React from "react";
import LoadingTakeover from '../components/Loading/Takeover';

const Upgrading: React.FC = () => <LoadingTakeover title="Upgrading the rocket..." />;

export function lazyWithReload<T extends React.ComponentType<any>>(factory: () => Promise<{ default: T }>): React.LazyExoticComponent<T> {
  return React.lazy(async () => {
    try {
      return await factory();
    } catch (error) {
      const now = Date.now();
      const lastReload = parseInt(window.sessionStorage.getItem('last_reload') || '', 10);
      if ((now - lastReload) < 30000) {
        throw error
      } else {
        window.sessionStorage.setItem('last_reload', now.toString())
        window.location.reload(true);
        return Promise.resolve({ default: Upgrading }) as Promise<{ default: T }>;
      }
    }
  });
}