import React from 'react';
import { FallbackRender } from '@sentry/react/dist/errorboundary';
import { Button, Result } from 'antd';

const chunkFailedMessage = /Loading chunk [\d]+ failed/;

const ErrorFallback: React.ReactNode | FallbackRender = ({ resetError, error }) => {
  const goBack = () => {
    if (error?.message && chunkFailedMessage.test(error.message)) {
      window.location.reload(true);
    } else {
      resetError();
    }
  };

  console.error(error);

  return (
    <Result
      status="500"
      title="UH OH!"
      subTitle="It looks like we're having an issue. Our team has been notified."
      extra={<Button type="primary" onClick={goBack}>Go back</Button>}
    />
  );
};

export default ErrorFallback;