import { message } from 'antd';
import { useCallback } from 'react';
import { SubscriberTypes, useCreateUserProjectSubscriptionMutation } from '../generated/graphql';
import { useUserContext } from '../AppContext';

export const useCreateUserProjectSubscription = (project: LN.Project) => {
  const user = useUserContext();
  const { mutate } = useCreateUserProjectSubscriptionMutation({
    onSuccess: () => {
      message.success('Your subscription has been created');
    },
    onError: (error) => {
      message.error(`We were unable to create a subscription ${error}`);
    },
  });

  const onSubmit = useCallback((categories?: string[]) => {
    if (!user) return;
    mutate({
      input: {
        projectSubscription: {
          projectId: project.id,
          subscriber: {
            type: SubscriberTypes.Subscriber,
            email: user.email,
          },
          categories: categories?.map((id) => ({ id })) || project?.categories?.nodes,
        },
      },
    });
  }, [mutate, project, user]);

  return [onSubmit];
};
