import React, { FC, useMemo, useState } from 'react';
import {
  Feedback,
  PageInfo,
  SortEnum,
  InputMaybe,
  OrderBy,
  useAudienceDomainFeedbackQuery,
} from '../../generated/graphql';
import SentimentIndicator from '../Sentiment/SentimentIndicator';
import { MinimalFeedbackTable } from '../Feedback/FeedbackTable';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import {useSubscriberDrawer} from '../Subscribers/DetailDrawer';
import {usePagination} from '../SuperTable/Pagination';
import type { ColumnsType } from 'antd/es/table';
import EmptyTableRenderer from './EmptyTableState';
import email from '../SuperTable/columns/email';
import dateTime from '../SuperTable/columns/dateTime';
import HighImportance from '@launchnotes/icons/HighImportance';
import MediumImportance from '@launchnotes/icons/MediumImportance';
import LowImportance from '@launchnotes/icons/LowImportance';
import { Key, SortOrder } from 'antd/lib/table/interface';
import { Space, Tooltip, Typography } from 'antd';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faCircleQuestion,
} from '@fortawesome/pro-regular-svg-icons'

type TableState = {
  orderBy?: {
    field: string | Key | undefined,
    sort: SortOrder
  }
};

const DomainFeedbacksTable: FC<{ id: string }> = ({ id }) => {
  const projectId = useProjectId();
  const { subscriberDrawer, showSubscriberDrawer } = useSubscriberDrawer();

  const [tableState, updateTableState] = useState<TableState>();

  let orderBy: InputMaybe<OrderBy> | undefined;

  if (tableState?.orderBy?.field) {
    orderBy = {
      field: tableState.orderBy.field.toString(),
      sort: tableState.orderBy.sort === 'descend' ? SortEnum.Desc : SortEnum.Asc,
    };
  }

  const { previousPage, nextPage, updatePageSize, first, before, after } = usePagination();

  const { data, isLoading } = useAudienceDomainFeedbackQuery({
    id,
    projectId,
    first,
    before,
    after,
    orderBy: orderBy || {
      field: 'createdAt',
      sort: SortEnum.Desc,
    },
  });

  const columns: ColumnsType<Feedback> = useMemo(() => [
    email<Feedback>(
      'Email',
      ['affectedCustomer'],
      'email',
      {
        onClick: showSubscriberDrawer,
        subscriberIdIndex: ['affectedCustomer', 'id'],
      },
    ),
    {
      title: (
        <Typography.Text>
          Sentiment{' '}
          <Tooltip title='LaunchNotes applies machine learning to identify the emotional tone behind feedback.'>
            <FontAwesomeIcon icon={faCircleQuestion} />
          </Tooltip>
        </Typography.Text>
      ),
      key: 'sentimentScore',
      dataIndex: ['sentimentScore'],
      sorter: true,
      showSorterTooltip: false,
      width: '20%',
      render: (sentimentScore: number | undefined) => (
        <SentimentIndicator
          sentimentScore={sentimentScore}
          displayScore
          titleOverride={`This feedback has a sentiment score of ${sentimentScore?.toFixed(2)}.`}
        />),
    },
    {
      title: 'Importance',
      key: 'importance',
      dataIndex: ['importance'],
      sorter: true,
      width:'20%',
      render: (importance: 'high' | 'medium' | 'low' | undefined) => {
        if (importance === 'high') {
          return <HighImportance />;
        } else if (importance === 'medium') {
          return <MediumImportance />;
        } else if (importance === 'low') {
          return <LowImportance />;
        } else {
          return <Space className="no-reaction">-</Space>;
        }
      },
    },
    dateTime(
      'Created',
      ['createdAt'],
      'createdAt',
    ),
  ], [showSubscriberDrawer]);

  return (
    <>
      <MinimalFeedbackTable
        nextPage={nextPage}
        previousPage={previousPage}
        feedback={data?.project?.audienceDomain?.feedbacks.nodes as Feedback[]}
        columns={columns}
        pageInfo={data?.project?.audienceDomain?.feedbacks.pageInfo as PageInfo}
        first={first}
        totalCount={data?.project?.audienceDomain?.feedbacks.totalCount}
        updatePageSize={updatePageSize}
        isLoading={isLoading}
        showLink
        emptyState={EmptyTableRenderer('No Feedback')}
        onChange={(_, __, sorting) => {
          let field, sort;
          if (Array.isArray(sorting)) {
            sort = sorting[0].order;
            field = sorting[0].columnKey;
          } else {
            sort = sorting.order;
            field = sorting.columnKey;
          }

          if (sort === undefined) {
            updateTableState({
              ...tableState,
              orderBy: undefined,
            });
          } else {
            updateTableState({
              ...tableState,
              orderBy: { field, sort },
            });
          }
        }}
      />
      {subscriberDrawer}
    </>
  );
};

export default DomainFeedbacksTable;