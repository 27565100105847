import React from 'react';
import Icon from '@ant-design/icons';

const Svg = () => (
  <svg width="65" height="80" viewBox="0 0 65 80" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M64.8082 55.9617H57.9626C54.9998 65.9744 45.7434 73.2807 34.7868 73.2807C34.7868 73.2807 34.7868 73.2807 34.7804 73.2807C33.2895 73.2807 32.065 72.0863 32.0333 70.5933C31.6353 51.4892 31.6632 32.3359 31.9762 13.2294C32.0333 9.92566 34.5711 7.20645 37.8511 6.90785L42.6347 6.46947V0.624435H0.736725V6.46947C6.19285 7.02221 10.5304 11.5839 10.5939 17.0731C10.5939 34.2497 10.5939 46.5711 10.5939 63.0329C10.5304 68.5285 6.1992 73.0965 0.736725 73.6365V79.4816H63.5393L64.8082 55.9553V55.9617Z" fill="#F7F5EE"/>
  </svg>
);
const LogoL = (props: any) => <Icon component={Svg} {...props} />;

export default LogoL;
