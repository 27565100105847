import React from 'react';
import {ColumnType} from 'antd/lib/table';
import {Tag} from 'antd';
import {FilterValue} from 'antd/lib/table/interface';
import {SubscriptionStatus} from '../../../generated/graphql';

export const statusBadge = (value: SubscriptionStatus) => {
  switch (value) {
  case SubscriptionStatus.Confirmed:
    return <Tag color="success">Confirmed</Tag>;
  case SubscriptionStatus.Unconfirmed:
    return <Tag color="default">Unconfirmed</Tag>;
  case SubscriptionStatus.Unsubscribed:
    return <Tag color="error">Unsubscribed</Tag>;
  case SubscriptionStatus.Blocked:
    return <Tag color="error">Blocked</Tag>;
  }
};

const status = <T extends { status: SubscriptionStatus.Unsubscribed | SubscriptionStatus.Unconfirmed | SubscriptionStatus.Confirmed },> (filteredValue?:  FilterValue | null): ColumnType<T> => {
  return (
    {
      filters: [
        { text: 'Confirmed', value: 'confirmed' },
        { text: 'Unconfirmed', value: 'unconfirmed' },
        { text: 'Unsubscribed', value: 'unsubscribed' },
        { text: 'Blocked', value: 'blocked' },
      ],
      title: 'Status',
      dataIndex: ['status'],
      key: 'status',
      sorter: false,
      filteredValue,
      filterMultiple: true,
      align: 'left',
      width: 75,
      render: statusBadge,
    }
  );
};

export default status;
