import React, { useState, useMemo, useCallback } from "react";
import type { DefaultCategoryProps } from "./";
import { Button, Typography, Row, Col } from "antd";
import { Link } from 'react-router-dom';
import { useCreateCategory } from './hooks/useCreateCategory';
import { useRemoveCategory } from "./hooks/useRemoveCategory";
import { CategoryConnection, WizardCategoriesStepFragment } from '../../../generated/graphql';
const { Text, Paragraph } = Typography;

type CategoryComponentProps = {
  project: any;
  category: DefaultCategoryProps;
  index: number;
}

type Props = {
  project: WizardCategoriesStepFragment;
  defaultCategories: Array<DefaultCategoryProps>;
};

const Category = (props: CategoryComponentProps) => {
  const categories = props?.project?.categories as CategoryConnection;
  const matchingCategory = useMemo(() => {
    return categories?.edges?.find((edge) => edge?.node?.name === props?.category?.name)
  }, [categories, props]);
  const [active, setActive] = useState<boolean>(!!matchingCategory)
  const [onCreate] = useCreateCategory();
  const [onRemove] = useRemoveCategory(matchingCategory?.node?.id ?? '');


  const toggleCategory = useCallback(() => {
    if (active) {
      onRemove();
      setActive(false);
    } else {
      onCreate({name: props.category.name});
      setActive(true);
    }
  }, [props.category, active, setActive, onRemove, onCreate]);

  return (
    <Col
      className={`category-add-container ${active ? "active" : ""}`}
      onClick={toggleCategory}
      span={24}
    >
      {props.category.displayName}
    </Col>
  )
};

const CategoriesStep = (props: Props) => {
  const project: WizardCategoriesStepFragment = props.project;
  const previousUrl = useMemo(() => `/projects/${project.id}/welcome/customize`, [project]);
  const nextUrl = useMemo(() => `/projects/${project.id}/welcome/subscribers`, [project]);

  return (
    <>
      <Row>
        <Col className="onboarding-fields categories">
          <Paragraph>
            LaunchNotes lets you tag which parts of your product are going to be
            affected by a given change.
          </Paragraph>
          <Paragraph>
            We call these <Text strong>categories</Text>.
          </Paragraph>
          <Paragraph>
            Which of the following <em>categories</em> does your product have?
          </Paragraph>
          <Row className="category-adds-container">
            {props.defaultCategories.map((category: any, index: number) => (
              <Category
                key={index}
                project={project}
                category={category}
                index={index}
              />
            ))}
            <Col span={24} className="small">
              Don’t worry. You’ll be able to make changes later.
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between" className="buttons-container">
        <Col>
          <Link to={previousUrl}>
            <Button size="small" type="text">Back</Button>
          </Link>
        </Col>
        <Col>
          <Link to={nextUrl}>
            <Button
              size="large"
              type="primary"
              disabled={project.categories!.edges!.length < 1}
            >
              Next
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col className="step-progress">3/5</Col>
      </Row>
    </>
  );
};
export default CategoriesStep;
