export const PROJECT_ADMIN_PRIVILEGES =   [
    "create:announcement",
    "publish:announcement",
    "create:work_item",
    "publish:work_item",
    "create:idea",
    "publish:idea",
    "create:feedback",
    "manage:categories",
    "manage:api_keys",
    "manage:secure_connection",
]
export const ORG_ADMIN_PRIVILEGES =   [
    "manage:billing"
]
