import React, { FC } from 'react';
import { Divider } from 'antd';

interface Props {
  style?: React.CSSProperties; // Allow passing custom style
}

export const LowPaddingDivider: FC<Props> = ({ style }) => {
  const defaultStyle: React.CSSProperties = {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0
  };

  // Merge default style with custom style, if provided
  const mergedStyle = { ...defaultStyle, ...style };

  return <Divider style={mergedStyle} />
};