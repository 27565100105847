import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Menu, Row, Space, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import HighImportance from '@launchnotes/icons/HighImportance';
import MediumImportance from '@launchnotes/icons/MediumImportance';
import LowImportance from '@launchnotes/icons/LowImportance';
import SadFace from '@launchnotes/icons/SadFace';
import MehFace from '@launchnotes/icons/MehFace';
import Time from '../Time';
import { feedbackLabel } from './utils';
import { ActionsWithChildren } from '../Actions';
import { FeedbackTableFeedback } from './FeedbackTable';
import {useSearchParams} from '@launchnotes/common-hooks/useSearchParams';
import highlightedContent from '../../utils/highlightedContent';
import { UserAvatar } from '@launchnotes/components/Avatar'
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faFaceSmile,
  faArrowRotateLeft,
  faTrash,
  faPencil,
  faFolder,
} from '@fortawesome/pro-regular-svg-icons'

export const affectedCustomerEmailColumn: ColumnType<FeedbackTableFeedback> = {
  title: 'Email',
  dataIndex: 'affectedCustomerEmail',
  key: 'email',
  width: '30%',
  ellipsis: true,
  sorter: true,
  render: (color: string, email: string, initials: string) => (
    <Space>
      <UserAvatar alt={email} color={color} email={email} size={30} initials={initials} />
      <Typography.Text copyable>{email}</Typography.Text>
    </Space>
  ),
};

export const createdAtColumn: ColumnType<FeedbackTableFeedback> = {
  title: 'Date',
  dataIndex: 'createdAt',
  key: 'created_at',
  sorter: true,
  render: (text: string) => <Time timestamp={text} local />,
};

export const importanceColumn: ColumnType<FeedbackTableFeedback> = {
  title: 'Importance',
  dataIndex: 'importance',
  key: 'importance',
  align: 'center',
  width: '10%',
  render: (importance: string) => {
    if (importance === 'high') {
      return <HighImportance />;
    } else if (importance === 'medium') {
      return <MediumImportance />;
    } else if (importance === 'low') {
      return <LowImportance />;
    } else {
      return <Space className='no-reaction'>-</Space>;
    }
  },
};

export const reactionColumn: ColumnType<FeedbackTableFeedback> = {
  title: 'Reaction',
  dataIndex: 'reaction',
  key: 'reaction',
  align: 'center',
  width: '10%',
  render: (reaction: string) => {
    if (reaction === 'happy') {
      return <FontAwesomeIcon icon={faFaceSmile} className='success-color' />;
    } else if (reaction === 'sad') {
      return <SadFace className='warn-color' />;
    } else if (reaction === 'meh') {
      return <MehFace />;
    } else {
      return <Space className='no-reaction'>-</Space>;
    }
  },
};

type FeedbackableColumnProps = {
  feedbackable: LN.Feedbackable | null | undefined;
}
const FeedbackableColumn: React.FC<FeedbackableColumnProps> = ({
  feedbackable,
}) => {
  const { searchParams } = useSearchParams<{
    search: string,
    token: string,
  }>();

  if (!feedbackable) return null;
  const { icon } = feedbackLabel(feedbackable.__typename);
  const url = new URL(feedbackable.privatePermalink);
  const highlightedName = highlightedContent(feedbackable.name, [searchParams.search, searchParams.token]);

  return (
    <>
        {feedbackable.archived && feedbackable.__typename !== "Idea" ? (
          <Typography.Text style={{ display: "block", maxWidth: 200 }}>
            {icon} {highlightedName}
          </Typography.Text>
        ) : (
          <Link to={url.pathname + url.search + url.hash}>
            <Tooltip title={feedbackable.name}>
              <Typography.Text
                style={{ display: "block", maxWidth: 200 }}
                ellipsis
              >
                {icon} {highlightedName}
              </Typography.Text>
            </Tooltip>
          </Link>
        )}
    </>
  );
};

export const organizeColumn = ({
  onArchive,
  onOrganize,
}: {
  onArchive?: (feedback: FeedbackTableFeedback) => void;
  onOrganize?: (feedback: FeedbackTableFeedback) => void;
}): ColumnType<FeedbackTableFeedback> => ({
  title: 'Organize',
  key: 'organize',
  align: 'left',
  width: '200px',
  render: (_, feedback) => feedback.feedbackable ? (
    <FeedbackableColumn feedbackable={feedback.feedbackable} />
  ) : !feedback.archived ? (
    <Row align='middle' className='organize-actions' gutter={24}>
      {onOrganize && (
        <Tooltip title='Organize feedback under an idea, roadmap item, or announcement' >
          <Col
            onClick={() => onOrganize(feedback)}
          >
            <Button className='action-button' data-cy='organize-feedback'><FontAwesomeIcon icon={faFolder} /></Button>
          </Col>
        </Tooltip>
      )}
      {onArchive && (
        <Tooltip title='Archive this feedback'>
          <Col
            onClick={() => onArchive(feedback)}
          >
            <Button danger className='action-button' data-cy='archive-feedback'><FontAwesomeIcon icon={faTrash} /></Button>
          </Col>
        </Tooltip>
      )}
    </Row>
  ) : null,
});

export const actionsColumn = ({
  onArchive,
  onEdit,
  onOrganize,
  onRestore,
}: {
  onArchive?: (feedback: FeedbackTableFeedback) => void;
  onEdit?: (feedback: FeedbackTableFeedback) => void;
  onOrganize?: (feedback: FeedbackTableFeedback) => void;
  onRestore?: (feedback: FeedbackTableFeedback) => void;
}): ColumnType<FeedbackTableFeedback> => ({
  title: '',
  key: 'actions',
  align: 'right',
  width: '32px',
  render: (_, feedback) => {
    const archived = feedback.archived || feedback.feedbackable?.archived;
    const items: React.ReactNode[] = [];
    if (onEdit && feedback.reporter && !archived) {
      items.push((
        <Menu.Item
          key='edit'
          icon={<FontAwesomeIcon icon={faPencil} className='action-icon edit' />}
          onClick={() => onEdit(feedback)}
        >
        Edit
        </Menu.Item>
      ));
    }
    if (onOrganize) {
      items.push((
        <Menu.Item
          key='organize'
          icon={<FontAwesomeIcon icon={faFolder} />}
          onClick={() => onOrganize(feedback)}
        >
          {feedback.feedbackable ? 'Reorganize' : 'Organize'}
        </Menu.Item>
      ));
    }
    if (archived) {
      if (onRestore) {
        const disabled = feedback.feedbackable?.archived || false;
        items.push((
          <Menu.Item
            key='restore'
            disabled={disabled}
            danger={!disabled}
            icon={<FontAwesomeIcon icon={faArrowRotateLeft} />}
            onClick={() => onRestore(feedback)}
          >
            <Tooltip title={disabled && `Unable to restore to an archived ${feedbackLabel(feedback.feedbackable?.__typename).label}`}>
              Restore
            </Tooltip>
          </Menu.Item>
        ));
      }
    } else if (onArchive) {
      items.push((
        <Menu.Item
          key='archive'
          danger
          icon={<FontAwesomeIcon icon={faTrash} className={'action-icon'} />}
          onClick={() => onArchive(feedback)}
        >
          Archive
        </Menu.Item>
      ));
    }

    return !items.length ? null : (
      <Space size='large'>
        <ActionsWithChildren dropdownProps={{ placement: 'bottomRight' }}>
          {items}
        </ActionsWithChildren>
      </Space>
    );
  },
});
