import { ColumnType } from 'antd/lib/table';
import { useSearchParams } from '@launchnotes/common-hooks/useSearchParams';
import { SortEnum } from '../../../generated/graphql';

export function useColumns<T>(columns: (ColumnType<T> | '' | boolean | null | undefined)[]): ColumnType<T>[] {
  const { searchParams } = useSearchParams<{
    sort: string,
    sortOrder: SortEnum,
  }>();

  return columns
    .map((c) => {
      if (!c || c === true) return null;
      if (c.sorter && c.key === searchParams.sort) {
        if (searchParams.sortOrder === SortEnum.Asc) {
          c.sortOrder = 'ascend'
        } else if (searchParams.sortOrder === SortEnum.Desc) {
          c.sortOrder = 'descend'
        } else {
          c.sortOrder = null
        }
      } else {
        c.sortOrder = null;
      }
      return c;
    })
    .filter((c) => !!c) as ColumnType<T>[];
}
