import React, { FC } from 'react';
import { ConfigProvider, Tag } from 'antd';
import { lnTokens } from '../../../Router';

interface Props {
  children: React.ReactNode;
  darkMode?: boolean;
  colorOverride?: string;
  style?: React.CSSProperties; // Allow passing custom style
}

export const SkeletonTag: FC<Props> = ({ children, darkMode, colorOverride, style }) => {
  const modeColor = darkMode ? lnTokens.cosmicDust : lnTokens.cosmos;
  const color = colorOverride ? colorOverride : modeColor;
  return (
    <ConfigProvider
      theme={{
        components: {
          Tag: {
            defaultBg: 'transparent',
            defaultColor: color,
            colorBorder: color,
          }
        }
      }}
    >
      <Tag style={style}>
        {children}
      </Tag>
    </ConfigProvider>
  );
};
