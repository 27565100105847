import React, { FC, useMemo, useCallback } from 'react';
import { Event, useInfiniteSubscriberEventsQuery, SubscriberEventsQuery } from '../../generated/graphql';
import { Skeleton, Typography, message } from 'antd';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import EventRow from './EventRow/EventRow';
import { format, parseISO } from 'date-fns';
import LoadMore from '../Loading/LoadMore';

const RecentNotificationsTable: FC<{ id: string }> = ({ id }) => {
  const projectId = useProjectId();
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteSubscriberEventsQuery<SubscriberEventsQuery>({
    projectId,
    id,
    count: 25,
  }, {
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      if (!lastPage || !lastPage.project) {
        return;
      }
      const subscribers = lastPage?.project?.subscribers?.nodes;
      if (!subscribers || subscribers.length === 0) {
        return;
      }

      const subscriber = subscribers[0];
      if (subscriber?.events.pageInfo.hasNextPage) {
        return {
          cursor: subscriber.events.pageInfo.endCursor,
        };
      }
    },
  });

  // Since we are using the infinite scroll version of this
  // query, it comes back in pages, each page of which should
  // have only the subscriber we care about in question.
  // This reduces the events from the subscriber in each page
  //  into a single array of events to be used in the timeline
  const events = useMemo(() => {
    return !data ? [] : data?.pages.reduce<Event[]>((a, page) => {
      const subscribers = page.project.subscribers?.nodes || [];
      if (!subscribers || subscribers.length === 0) return a;

      const subscriber = subscribers[0];
      if (!subscriber || !subscriber.events) return a;

      const nodes = subscriber.events.nodes || [];

      const nextEvents = nodes.map((node) => node ? node : null)
        .filter((event): event is NonNullable<typeof event> => !!event);

      return a.concat(nextEvents as Event[]);
    }, []);
  }, [data]);

  const handleLoadMore = useCallback(async () => {
    if (!hasNextPage || isFetchingNextPage) return;
    try {
      await fetchNextPage();
    } catch (e) {
      if (e)  message.error('Error pulling more feedback');
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  if (isLoading) {
    return <Skeleton />;
  }

  const shouldDisplayDate = (index: number, dates: string[]) => {
    if (index === 0) {
      return true;
    }
    return dates[index] !== dates[index - 1];
  };

  const shouldHideTail = (index: number, dates: string[]) => {
    // If this is the only event, hide the tail
    if (index === 0 && dates.length === 1) {
      return true;
    }

    if (index === dates.length - 1) {
      // If this is the last event, never show
      return true;
    }

    // If both dates don't match this one, its the only event of
    // the day, hide the tail
    return dates[index] !== dates[index + 1];
  };

  if (events && events.length > 0) {
    const dates = events.map((event) => format(parseISO(event.createdAt), 'MM:d:yyyy'));

    return (
      <>
        {
          events.filter((event) => !!event)
            .map((event, index) => (
              <EventRow
                key={event.id}
                event={event as Event}
                shouldDisplayDate={shouldDisplayDate(index, dates)}
                shouldHideTail={shouldHideTail(index, dates)}
              />
            ))
        }

        <LoadMore
          hasMore={hasNextPage || false}
          loadMore={handleLoadMore}
          loading={isFetchingNextPage}
          scroll
        />
      </>
    );
  } else {
    return <Typography.Text type="secondary">We haven't seen anything yet</Typography.Text>;
  }
};

export default RecentNotificationsTable;