import { useUserContext } from '../AppContext';

const useUserPrivileges = (contextId: string): string[] => {
  const user = useUserContext();
  const projectPrivileges = user.project_privileges;
  const organizationPrivileges = user.organization_privileges;

  const all_privileges = { ...projectPrivileges, ...organizationPrivileges };

  return user && all_privileges[contextId];
};

export default useUserPrivileges;
