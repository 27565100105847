import React, { useEffect, useState } from 'react';
import { Card, ConfigProvider, Layout, ThemeConfig } from 'antd';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { usePostHog } from 'posthog-js/react';
import Breadcrumbs, { Crumb } from '../AppContext/Breadcrumbs';
import { useUserContext, useOrganizationContext, useProjectContext } from '../AppContext';
import { LoadingContentWithSidebar } from '../components/Loading/Content';
import Sidebar from '../components/Sidebar';
import LockedPage from '../components/LockedPage';
import LoadingTakeover from '../components/Loading/Takeover';
import OnboardingWizard from '../pages/Onboarding/Wizard';
import WhatsNew from './WhatsNew';
import { lazyWithReload } from './lazyWithReload';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import AntdGlobals from '../utils/antGlobals';
import useUserPrivileges from '@launchnotes/common-hooks/useUserPrivileges';
import { PROJECT_ADMIN_PRIVILEGES } from '../PermissionConstants';
import {environment} from '../index';
import OnboardingSetup from '../pages/Onboarding/Setup'

const ProjectsRouter = lazyWithReload(() => import('./ProjectsRouter'));
const NoProjectsPage = lazyWithReload(
  () => import('../pages/Error/NoProjectsPage'),
);

const lnTokens = {
  cosmos: '#192A3E',
  phobos: '#FF7E48',
  mars: '#ED6E39',
  venus: '#EBB936',
  venusLight: 'rgba(235, 185, 54, 0.18)',
  jupiter: '#DDA81E',
  titan: '#6ED6B6', // rgb(110, 214, 182)
  titanBg: 'rgba(110, 214, 182, 0.2)',
  saturn: '#56B89A', // rgb(86, 184, 154)
  neptune: '#248CC3', // rgb(36, 140, 195), used to be '#147CB3',
  starlight: '#025C8F', // used to be '#126C9F'
  starlightBg: 'rgba(18, 108, 159, 0.1)',
  nebula: '#2E435B', // rgb(46, 67, 91)
  sheen: '#CBE3F1',
  comet: '#636E79',
  asteroid: '#949BA0',
  satellite: '#C6C8C7',
  cosmicDust: '#DFDFDB',
  moon: '#F7F5EE',
  cosmicAstronaut: '#F2F1EE',
  astronaut: '#FBFAF7',
  warning: '#CC4B14',
  warningBg: 'rgba(204, 75, 20, 0.1)',
  error: '#BC2815',
  errorBg: 'rgba(188, 40, 21, 0.1)',
  errorDark: '#9D2112',
  errorDarker: '#7D1B0E',
  buttonShadow: '2px 2px 3px rgba(0, 0, 0, 0.08)',
  boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
  boxShadowSecondary: '0 6px 16px 0 rgba(0, 255, 0, 0.06), 0 3px 6px -4px rgba(0, 0, 0, 0.10), 0 9px 28px 8px rgba(0, 0, 0, 0.03)',
  boxShadowTertiary: '0 1px 2px 0 rgba(0, 0, 0, 0.04), 0 1px 6px -1px rgba(0, 0, 0, 0.03), 0 2px 4px 0 rgba(0, 0, 0, 0.03)',
  mainMenuIndent: 16,
};

const lnTheme: ThemeConfig = {
  token: {
    // seed tokens
    borderRadius: 4,
    colorError: lnTokens.error,
    colorInfo: lnTokens.starlight,
    colorLink: lnTokens.starlight,
    colorPrimary: lnTokens.starlight,
    colorSuccess: lnTokens.saturn,
    colorTextBase: lnTokens.cosmos,
    colorWarning: lnTokens.warning,

    // map tokens
    borderRadiusLG: 8,
    borderRadiusSM: 4,
    borderRadiusXS: 4,
    colorBgContainer: '#FFFFFF',
    colorBgLayout: lnTokens.astronaut,
    colorBgSpotlight: lnTokens.cosmos,
    colorBorder: lnTokens.cosmicDust,
    colorBorderSecondary: lnTokens.moon,
    colorErrorActive: lnTokens.errorDarker,
    colorErrorBg: lnTokens.errorBg,
    colorErrorHover: lnTokens.errorDark,
    colorInfoBg: lnTokens.starlightBg,
    colorSuccessBg: lnTokens.titanBg,
    colorWarningBg: lnTokens.warningBg,
    colorTextDescription: lnTokens.comet,
    fontSizeHeading1: 32,
    fontSizeHeading2: 22,
    fontSizeHeading3: 16,
    fontSizeHeading4: 14,
    fontSizeHeading5: 12,

    // alias tokens
    boxShadow: lnTokens.boxShadow,
    boxShadowSecondary: lnTokens.boxShadowSecondary,
    boxShadowTertiary: lnTokens.boxShadowTertiary,
    colorBgContainerDisabled: lnTokens.moon,
    colorSplit: lnTokens.cosmicDust,
    colorTextDisabled: lnTokens.asteroid,
    colorTextPlaceholder: lnTokens.asteroid,
    controlOutline: lnTokens.cosmicDust,
    controlOutlineWidth: 1,
  },
  components: {
    Alert: {
      colorInfo: lnTokens.starlight,
      colorInfoBg: lnTokens.sheen,
      colorWarning: lnTokens.warning,
      colorWarningBg: lnTokens.warningBg,
    },
    Avatar: {
      groupOverlapping: -4,
    },
    Button: {
      colorPrimary: lnTokens.titan,
      colorPrimaryActive: lnTokens.saturn,
      primaryColor: lnTokens.cosmos,
      colorPrimaryHover: lnTokens.saturn,
      colorPrimaryBorder: 'transparent',
      colorTextLightSolid: lnTokens.cosmos,
      colorLinkHover: lnTokens.neptune,
      colorLinkActive: lnTokens.neptune,
      dangerColor: lnTokens.astronaut,
      primaryShadow: lnTokens.buttonShadow,
      defaultShadow: lnTokens.buttonShadow,
      dangerShadow: lnTokens.buttonShadow,
    },
    Card: {
      padding: 16,
      paddingLG: 16,
      headerFontSize: 16,
      headerHeightSM: 44,
      colorTextHeading: lnTokens.nebula,
    },
    Collapse: {
      headerBg: 'white',
    },
    Divider: {
    },
    Form: {
      itemMarginBottom: 24,
      labelFontSize: 14,
    },
    Drawer: {
      colorText: lnTokens.nebula,
    },
    Layout: {
      siderBg: lnTokens.cosmos,
    },
    Menu: {
      itemActiveBg: lnTokens.nebula,
      itemBg: lnTokens.cosmos,
      subMenuItemBg: lnTokens.cosmos,
      popupBg: lnTokens.cosmos,
      itemSelectedBg: lnTokens.nebula,
      itemSelectedColor: lnTokens.titan,
      horizontalItemSelectedColor: lnTokens.titan,
      horizontalItemHoverColor: lnTokens.titan,
      itemHoverColor: lnTokens.titan,
      itemMarginInline: 0,
      itemMarginBlock: 0,
      itemBorderRadius: 0,
      itemHoverBg: 'rgba(0, 0, 0, 0)',
      colorText: lnTokens.cosmicDust,
      colorBgContainer: lnTokens.cosmos,
      colorPrimary: lnTokens.cosmicDust,
      colorBgElevated: lnTokens.cosmos,
      colorSplit: lnTokens.nebula,
      fontSizeLG: 20,
      collapsedIconSize: 16,
    },
    Modal: {
      titleFontSize: 16,
    },
    Radio: {
    },
    Select: {
      multipleItemBg: lnTokens.moon,
      multipleItemBorderColor: lnTokens.cosmicDust,
    },
    Statistic: {
      titleFontSize: 14,
      contentFontSize: 24,
      colorText: lnTokens.nebula,
      colorTextDescription: lnTokens.nebula,
    },
    Tabs: {},
    Tag: {
      colorInfoBg: lnTokens.astronaut,
      colorSuccessBg: lnTokens.astronaut,
      colorProcessingBg: lnTokens.astronaut,
      colorWarningBg: lnTokens.astronaut,
      colorErrorBg: lnTokens.astronaut,
    },
    Table: {
      borderColor: lnTokens.moon,
      headerColor: lnTokens.nebula,
      headerBg: lnTokens.cosmicAstronaut,
      headerSplitColor: lnTokens.cosmicDust,
      expandIconBg: lnTokens.moon,
      headerSortActiveBg: lnTokens.astronaut,
      headerSortHoverBg: lnTokens.astronaut,
      fixedHeaderSortActiveBg: lnTokens.astronaut,
    },
    Tooltip: {
      colorText: lnTokens.cosmicDust,
      colorTextLightSolid: lnTokens.cosmicDust,
    },
    Typography: {
      fontWeightStrong: 500,
      linkHoverDecoration: 'underline',
      titleMarginTop: 0,
    },
  },
};

export const BaseChartTheme = {
  styleSheet: {
    fontFamily: 'sans-serif',
    fontSize: '20px',
    brandColor: lnTokens.neptune,
  },
  defaultColor: lnTokens.neptune,
  colors10: [lnTokens.titan, lnTokens.neptune, lnTokens.jupiter],
};

const App = () => {
  const [breadcrumbs, updateBreadcrumbs] = useState([] as Crumb[]);
  const posthog = usePostHog();
  const user = useUserContext();
  const organization = useOrganizationContext()
  const project = useProjectContext();
  const projectId = useProjectId();
  const onboardingPath = useRouteMatch('/onboarding');
  const privileges = useUserPrivileges(projectId);
  const isAdmin = PROJECT_ADMIN_PRIVILEGES.every((privilege) =>
    privileges?.includes(privilege),
  );
  const [collapsed, setCollapsed] = useState(false);

  // Only use this when Ant does not let us accomplish what we need.
  const GlobalStyle = createGlobalStyle`
    .ant-table-column-sorters {
      float: left;
    }
    
    .ant-switch:not(.ant-switch-checked):not(.ant-switch-disabled) {
      background: transparent;
      border: 1px solid ${lnTokens.satellite};

      &:hover {
        border: 1px solid ${lnTokens.asteroid};

        .ant-switch-handle::before {
          background-color: ${lnTokens.asteroid};
        }
      }

      .ant-switch-handle {
        height: 16px;
        width: 16px;

        &::before {
          background-color: ${lnTokens.satellite};
        }
      }

      &.ant-switch-small .ant-switch-handle {
        height: 10px;
        width: 10px;
      }
    }
  `;

  // Only borderless cards have box shadow.
  Card.defaultProps = { bordered: false };

  useEffect(() => {
    if (user && environment !== 'development') {
      posthog?.identify(user.id, {
        email: user.email,
        /* eslint-disable camelcase */
        project_id: project?.id,
        organization_id: project?.organizationId,
        active_tier: project?.activeTier,
        /* eslint-enable camelcase */
      });
    }
  }, [posthog, user]);

  if (user) {
    const defaultProject = user.defaultProject;

    if (!organization && user.status === 'active') {
      return <ConfigProvider theme={lnTheme}>
      <GlobalStyle />
        <Layout style={{ minHeight: '100vh' }}>
          <OnboardingSetup />
        </Layout>
      </ConfigProvider>;
    } else if (!project) {
      return <ConfigProvider theme={lnTheme}>
        <GlobalStyle />
        <Layout style={{ minHeight: '100vh' }}>
          <LockedPage status={'unauthorized'} email={user.email} />
        </Layout>
      </ConfigProvider>;

    } else if (user.status === 'active' || user.status === 'invited') {
      if (project?.billingStatus !== 'suspended' || isAdmin) {
        if (onboardingPath && project) {
          return <Redirect to={`/projects/${project.id}/onboarding`} />;
        }

        return (
          <ConfigProvider theme={lnTheme}>
            <GlobalStyle />
            <Layout style={{ minHeight: '100vh' }}>
              <Switch>
                {project && (
                  <Route path={`/projects/${project.id}/welcome/:step`}>
                    <OnboardingWizard />
                  </Route>
                )}
                <Route path='/projects/:projectId'>
                  <Sidebar
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                  />
                  <Layout style={{
                    boxSizing: 'border-box',
                    width: '100%',
                    minHeight: '100vh',
                    paddingLeft: collapsed ? '60px' : '200px',
                  }}>
                    <Breadcrumbs.Provider value={{ updateBreadcrumbs }}>
                      <React.Suspense fallback={<LoadingContentWithSidebar />}>
                        <ProjectsRouter />
                      </React.Suspense>
                    </Breadcrumbs.Provider>
                  </Layout>
                </Route>
                {defaultProject ? (
                  <Redirect to={`/projects/${user?.defaultProject}`} />
                ) : (
                  <>
                    <Route path='/projects'>
                      <React.Suspense
                        fallback={
                          <LoadingTakeover title='Tightening the bolts...' />
                        }
                      >
                        <NoProjectsPage />
                      </React.Suspense>
                    </Route>
                    <Redirect to='/projects' />
                  </>
                )}
              </Switch>
              <WhatsNew />
              <AntdGlobals />
            </Layout>
          </ConfigProvider>
        );

      } else {
        return (
          <ConfigProvider theme={lnTheme}>
            <GlobalStyle />
            <Layout style={{ minHeight: '100vh' }}>
              <LockedPage status='suspended' email={user.email} />
            </Layout>
          </ConfigProvider>
        );
      }

    } else {
      return (
        <ConfigProvider theme={lnTheme}>
          <GlobalStyle />
          <Layout style={{ minHeight: '100vh' }}>
            <LockedPage status={user.status} email={user.email} />
          </Layout>
        </ConfigProvider>
      );
    }
  } else {
    return <LoadingTakeover title='Adding parachutes...' />;
  }
};

export default App;
export { lnTokens, lnTheme };
