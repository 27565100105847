import React, { useState, useCallback } from "react";
import type { MockedAnnouncementProps } from "./";
import { Form, Select, Button, Modal, Space, Typography, Col } from "antd";
import { useUserContext } from '../../../AppContext';
import { useCreateUserProjectSubscription } from '@launchnotes/common-hooks/useCreateUserProjectSubscription';
import { useParams } from "react-router-dom";
import type { ParamsProps } from "./";
import { WizardPublicPagePreviewFragment } from '../../../generated/graphql';
const { Option } = Select;
const { Text, Paragraph } = Typography;

type Props = {
  project: WizardPublicPagePreviewFragment;
  mockedAnnouncement: MockedAnnouncementProps;
}

const PublicPagePreview = (props: Props) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const project: WizardPublicPagePreviewFragment = props.project;
  const user = useUserContext();
  const [isSubscribed, setIsSubscribed] = useState<boolean>(!!project.emailProjectSubscription);
  const { step } = useParams<ParamsProps>();
  const [createUserProjectSubscription] = useCreateUserProjectSubscription(project);

  const submitCategories = useCallback(({ categories }: { categories: string[] }) => {
    setModalVisible(false);
    createUserProjectSubscription(categories);
    setIsSubscribed(true);
  }, [setModalVisible, createUserProjectSubscription]);

  return (<>
    <Modal
      title="Subscribe to updates"
      closable={false}
      footer={null}
      open={modalVisible}
      onCancel={() => { setModalVisible(false) }}
    >
      <Form layout="vertical" onFinish={submitCategories}>
        <Text>Email</Text>
        <Paragraph>{user?.email}</Paragraph>
        <Form.Item
          name="categories"
          label="What categories do you want to hear about?"
          rules={[{ required: true, message: "Please select at least one category" }]}
        >
          <Select
            mode="multiple"
            placeholder="Select categories"
            showArrow
          >
            {
              project?.categories?.edges?.map((edge, index) => {
                if (!edge?.node) return <></>
                return <Option key={index} value={edge.node.id}>{edge.node.name}</Option>
              })
            }
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            block
            htmlType="submit"
            style={{
              color: "#fff",
              backgroundColor: project.primaryColor
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
    <Col className={`content-preview-container ${project.supportingPalette}`}>
      <Col className="url-bar-container">
        <Col className="dots-container">
          <Col className="dot"></Col>
          <Col className="dot middle"></Col>
          <Col className="dot"></Col>
        </Col>
        <Col className="url-container">
          https://{project.slug}.launchnotes.io
        </Col>
      </Col>
      <Col className="fp-header-container" style={{backgroundColor: project.primaryColor}}>
        <Col className="page-name-container">
          <Space direction="horizontal">
            { project.logo?.url ? (
              <img src={project.logo.url} alt="Project Logo" />
            ) : (
              project.name
            )}
          </Space>
        </Col>
        <Button
          className="subscribe-button"
          ghost
          disabled={(step !== "subscribers" || isSubscribed)}
          onClick={() => setModalVisible(true)}
        >
          { isSubscribed ? "Subscribed" : "Subscribe" }
        </Button>
      </Col>
      <Col className="fp-hero-container">
        <Col className="fp-hero-header">Product Updates</Col>
        <Paragraph>See the latest feature releases, product improvements, and bug fixes</Paragraph>
      </Col>
      <Col className="fp-body-container">
        <Col className="categories-container">
          {
            project?.categories?.edges?.map((edge, index) => {
              if (!edge?.node) return <></>
              return (
                <Col key={index} className="category-container">
                  {edge?.node?.name}
                </Col>
              );
            })
          }
        </Col>
        <Col className="announcements-container">
          <Col className="announcement-container mocked" id="mocked-announcement">
            <Col className="announcement-name">{props.mockedAnnouncement?.headline}</Col>
            <Col className="announcement-categories-container">
              <Col className="announcement-category-container">
                {props.mockedAnnouncement?.categoryName}
              </Col>
            </Col>
            <Text>
              {props.mockedAnnouncement?.content}
            </Text>
          </Col>
          <Col className="announcement-container wireframed">
            <Col className="wireframe-ann-title"></Col>
            <Col className="wireframe-ann-body-line"></Col>
            <Col className="wireframe-ann-body-line"></Col>
            <Col className="wireframe-ann-body-line pp-end width-75"></Col>
            <Col className="wireframe-ann-body-line"></Col>
            <Col className="wireframe-ann-body-line"></Col>
            <Col className="wireframe-ann-body-line pp-end width-25"></Col>
            <Col className="wireframe-ann-body-line"></Col>
            <Col className="wireframe-ann-body-line"></Col>
            <Col className="wireframe-ann-body-line pp-end width-50"></Col>
          </Col>
        </Col>
        <Col className="months-container"></Col>
      </Col>
    </Col>
  </>);
};

export default PublicPagePreview;
