import { message } from "antd";
import { useCallback } from "react";
import { useAuthenticatedFetch } from "@launchnotes/common-hooks/useAuthenticatedFetch";
import useProjectId from "@launchnotes/common-hooks/useProjectId";
import API_URL from '../../../../API_URL';
import {
  CreateAnnouncementAttributes,
  useWizardCreateTestAnnouncementMutation,
  useWizardQuery
} from '../../../../generated/graphql';
import { useInvalidateQuery } from '@launchnotes/common-hooks/useInvalidateQuery';

const useCreateTestAnnouncement = () => {
  const projectId = useProjectId();
  const { authenticatedFetch } = useAuthenticatedFetch();
  const [invalidateWizardQuery] = useInvalidateQuery(useWizardQuery.getKey({projectId}));
  const { mutate, isPending: isLoading } = useWizardCreateTestAnnouncementMutation({
    onSuccess: (data) => {
      const announcement = data?.createAnnouncement?.announcement;
      const errors = data?.createAnnouncement?.errors;
      if (announcement && !errors?.length) {
        publishTestAnnouncement(announcement.id);
        invalidateWizardQuery();
      }
    },
    onError: (error: any) => {
      message.error(`There was a problem creating an announcement - ${error}`);
    },
  });

  const publishTestAnnouncement = useCallback(async (announcementId: string) => {
    try {
      const url = `${API_URL}/projects/${projectId}/announcements/${announcementId}/publish_test`;
      const response = await authenticatedFetch(url, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
      } else {
        message.error("We couldn't create and send your test announcement. Please try reloading the page and trying again. " + response.statusText);
      }
    } catch (error) {
      message.error("We couldn't create and send your test announcement. Please try reloading the page and trying again. " + error);
    }
  }, [authenticatedFetch, projectId]);

  const createTestAnnouncement = useCallback((announcement: CreateAnnouncementAttributes) => {
    mutate({
      announcement: announcement,
    });
  }, [mutate]);

  return {
    createTestAnnouncement,
    isLoading,
  }
};

export default useCreateTestAnnouncement;
