import API_URL from '../API_URL';
import { useContext } from 'react';
import {TokenContext} from './TokenAuthenticated';
import { useFeatureFlags } from '../FeatureFlags';

export const useFetchData = <TData, TVariables>(
  query: string,
  _options?: RequestInit['headers'],
): ((variables?: TVariables) => Promise<TData>) => {

  const tokenContext = useContext(TokenContext);
  const url = `${API_URL}/graphql`;

  return async (variables?: TVariables) => {
    const token = tokenContext.token;

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-version': 'beta',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0] || 'Error..';
      throw new Error(message);
    }

    return json.data;
  };
};
