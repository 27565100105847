import React from 'react';
import { Form, Input, Modal } from 'antd';
import { ImportanceFeedbackSection } from '@launchnotes/components/Feedback/ImportanceFeedbackSection';
import { OrganizeFeedbackSection } from '@launchnotes/components/Feedback/OrganizeFeedbackSection';
import { feedbackLabel } from '@launchnotes/components/Feedback/utils';
import { FeedbackableSelect } from '@launchnotes/components/Select/FeedbackableSelect';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import { useFeedbackModalController } from '../hooks/useFeedbackModalController';
import { useCreateFeedbackModalQuery } from '../../../generated/graphql';
import { SmallSecondary } from '../../Layout';

const { TextArea } = Input;

type CreateFeedbackModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  defaultID?: string;
  defaultType?: string;
};

export const CreateFeedbackModal: React.FC<CreateFeedbackModalProps> = ({
  visible,
  setVisible,
  defaultID,
  defaultType,
}) => {
  const [loading, setLoading] = React.useState(false);
  const projectId = useProjectId();
  const [form] = Form.useForm();
  const feedbackablePreselected = !!defaultID && !!defaultType;

  const { data } = useCreateFeedbackModalQuery({ projectId });

  const announcements = (data?.project.announcements?.nodes || []).filter((node): node is NonNullable<typeof node> => !!node);
  const ideas = (data?.project.ideas?.nodes || []).filter((node): node is NonNullable<typeof node> => !!node);
  const workItems = (data?.project.workItems?.nodes || []).filter((node): node is NonNullable<typeof node> => !!node);

  const { state, stateFunctions, mutators } = useFeedbackModalController({
    announcements,
    ideas,
    workItems,
    form,
    data: null,
    setVisbility: setVisible,
    defaultID,
    defaultType,
  });
  const { importance, feedbackType, options } = state;
  const { updateImportance, updateFeedbackType, onSubmit } = stateFunctions;
  const { createFeedback } = mutators;

  return (<>
    <Modal
      title={'New feedback'}
      className="new-feedback-form"
      data-cy="create-feedback-modal"
      centered
      open={visible}
      okText={'Submit feedback'}
      onCancel={() => setVisible(false)}
      onOk={() => onSubmit()}
      okButtonProps={{ disabled: loading }}
      focusTriggerAfterClose={false}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={undefined}
        onFinish={(values) => createFeedback(values)}
      >
        <Form.Item
          label="Short summary of the feedback"
          name="content"
          rules={[
            {
              type: 'string',
              required: true,
              message: 'Please provide a summary of the feedback.',
            },
          ]}
        >
          <TextArea rows={4} autoFocus data-cy="create-feedback-modal-content"/>
        </Form.Item>

        <Form.Item
          label="Email address of person whose feedback it is"
          name="affectedCustomerEmail"
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Must be a valid email',
            },
          ]}
        >
          <Input data-cy="create-feedback-modal-affectedCustomerEmail" />
        </Form.Item>

        <Form.Item
          label="Notes"
          name="notes"
          extra={<SmallSecondary.Text>Add any context, links, etc. about this feedback.</SmallSecondary.Text>}
        >
          <TextArea rows={4} data-cy="create-feedback-modal-notes" />
        </Form.Item>

        <ImportanceFeedbackSection
          importance={importance ? importance : undefined}
          setImportance={updateImportance}
        />

        {!feedbackablePreselected &&
          <Form.Item label="Organize feedback (optional)">
            <OrganizeFeedbackSection
              feedbackableType={
                feedbackType ? (feedbackType as LN.Feedbackables) : undefined
              }
              setFeedbackableType={updateFeedbackType}
            />
          </Form.Item>
        }

        {!feedbackablePreselected && feedbackType && (
          <Form.Item
            className="feedback-modal-select"
            label={`Select ${feedbackLabel(feedbackType).label}`}
            name={'feedbackableId'}
            rules={[
              {
                type: 'string',
                required: true,
                message:
                  'Please select an idea, roadmap item, or announcement.',
              },
            ]}
          >
            <FeedbackableSelect
              type={feedbackType}
              options={options}
              loading={loading}
              setLoading={setLoading}
              onIdeaCreated={onSubmit}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  </>);
};
