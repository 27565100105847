import React, {FC, MouseEventHandler, ReactNode} from 'react';
import {
  Button,
  Card,
  Flex,
  Space,
  Typography
} from 'antd';
import { ButtonType } from 'antd/es/button';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon';
import { 
  faBarsFilter,
  faChartSimple,
} from '@fortawesome/pro-thin-svg-icons';
import { lnTokens } from '../../Router';

declare const IconSizes: readonly ["small", "large"];
export type IconSizesType = typeof IconSizes[number];

type EmptyStateProps = {
  style?: any
  title: string
  subTitle?: string
  buttonOverride?: ReactNode
  buttonText?: string
  buttonOnClick?: MouseEventHandler<HTMLElement>
  buttonHelpText?: ReactNode
  buttonType?: ButtonType
  infoText?: ReactNode
  wistiaVideoId?: string
  icon?: ReactNode
  iconSize?: IconSizesType
  className?: string
  wrapInCard?: boolean
}

type EmptyStateFilteredTableProps = {
  buttonText?: string
  buttonOnClick?: MouseEventHandler<HTMLElement>
  buttonType?: ButtonType
  wrapInCard?: boolean
}

const EmptyState: FC<EmptyStateProps> = ({
  style,
  title,
  subTitle,
  buttonOverride,
  buttonText,
  buttonOnClick,
  buttonHelpText,
  buttonType,
  wistiaVideoId,
  icon,
  iconSize = 'large',
  className,
  wrapInCard = false,
  ...props
}) => {

  const cardContents = (
    <Flex align='center' justify='center' style={{textAlign: 'center', paddingTop: '10px', paddingBottom: '10px'}} vertical>
        <span style={{fontSize: iconSize == 'small' ? '44px' : '64px', color: lnTokens.asteroid}}>{icon}</span>
        <Typography style={{maxWidth: '600px'}}>
          <Typography.Title level={2}>{title}</Typography.Title>
          {subTitle && <Typography.Paragraph>{subTitle}</Typography.Paragraph>}
          {buttonOverride && buttonOverride}
          {!buttonOverride && buttonText && buttonOnClick && (
            <Typography.Paragraph>
              <Button
                type={buttonType ? buttonType : 'primary'}
                onClick={buttonOnClick}
              >
                {buttonText}
              </Button>
            </Typography.Paragraph>
          )}
          {buttonHelpText && (
            <Typography.Paragraph type="secondary">{buttonHelpText}</Typography.Paragraph>
          )}
        </Typography>
      </Flex>
  );

  return wrapInCard ? (
    <Card className={`empty-state-takeover ${className}`} style={{width: '100%'}} {...props}>
      {cardContents}
    </Card>
  ) : cardContents;
};

export const EmptyStateFullPageWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Space>
      {children}
    </Space>
  );
};

export const EmptyStateTableWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Space>
      {children}
    </Space>
  );
};

export const EmptyStateBarChart = () => {
  return (
    <EmptyState
      title='Not enough data'
      icon={<FontAwesomeIcon icon={faChartSimple} />}
      iconSize='small'
      wrapInCard={false}
    />
  );
};

export const EmptyStateFilteredTable: FC<EmptyStateFilteredTableProps> = ({
  buttonText,
  buttonOnClick,
  buttonType,
  wrapInCard = false,
}) => {
  return (
    <EmptyState
      title='No matching results'
      subTitle="Try adjusting your filters to find what you're looking for."
      icon={<FontAwesomeIcon icon={faBarsFilter} />}
      iconSize='small'
      buttonOnClick={buttonOnClick}
      buttonText={buttonOnClick ? 'Clear all filters' : null}
      buttonType={buttonOnClick ? 'link' : null}
      wrapInCard={wrapInCard}
    />
  );
};

export default EmptyState;