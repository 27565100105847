import { useCallback } from 'react';
import { useContext } from 'react';
import { TokenContext } from '../Authenticated/TokenAuthenticated';

export const useAuthenticatedFetch = () => {
  const tokenContext = useContext(TokenContext);

  const authenticatedFetch: typeof fetch = useCallback(async (input, init?) => {
    const token = tokenContext.token;

    return fetch(input, {
      ...init,
      headers: {
        ...init?.headers,
        'Authorization': `Bearer ${token}`,
      },
    });
  }, [tokenContext.token]);

  return {
    authenticatedFetch,
  };
};
