import React from 'react';
import Icon from '@ant-design/icons';

const Svg = () => (
  <svg width="23" height="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1555_3115" style={{'maskType': 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="23">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H21.7624L23 0.977058V23H0V0ZM6.04308 4.58809C4.95322 3.74042 3.38253 3.93675 2.53485 5.02662C1.68718 6.11649 1.88351 7.68718 2.97338 8.53485L17.0266 19.4651C18.1165 20.3128 19.6872 20.1165 20.5349 19.0266C21.3825 17.9368 21.1862 16.3661 20.0963 15.5184L6.04308 4.58809Z" fill="black"/>
    </mask>
    <g mask="url(#mask0_1555_3115)">
      <path d="M18.4375 5.96875C16.7188 4.53125 14.1875 4.75 12.5938 6.375L12 7.03125L11.375 6.375C10.0938 5.0625 7.53125 4.28125 5.53125 5.96875C3.5625 7.65625 3.46875 10.6562 5.21875 12.4688L11.2812 18.7188C11.4688 18.9062 11.7188 19 11.9688 19C12.25 19 12.5 18.9062 12.6875 18.7188L18.75 12.4688C20.5 10.6562 20.4062 7.65625 18.4375 5.96875ZM18.0312 11.7812L12 18.0312L5.9375 11.7812C4.75 10.5312 4.5 8.1875 6.1875 6.75C7.90625 5.28125 9.90625 6.34375 10.6562 7.09375L12 8.46875L13.3125 7.09375C14.0312 6.34375 16.0625 5.28125 17.7812 6.75C19.4688 8.1875 19.2188 10.5312 18.0312 11.7812Z" fill="#1C1917"/>
    </g>
    <line x1="2.70165" y1="4.91229" x2="19.9123" y2="18.2984" stroke="black" strokeLinecap="round"/>
  </svg>
);

const unvote = () => <Icon component={Svg} />;

export default unvote;