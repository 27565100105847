import styled from 'styled-components';
import { Table } from 'antd';

export const BorderlessTable = styled(Table)`
table tr:nth-of-type(odd) td {
  border-bottom: none;
}

.ant-table-thead th.ant-table-cell, .ant-table-thead td.ant-table-cell {
  background-color: #FFF;
}

.ant-table-tbody tr.ant-table-row:hover td {
  background-color: white;
}

.ant-table-tbody .ant-table-expanded-row .ant-table-cell {
  background-color: white;
  padding: 0px 16px 16px;
}

.ant-table-tbody .email-with-domain {
  align-items: center;
  display: flex;
  gap: 8px;
}

.ant-table-column-sorters {
  float: left;
}

.ant-table-tbody .ant-table-row.ant-table-row-selected >.ant-table-cell {
  background-color: rgba(0, 0, 0, 0.01) !important;
}

.idea-link {
  text-decoration: underline;
}
`;
