import React, { FC } from 'react';
import { ConfigProvider, Typography, theme } from 'antd';

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties; // Allow passing custom style
}

export const Text: FC<Props> = ({ children, style }) => {
  const { token } = theme.useToken()
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: token.fontSizeSM
        }
      }}
    >
      <Typography.Text style={style} type="secondary">
        {children}
      </Typography.Text>
    </ConfigProvider>
  );
};

export const Paragraph: FC<Props> = ({ children, style }) => {
  const { token } = theme.useToken()
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: token.fontSizeSM
        }
      }}
    >
      <Typography.Paragraph style={style} type="secondary">
        {children}
      </Typography.Paragraph>
    </ConfigProvider>
  );
};

export const Link: FC<Props> = ({ children, style }) => {
  const { token } = theme.useToken()
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: token.fontSizeSM
        }
      }}
    >
      <Typography.Link style={style} type="secondary">
        {children}
      </Typography.Link>
    </ConfigProvider>
  );
};
