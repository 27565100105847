import React from 'react';
import { Form, Input, Modal, Typography } from 'antd';
import { useCreateProject } from './hooks/useCreateProject';

const { Link } = Typography;

export const NewPageModal: React.FC<{
  visible: boolean;
  setVisible: (visible: boolean) => void;
}> = (props) => {
  const { createProject } = useCreateProject();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const close = React.useCallback(() => {
    props.setVisible(false);
  }, [props]);

  const onSubmit = React.useCallback(
    ({ name }: { name: string }) => {
      setLoading(true);
      createProject(name)
        .then(() => {
          close();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [close, createProject ],
  );

  return (
    (<Modal
      title="Create a new LaunchNotes page"
      className="new-page-modal"
      open={props.visible}
      onCancel={close}
      onOk={() => form.submit()}
      okText="Create page"
      okButtonProps={{ loading }}
      destroyOnClose
    >
      <Typography.Paragraph>
        Unlock your ability to communicate with another audience. The page can
        be made public or kept private.{' '}
        <Link
          href="https://help.launchnotes.com/en/articles/5558593-private-pages"
          target="_blank"
          rel="noreferrer"
        >
          Learn more.
        </Link>
      </Typography.Paragraph>
      <Form layout="vertical" form={form} preserve={false} onFinish={onSubmit}>
        <Form.Item
          label="Page name"
          name="name"
          rules={[
            {
              type: 'string',
              required: true,
              message: 'Page name is required.',
            },
          ]}
        >
          <Input ref={(el) => setTimeout(() => el?.focus(), 0)} />
        </Form.Item>
      </Form>
    </Modal>)
  );
};
