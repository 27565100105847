import React from 'react';

// We only want to show a blank space if we are loading in
// a table, not an empty `No X` state.
// This is a similar to overwriting the empty
// state for tables, a function that just
// returns an element, only this is just an empty
// div. This will then show the spinner with the table
// columns and nothing else
const EmptySpinner = () => (
  <></>
);

export default EmptySpinner;