import { useCallback, useContext } from 'react';
import {TokenContext} from '../Authenticated/TokenAuthenticated';

export const useAuthenticatedUrl = () => {
  const tokenContext = useContext(TokenContext);

  const addTokenToUrl = useCallback((url: string, param = 'workosToken') => {
    const parsedUrl = new URL(url);
    const spaToken = tokenContext.token;
    parsedUrl.searchParams.set(param, spaToken);

    return parsedUrl.toString();
  }, [tokenContext.token]);

  return {
    addTokenToUrl,
  };
};
