import useUpdateUserContext from '@launchnotes/common-hooks/useUpdateUserContext';
import { message } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useOrganizationContext } from '../../../AppContext';
import { useCreateProjectMutation } from '../../../generated/graphql';

const toastSuccess = () => message.success('New page created');
const toastError = () => message.error('New page could not be created');

export const useCreateProject = () => {
  const organization = useOrganizationContext();

  const { updateContext } = useUpdateUserContext();
  const history = useHistory();
  const { mutate } = useCreateProjectMutation({
    onSuccess: (data) => {
      const { project } = data.createProject || {};
      if (project) {
        updateContext(project.id);
        history.push(`/projects/${project.id}/admin/customize-page`);
        toastSuccess();
      } else {
        toastError();
      }
    },
    onError: () => {
      toastError();
    },
  });

  const createProject = React.useCallback(
    async (name: string) => {
      await mutate({
        input: {
          name,
          organizationId: organization?.id || '',
        },
      });
    },
    [mutate, organization?.id],
  );

  return {
    createProject,
  };
};
