import { useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useOrganizeFeedbacksMutation } from '../../../generated/graphql';

export const useOrganizeFeedbacks = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (e?: Error) => void;
} = {}) => {
  const queryClient = useQueryClient();

  const { mutate } = useOrganizeFeedbacksMutation({
    onSuccess(_, { feedbackIds }) {
      queryClient.invalidateQueries({
        queryKey: ['FeedbackTable']
      });
      Array.isArray(feedbackIds) && feedbackIds.length > 1
        ? message.success('Feedbacks organized')
        : message.success('Feedback organized');
      onSuccess?.();
    },
    onError(e: Error | undefined, { feedbackIds }) {
      Array.isArray(feedbackIds) && feedbackIds.length > 1
        ? message.error(`Feedbacks could not be organized ${e?.message}`)
        : message.error(`Feedback could not be organized ${e?.message}`);
      onError?.(e);
    },
  });

  return { organizeFeedbacks: mutate };
};
