import React from 'react';
import { Button, Card, Row, Tooltip, theme } from 'antd';
import { useFeatureFlags, FEEDBACK } from '../../../FeatureFlags';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faLightbulb,
  faFlask,
  faMegaphone,
} from '@fortawesome/pro-regular-svg-icons'

const { useToken } = theme;

type ImportanceFeedbackProps = {
  preventDeslect?: boolean;
  feedbackableType?: LN.Feedbackables | null;
  setFeedbackableType: (feedback: LN.Feedbackables | undefined) => void;
};

type OrganizeAppearance = {
  color: string;
  background: string;
  selected: boolean;
};

export const OrganizeFeedbackSection = (props: ImportanceFeedbackProps) => {
  const { setFeedbackableType, feedbackableType, preventDeslect } = props;
  const { Meta } = Card;
  const { features } = useFeatureFlags([FEEDBACK]);
  const ideasEnabled = features[FEEDBACK];
  const { token } = useToken();

  return (
    <Row justify='space-between' className='importance'>

        {ideasEnabled ? (
          <Button
            icon={<FontAwesomeIcon icon={faLightbulb} />}
            disabled={feedbackableType && feedbackableType !== 'Idea'}
            style={{ width: '30%'}}
            className='importance-card'
            onClick={() => {
              // If already set to the specified type, the user is de-selecting
              if (feedbackableType === 'Idea' && !preventDeslect) {
                setFeedbackableType(undefined);
              } else {
                setFeedbackableType('Idea');
              }
            }}
          >
            Idea
          </Button>
        ) : (
          <Button
            className='importance-card disabled'
            icon={<FontAwesomeIcon icon={faLightbulb} />}
            disabled
          >
            <Tooltip title={'Ideas not enabled'}>Idea</Tooltip>
          </Button>
        )}
        <Button
          icon={<FontAwesomeIcon icon={faFlask} className='organize-fb-icon' />}
          className='importance-card'
          disabled={feedbackableType && feedbackableType !== 'WorkItem'}
          style={{ width: '30%'}}
          onClick={() => {
            // If already set to the specified type, the user is de-selecting
            if (feedbackableType === 'WorkItem' && !preventDeslect) {
              setFeedbackableType(undefined);
            } else {
              setFeedbackableType('WorkItem');
            }
          }}
        >
          Roadmap Item
        </Button>
        <Button
          icon={<FontAwesomeIcon icon={faMegaphone} className='organize-fb-icon' />}
          style={{ width: '33%'}}
          disabled={feedbackableType && feedbackableType !== 'Announcement'}
          className='importance-card'
          onClick={() => {
            // If already set to the specified type, the user is de-selecting
            if (feedbackableType === 'Announcement' && !preventDeslect) {
              setFeedbackableType(undefined);
            } else {
              setFeedbackableType('Announcement');
            }
          }}
        >
          Announcement
        </Button>

    </Row>
  );
};
