import React from 'react';
import {ConfigProvider, Row, Space, Table} from 'antd';
import {Maybe, PageInfo} from '../../generated/graphql';
import Pagination from './Pagination';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult, TableCurrentDataSource, GetRowKey } from 'antd/lib/table/interface';
import EmptySpinner from './EmptySpinner';

type MinimalTableProps<T = unknown> = {
  isLoading?: boolean
  data: T[]
  columns: ColumnsType<T>
  pageInfo: PageInfo | undefined
  first: number
  totalCount?: number
  nextPage: (cursor: Maybe<string> | undefined) => void
  previousPage: (cursor: Maybe<string> | undefined) => void
  updatePageSize: (size: number) => void
  tableProps?: React.PropsWithChildren
  emptyState?: () => JSX.Element
  bordered?: boolean
  rowKey?: string | GetRowKey<T>
  onChange?: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<object> | SorterResult<object>[], extra: TableCurrentDataSource<object>) => void
}

// eslint-disable-next-line react/display-name
export const MinimalTable = <T,>(): React.FC<MinimalTableProps<T>> => ({
  isLoading,
  data,
  columns,
  first,
  pageInfo,
  totalCount,
  nextPage,
  previousPage,
  updatePageSize,
  emptyState,
  rowKey,
  bordered,
  onChange,
}: MinimalTableProps<T>) => {
  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <ConfigProvider renderEmpty={isLoading ? EmptySpinner : emptyState} >
        <Table
          loading={isLoading}
          size="small"
          dataSource={data}
          rowKey={rowKey || 'id'}
          columns={columns}
          pagination={false}
          bordered={bordered}
          onChange={onChange}
        />
      </ConfigProvider>

      <Row justify="end">
        <Pagination
          pageInfo={pageInfo}
          pageSize={first}
          totalCount={totalCount || 0}
          onNext={() => {
            nextPage(pageInfo?.endCursor);
          }}
          onPrevious={() => {
            previousPage(pageInfo?.startCursor);
          }}
          onUpdatePageSize={updatePageSize}
        />
      </Row>
    </Space>
  );
};