import React, { FC, useState, useMemo } from 'react';
import { Feedback } from '../../../generated/graphql';
import {
  Button,
  Card,
  Space,
  Typography,
} from 'antd';
import { HasEvent, HasNotification, Toggleable} from './types';
import { format, parseISO } from 'date-fns';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faArrowTurnDownRight,
  faChevronDown,
  faChevronUp
} from '@fortawesome/pro-regular-svg-icons'

const { Paragraph } = Typography;

const CollapsibleText: FC<{ text: string, styles?: object}> = ({ text, styles }) => {
  const [showFullText, toggleShowFullText] = useState(false);

  const validLength = text.length < 230;

  const displayedText = useMemo(() => {
    if (showFullText || validLength) {
      return text;
    }

    return text.slice(0, 230) + '...';
  }, [showFullText, validLength, text]);

  return (
    <Space>
      <Typography.Text style={styles}>
        {displayedText}
      </Typography.Text>
      { !validLength && (
        <Space>
          <Button type="link" onClick={() => toggleShowFullText(!showFullText)}>
            { showFullText ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
            { showFullText ? 'Less' : 'More'}
          </Button>
        </Space>
      )
      }
    </Space>
  );
};

const EventRowBody: FC<HasEvent & HasNotification & Toggleable> = ({ event, notification, toggleState }) => {
  if (event.eventType === 'announcement.published' && notification) {
    if (!toggleState) {
      return <></>;
    }

    if (!notification.openedAt &&
      !notification.clickedThroughAt) {
      return <></>;
    }

    return (
      <Card>
        { notification.openedAt && (
          <Typography.Paragraph>
            <Typography.Text strong>Opened</Typography.Text> - {format(parseISO(notification.openedAt), 'MMMM d, yyyy')}
          </Typography.Paragraph>
        )}

        { notification.clickedThroughAt && (
          <Typography.Paragraph>
            <Typography.Text strong>Clicked</Typography.Text> - {format(parseISO(notification.clickedThroughAt), 'MMMM d, yyyy')}
          </Typography.Paragraph>
        )}
      </Card>
    );
  }

  if (['idea.unvoted', 'idea.voted'].includes(event.eventType)) {
    return <></>;
  }

  if (event.eventObject.__typename !== 'Feedback') {
    return <></>;
  }

  const feedback = event.eventObject as Feedback;

  return (
    <Card style={{marginTop: 8, marginBottom: 8}}>
      <CollapsibleText text={feedback.content} />
      { feedback.reporter && (
          <Paragraph strong style={{marginBottom: 0}}>
            <FontAwesomeIcon icon={faArrowTurnDownRight} />
            &nbsp;
            Recorded by {feedback.reporter.name}
          </Paragraph>
      )}
      {
        feedback.notes &&
        <CollapsibleText text={feedback.notes} />
      }
    </Card>
  );
};

export default EventRowBody;
