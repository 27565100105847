import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useSearchParams<T extends { [key: string]: string | boolean }>() {
  const history = useHistory();
  const location = useLocation();

  const updateSearchParams = React.useCallback((changes: Partial<T>) => {
    const urlSearchParams = new URLSearchParams(location.search)
    Object.entries(changes).forEach(([key, value]) => {
      if (value || value === false) {
        if (typeof value === 'boolean') {
          urlSearchParams.set(key, JSON.stringify(value));
        } else {
          urlSearchParams.set(key, (value as Object).toString());
        }
      } else {
        urlSearchParams.delete(key);
      }
    })
    history.replace({ ...location, search: urlSearchParams.toString() });
  }, [history, location]);

  const searchParams = React.useMemo(() => {
    const searchParams: Partial<T> = {};
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.forEach((value, key) => {
      if (value === 'true' || value === 'false') {
        searchParams[key as keyof T] = JSON.parse(value);
      } else {
        searchParams[key as keyof T] = value as any;
      }
    });
    return searchParams;
  }, [location.search]);

  return {
    searchParams,
    updateSearchParams,
  }
};
