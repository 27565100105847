import React from 'react';
import { ConfigProvider, Empty, Select, Typography } from 'antd';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import { feedbackLabel } from '@launchnotes/components/Feedback/utils';
import { useCreateIdea } from '@launchnotes/components/Ideas/hooks/useCreateIdea';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faLightbulb,
} from '@fortawesome/pro-regular-svg-icons'
const { Text } = Typography;

export type FeedbackableSelectProps = {
  type: string | null;
  options: { id: string, name: string }[] | undefined;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
  value?: string | undefined;
  onChange?: (value: string | undefined) => void;
  onIdeaCreated?: () => void;
};

export const FeedbackableSelect: React.FC<FeedbackableSelectProps> = (props) => {
  const projectId = useProjectId();

  const { createIdea } = useCreateIdea({
    onSuccess(idea) {
      props.setLoading?.(false);
      props.onChange?.(idea?.id);
      props.onIdeaCreated?.();
    },
  });

  const [searchValue, setSearchValue] = React.useState('');
  const optionsByName = React.useMemo(() =>
    (props.options || [])
      .reduce<{ [name: string]: { id: string, name: string } }>((o, opt) => {
        o[opt.name.toLowerCase().trim()] = opt;
        return o;
      }, {}),
  [props.options],
  );

  const onChange = (value: string) => {
    if (value === 'new-idea') {
      props.setLoading?.(true);
      createIdea({
        input: {
          projectId,
          name: searchValue,
        },
      });
      props.onChange?.(`Creating: ${searchValue}...`);
    } else {
      props.onChange?.(value);
    }
  };

  return (
    <ConfigProvider renderEmpty={() => (
      <Empty
        className="ant-empty-small"
        image={<FontAwesomeIcon icon={faLightbulb} />}
        description={props.type === 'Idea' ? 'Type to create an idea' : undefined }
      />
    )}>
      <Select
        allowClear
        showArrow
        showSearch
        loading={props.loading}
        value={props.value}
        onChange={onChange}
        disabled={props.loading || (props.type !== 'Idea' && props.options?.length === 0)}
        searchValue={searchValue}
        onSearch={(value) => setSearchValue(value)}
        placeholder={ props.options?.length === 0
          ? feedbackLabel(props.type).emptyState
          : feedbackLabel(props.type).placeholder }
        filterOption={(input, option) =>
          (option?.title || '')
            .toLowerCase()
            .trim()
            .indexOf(input.toLowerCase().trim()) >= 0
        }
      >
        {(props.options || []).map(({ id, name }) => (
          <Select.Option key={id} title={name} value={id}>
            {feedbackLabel(props.type).icon}
            <Text> {name}</Text>
          </Select.Option>
        ))}
        {props.type === 'Idea' && searchValue && !optionsByName[searchValue.toLowerCase().trim()] && (
          <Select.Option key="new-idea" title={searchValue} value="new-idea">
            <Text>Create: {searchValue}</Text>
          </Select.Option>
        )}
      </Select>
    </ConfigProvider>
  );
};
