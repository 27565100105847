import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { message, Typography } from 'antd';
import {
  ArchiveFeedbacksMutationVariables,
  useArchiveFeedbacksMutation,
} from '../../../generated/graphql';
import { modal } from '../../../utils/antGlobals';

export const useArchiveFeedbacks = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (e?: Error) => void;
} = {}) => {
  const queryClient = useQueryClient();

  const { mutate } = useArchiveFeedbacksMutation({
    onSuccess(_, { feedbackIds }) {
      queryClient.invalidateQueries({
        queryKey: ['FeedbackTable']
      });
      Array.isArray(feedbackIds) && feedbackIds.length > 1
        ? message.success('Feedbacks archived')
        : message.success('Feedback archived');
      onSuccess?.();
    },
    onError(e: Error | undefined, { feedbackIds }) {
      Array.isArray(feedbackIds) && feedbackIds.length > 1
        ? message.error(`Feedbacks could not be archived ${e?.message}`)
        : message.error(`Feedback could not be archived ${e?.message}`);
      onError?.(e);
    },
  });

  const confirm = useCallback(
    (variables: ArchiveFeedbacksMutationVariables) => {
      const { feedbackIds } = variables;
      modal.confirm({
        icon: null,
        content: (
          <Typography>
            {Array.isArray(feedbackIds) && feedbackIds.length > 1
              ? 'Are you sure you want to archive these feedbacks?'
              : 'Are you sure you want to archive this feedback?'}
          </Typography>
        ),
        onOk: (close) => {
          mutate(variables);
          close();
        },
      });
    },
    [mutate],
  );

  return { archiveFeedbacks: confirm };
};
