import React from 'react';
import { Button, Card, Form, Row, Typography, theme } from 'antd';
import LowImportance from '@launchnotes/icons/LowImportance';
import MediumImportance from '@launchnotes/icons/MediumImportance';
import HighImportance from '@launchnotes/icons/HighImportance';
import { Importance } from '../../../generated/graphql';

const { Text } = Typography;
const { useToken } = theme;

type ImportanceFeedbackProps = {
  importance?: Importance | null;
  setImportance: (importance: Importance) => void;
};

type ImportanceAppearance = {
  color: string;
  background: string;
  selected: boolean;
};

export const ImportanceFeedbackSection = (props: ImportanceFeedbackProps) => {
  const { setImportance, importance } = props;
  const { Meta } = Card;
  const { token } = useToken();

  /**
   * Ideally we would just use the default style options provided by ant design.
   * Beacause those don't match the designs, we have this instead which
   * essentially sets up styled buttons. This upside of this is that we get
   * all of the transitions and other props from button for free here.
   */

  // Selected styles are always the same
  const selectedStyle = {
    selected: true,
  };

  // Non selected styles share many similar properties
  const defaultStyle = {
    selected: false,
    background: 'transparent',
  };

  const lowStyles: ImportanceAppearance =
    importance === 'low'
      ? {
          color: token.colorSuccess,
          background: token.colorSuccessBgHover,
          ...selectedStyle,
        }
      : {
          color: token.colorSuccess,
          ...defaultStyle,
        };
  const mediumStyles: ImportanceAppearance =
    importance === 'medium'
      ? {
          color: token.colorWarning,
          background: token.colorWarningBgHover,
          ...selectedStyle,
        }
      : {
          color: token.colorWarning,
          ...defaultStyle,
        };
  const highStyles: ImportanceAppearance =
    importance === 'high'
      ? {
          color: token.colorError,
          background: token.colorErrorBgHover,
          ...selectedStyle,
        }
      : {
          color: token.colorError,
          ...defaultStyle,
        };

  return (
    <>
      <Form.Item label='How important is this feedback? (optional)'>
        <Row justify={'space-between'}>
          <Button
            className='importance-card'
            onClick={() => {
              setImportance(Importance.Low);
            }}
            icon={<LowImportance className='low-importance' />}
            /* Style lock: Computed background, cannot override */
            style={{ background: lowStyles.background, width: '30%' }}
          >
            <Text>Nice to have</Text>
          </Button>
          <Button
            /* Style lock: Computed background, cannot override */
            style={{ background: mediumStyles.background, width: '30%' }}
            className='importance-card'
            onClick={() => setImportance(Importance.Medium)}
            icon={<MediumImportance className='medium-importance' />}
          >
            <Text>Somewhat</Text>
          </Button>
          <Button
            /* Style lock: Computed background, cannot override */
            style={{ background: highStyles.background, width: '30%' }}
            className='importance-card'
            onClick={() => setImportance(Importance.High)}
            icon={<HighImportance className='high-importance' />}
          >
            <Text>Extremely</Text>
          </Button>
        </Row>
      </Form.Item>
    </>
  );
};
