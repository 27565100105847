import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { environment } from '../../../index';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import PublicPagePreview from './PublicPagePreview';
import InfoStep from './InfoStep';
import CustomizeStep from './CustomizeStep';
import CategoriesStep from './CategoriesStep';
import SubscribersStep from './SubscribersStep';
import AnnouncementStep from './AnnouncementStep';
import LoadingContent from '@launchnotes/components/Loading/Content';
import { Space, Row, Col } from 'antd';
import { CategoryAttributes, useWizardQuery, WizardQuery } from '../../../generated/graphql';

export type ParamsProps = {
  step: string;
}

export type MockedAnnouncementProps = {
  headline: string;
  content: string;
  categoryName: string;
  categories: CategoryAttributes[];
}

export type DefaultCategoryProps = {
  name: string;
  displayName: string;
  mockedAnnouncement: MockedAnnouncementProps;
}

const OnboardingWizard = () => {
  const projectId = useProjectId();
  const { data } = useWizardQuery<WizardQuery>({ projectId });
  const { step } = useParams<ParamsProps>();
  const project = data?.project;

  const defaultCategories = useMemo<DefaultCategoryProps[]>(() => {
    return ([
      {
        name: 'Admin dashboard',
        displayName: 'Admin dashboard',
        mockedAnnouncement: {
          headline: 'Improved navigation structure in the Admin Dashboard',
          content: 'We\'ve moved a few things around in order to improve the usability of the Admin Dashboard. Now you can find more things in the places you\'d expect them to be.',
          categoryName: 'Admin dashboard',
          categories: [],
        },
      },
      {
        name: 'Mobile app',
        displayName: 'Mobile application',
        mockedAnnouncement: {
          headline: 'iOS Support for the new Apple Hoverboard',
          content: 'You can sync our official iOS app with the new Apple Hoverboard. This will allow you to import all your stats, tricks, and movement data directly from your phone.',
          categoryName: 'Mobile app',
          categories: [],
        },
      },
      {
        name: 'Integrations',
        displayName: 'Integrations with other tools',
        mockedAnnouncement: {
          headline: 'Three new Integrations launching today',
          content: 'Our ecosystem team has been hard at work on several new integrations with your favorite chat apps — they are available to all plans as of today.',
          categoryName: 'Integrations',
          categories: [],
        },
      },
      {
        name: 'Developer API',
        displayName: 'Developer API',
        mockedAnnouncement: {
          headline: 'New endpoints available in the Developer API',
          content: 'We\'ve released a handful of new endpoints to the Developer API. All of the changes are backwards compatible so none of your existing calls will break.',
          categoryName: 'Developer API',
          categories: [],
        },
      },
      {
        name: 'Embed widget',
        displayName: 'Embeddable widget',
        mockedAnnouncement: {
          headline: 'New customization options for the Widget',
          content: 'You can now customize the colors, fonts, introductory text and various other aspects of the embeddable widget.',
          categoryName: 'Embed widget',
          categories: [],
        },
      },
    ]);
  }, []);

  const emailSubscription = useMemo(() => {
    if (!project) return;
    return project.emailProjectSubscription;
  }, [project]);

  const mockedAnnouncement = useMemo<MockedAnnouncementProps>(() => {
    if (emailSubscription && emailSubscription.categories.length > 0) {
      const categoryName = emailSubscription.categories[0].name;
      const categoryId = emailSubscription.categories[0].id;
      const defaultCategory = defaultCategories.find((category: DefaultCategoryProps) => {
        return category.name === categoryName;
      });
      const announcement = defaultCategory ? defaultCategory.mockedAnnouncement : defaultCategories[0].mockedAnnouncement;
      return {
        ...announcement,
        categories: [{ id: categoryId }],
      };
    } else {
      return defaultCategories[0].mockedAnnouncement;
    }
  }, [defaultCategories, emailSubscription]);

  if (!data?.project) {
    return <LoadingContent />;
  }

  return (
    <Row className="layout-onboarding-wizard-wrapper">
      <Col flex="425px" className="layout-onboarding-wizard-sider">
        <Space direction="vertical">
          { step === 'info' && <InfoStep project={data.project} /> }
          { step === 'customize' && <CustomizeStep project={data.project} /> }
          { step === 'categories' && <CategoriesStep project={data.project} defaultCategories={defaultCategories} /> }
          { step === 'subscribers' && <SubscribersStep project={data.project} /> }
          { step === 'announcement' && <AnnouncementStep project={data.project} mockedAnnouncement={mockedAnnouncement} /> }
        </Space>
      </Col>
      <Col flex="auto" className="layout-onboarding-wizard-content">
        <PublicPagePreview project={data.project} mockedAnnouncement={mockedAnnouncement} />
      </Col>
    </Row>
  );
};

export default OnboardingWizard;
