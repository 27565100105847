import { useCallback } from "react";
import { message } from "antd";
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import useUpdateUserContext from '@launchnotes/common-hooks/useUpdateUserContext';
import { useWizardCreateCategoryMutation, useWizardQuery } from '../../../../generated/graphql';
import { useInvalidateQuery } from '@launchnotes/common-hooks/useInvalidateQuery';

export const useCreateCategory = () => {
  const projectId = useProjectId();
  const {updateContext} = useUpdateUserContext();
  const [invalidate] = useInvalidateQuery(useWizardQuery.getKey({projectId}));
  const { mutate } = useWizardCreateCategoryMutation({
    onSuccess: () => {
      updateContext(projectId);
      invalidate();
    },
    onError: () => { message.error("Could not create the category") }
  });

  const onCreate = useCallback((values: any) => {
    mutate({
      category: {...values, ...{projectId}},
    });
  }, [mutate, projectId]);

  return [onCreate];
};
