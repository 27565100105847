/* eslint-disable camelcase */
import { useContext } from 'react';
import { useTreatments, SplitContext } from '@splitsoftware/splitio-react';
import { useProjectContext } from './AppContext';
import SplitIO from '@splitsoftware/splitio-react/types/splitio/splitio';

export const ADVANCED_CHARTS                = 'advanced_charts';
export const AI_ASSISTANT                   = 'ai_assistant';
export const AUDIT_LOG                      = 'audit_log';
export const BOUNCER                        = 'bouncer';
export const BRANDED_EMAILS                 = 'branded_emails';
export const CUSTOM_ATTRIBUTES              = 'custom_attributes';
export const CUSTOM_CSS                     = 'custom_css';
export const CUSTOM_LAYOUTS                 = 'custom_layouts';
export const CUSTOM_PRIVACY_AND_TERMS       = 'custom_privacy_and_terms';
export const DIGESTS                        = 'digests';
export const FEEDBACK                       = 'feedback';
export const HUBSPOT_APP_ROLLOUT            = 'hubspot_app_rollout'
export const INTEGRATIONS_V2                = 'integrations_v2';
export const MANAGEMENT_API                 = 'management_api';
export const OPENAI_ANNOUNCEMENT_CONTENT    = 'openai_announcement_content';
export const RECIPIENTS_MODAL               = 'recipients_modal';
export const ROADMAP_DISCLAIMER             = 'roadmap_disclaimer';
export const SELF_SERVE_BILLING             = 'self_serve_billing';
export const SELF_SERVE_GROWTH              = 'self_serve_growth';
export const SELF_SERVE_STARTER             = 'self_serve_starter';
export const SHAREABLE_VIEWS                = 'shareable_views';
export const SLACK_NOTIFIER                 = 'slack_notifier';
export const SAML_MANAGE_PORTAL             = 'saml_manage_portal';

// Rollout flags.  These shouldn't hang around for very long
export const AI_ASSISTANT_ROLLOUT           = 'ai_assistant_rollout';
export const BASIC_ENRICHMENT               = 'basic_enrichment'; // Only on for our Organization
export const DOMAIN_DRAWER_ACCESS           = 'domain_drawer_access'; // Billing tier flag
export const PUBLISHER_ROLE                 = 'publisher_role';
export const CONTRIBUTOR_ROLE               = 'contributor_role';
export const COHORTS_ROLLOUT                = 'cohorts_rollout';
export const PUBLIC_STAGE_DESC              = 'public_stage_desc';
export const LOOM_ANNOUNCEMENT              = 'loom_announcement';
export const FEATURE_NOTIFY_ZAPIER          = 'feature_notify_zapier';
export const CHANGE_TYPE_ROLLOUT            = 'change_type_rollout';
export const COPY_TO_PROJECT_ROLLOUT        = 'copy_to_project_rollout';

/**
 * const { isReady, features } = useFeatureFlags([BRANDED_EMAILS]);
 *
 * if (features[BRANDED_EMAILS]) {
 *  // Your feature is enabled. Do something cool.
 * } else {
 *  // The feature is not enabled
 * }
 */
export const useFeatureFlags = (flags: string[], attributes?: SplitIO.Attributes) => {
  const { isReady } = useContext(SplitContext);
  const project = useProjectContext();

  const mergedAttributes = {
    ...attributes,
    organization: project?.organizationId,
    project: project?.id,
    trial: project?.billingStatus === 'trial',
    active_tier: project?.activeTier || '',
    createdAt: project?.createdAt,
  };

  const treatments = useTreatments(flags, mergedAttributes);
  const features: { [featureName: string]: boolean } = {};

  for (const treatment in treatments) {
    if (treatments[treatment]) {
      features[treatment] = treatments[treatment].treatment === 'on';
    }
  }

  return { isReady, features };
};
