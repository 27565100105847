import { FormInstance } from 'antd';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useCreateFeedback } from './useCreateFeedback';
import { useOrganizeFeedbacks } from './useOrganizeFeedbacks';
import { useUpdateFeedback } from './useUpdateFeedback';
import { FeedbackTableFeedback } from '../FeedbackTable';
import {
  CreateFeedbackMutationVariables,
  Importance,
  OrganizeFeedbackModalQuery,
  UpdateFeedbackMutationVariables,
} from '../../../generated/graphql';
import useProjectId from '@launchnotes/common-hooks/useProjectId';

type ControllerProps = {
  state: State;
  stateFunctions: StateFunctions;
  mutators: Mutators;
};

type State = {
  importance: Importance | null | undefined;
  feedbackType: string | undefined | null;
  options: { id: string, name: string }[] | undefined;
};

type Mutators = {
  updateFeedback: (values: UpdateFeedbackMutationVariables['feedback']) => void;
  createFeedback: (values: CreateFeedbackMutationVariables['feedback']) => void;
  organizeFeedback: (
    feedbacks: FeedbackTableFeedback[],
    feedbackableId: string,
    feedbackableType: string
  ) => void;
};

type StateFunctions = {
  updateImportance: Dispatch<SetStateAction<Importance | null | undefined>>;
  updateFeedbackType: Dispatch<SetStateAction<string | null | undefined>>;
  onSubmit: () => void;
};

type InputProps = {
  feedbackId?: string;
  announcements?: { id: string, name: string }[];
  ideas?: { id: string, name: string }[];
  workItems?: { id: string, name: string }[];
  form: FormInstance;
  data?: OrganizeFeedbackModalQuery | null;
  setVisbility: (visible: boolean) => void;
  defaultID?: string;
  defaultType?: string;
  successCallback?: () => void;
};

export function useFeedbackModalController(input: InputProps): ControllerProps {
  const projectId = useProjectId();
  const data = input?.data;
  const initialFeedbackableId = data?.feedback?.feedbackable?.id;
  const initialFeedbackableType = data?.feedback?.feedbackable?.__typename;

  const [importance, setImportance] = useState<Importance | null | undefined>(
    data?.feedback?.importance,
  );

  const [feedbackType, setFeedbackType] = useState<string | undefined | null>(
    input?.defaultType || data?.feedback?.feedbackable?.__typename,
  );

  const options = useMemo(() => {
    if (feedbackType === 'Announcement') {
      return input.announcements;
    } else if (feedbackType === 'Idea') {
      return input.ideas;
    } else if (feedbackType === 'WorkItem') {
      return input.workItems;
    } else {
      return [];
    }
  }, [feedbackType, input.announcements, input.ideas, input.workItems]);

  const { updateFeedback } = useUpdateFeedback({
    onSuccess: input.successCallback
  });

  const { createFeedback } = useCreateFeedback();
  const { organizeFeedbacks } = useOrganizeFeedbacks({
    onSuccess: input.successCallback
  });

  const update = (values: UpdateFeedbackMutationVariables['feedback']) => {
    if (data?.feedback?.id) {
      updateFeedback({
        feedback: {
          importance: importance ? importance : undefined,
          feedbackableType: feedbackType ? feedbackType : undefined,
          feedbackableId: values.feedbackableId,
          ...values,
          id: data.feedback.id,
        },
      });
    }
    input.form.resetFields();
  };

  const create = (values: Omit<CreateFeedbackMutationVariables['feedback'], 'projectId'>) => {
    createFeedback({
      feedback: {
        projectId,
        importance: importance ? importance : undefined,
        feedbackableType: feedbackType ? feedbackType : undefined,
        feedbackableId: input?.defaultID || values.feedbackableId,
        origin: 'management feedback modal',
        ...values,
      },
    });
    input.form.resetFields();
  };

  const organize = (
    feedbacks: FeedbackTableFeedback[],
    feedbackableId: string,
    feedbackableType: string,
  ) => {
    organizeFeedbacks({
      feedbackIds: feedbacks.map(({ id }) => id),
      feedbackableId,
      feedbackableType,
    });
  };

  const submit = () => {
    input.form.validateFields().then(() => {
      input.form.submit();
      input.setVisbility(false);
    });
  };

  useEffect(() => {
    setFeedbackType(feedbackType);
  }, [feedbackType, setFeedbackType]);

  useEffect(() => {
    setImportance(importance);
  }, [setImportance, importance]);

  useEffect(() => {
    const currentFieldValue = input.form.getFieldValue('feedbackableId');
    if (
      currentFieldValue !== initialFeedbackableId ||
      initialFeedbackableType !== feedbackType
    ) {
      input.form.setFieldsValue({ feedbackableId: null });
    }
  }, [
    feedbackType,
    setFeedbackType,
    input.form,
    initialFeedbackableId,
    initialFeedbackableType,
  ]);

  return {
    state: {
      importance: importance,
      feedbackType: feedbackType,
      options: options,
    },
    stateFunctions: {
      updateImportance: setImportance,
      updateFeedbackType: setFeedbackType,
      onSubmit: submit,
    },
    mutators: {
      updateFeedback: update,
      createFeedback: create,
      organizeFeedback: organize,
    },
  };
}
