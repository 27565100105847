import React, { useMemo, useCallback } from 'react';
import {
  Form,
  Button,
  Radio,
  Typography,
  Space,
  Row,
  Col,
} from 'antd';
import UploadField from '@launchnotes/components/Form/UploadField';
import { useUpdateProject, useRemoveLogo } from './hooks/UpdateOnboardingProject';
import { Link } from 'react-router-dom';
import ColorInput from '@launchnotes/components/Form/ColorField';
import { WizardCustomizeStepFragment } from '../../../generated/graphql';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faUpload,
} from '@fortawesome/pro-regular-svg-icons'

const { Text, Title, Paragraph } = Typography;

export type CustomizeUpdateProps = {
  logo?: string;
  slug?: string;
};

type Props = {
  project: WizardCustomizeStepFragment;
};

type PaletteOption = {
  name: string;
  colors: {
    primaryTextColor: string;
    secondaryTextColor: string;
    grayColor: string;
    lightGrayColor: string;
    offWhiteColor: string;
    whiteColor: string;
  }
}

const CustomizeStep = (props: Props) => {
  const [form] = Form.useForm();
  const project: WizardCustomizeStepFragment = props.project;
  const { updateProject } = useUpdateProject(project);
  const [removeLogo] = useRemoveLogo(project);
  const previousUrl = useMemo(() => `/projects/${project.id}/welcome/info`, [project]);
  const nextUrl = useMemo(() => `/projects/${project.id}/welcome/categories`, [project]);

  const selectedPalette = useCallback((newSupportingPalette: string): PaletteOption | undefined => {
    const colorPaletteOptions = [
      {
        name: 'warm',
        colors: {
          primaryTextColor: '#3F3D3B',
          secondaryTextColor: '#9B9997',
          grayColor: '#D8D5D4',
          lightGrayColor: '#EFEBE8',
          offWhiteColor: '#F5F3F1',
          whiteColor: '#FFFFFF',
        },
      },
      {
        name: 'neutral',
        colors: {
          primaryTextColor: '#3D3D3D',
          secondaryTextColor: '#999999',
          grayColor: '#D5D5D5',
          lightGrayColor: '#EBEBEB',
          offWhiteColor: '#F3F3F3',
          whiteColor: '#FFFFFF',
        },
      },
      {
        name: 'cool',
        colors: {
          primaryTextColor: '#3B3D3F',
          secondaryTextColor: '#97999B',
          grayColor: '#D4D5D8',
          lightGrayColor: '#E8EBEF',
          offWhiteColor: '#F1F3F5',
          whiteColor: '#FFFFFF',
        },
      },
    ];

    const paletteName = newSupportingPalette ? newSupportingPalette : project.supportingPalette;
    const selectedOption = colorPaletteOptions.find((option: PaletteOption) => {
      return option.name === paletteName;
    });
    return selectedOption;
  }, [project.supportingPalette]);

  const setColorValue = useCallback(
    (key: string, value: string) => {
      const args: { [fieldName: string]: string } = {};
      args[key] = value;
      form.setFieldsValue(args);
    },
    [form],
  );

  return (
    <>
      <Row>
        <Col className="onboarding-fields customize">
          <Title level={2}>
            {'Let\'s get this looking more like'} <Text strong>{project.name}</Text>
          </Title>
          <Paragraph>
            Don’t worry about getting this exactly right, you’ll be able to fully
            customize everything later.
          </Paragraph>
          <Form
            layout="vertical"
            form={form}
            initialValues={project}
            onValuesChange={(changedValues, allValues) => {
              updateProject({
                colorTheme: 'custom',
                ...selectedPalette(changedValues.supportingPalette)?.colors,
                ...changedValues,
              });
            }}
          >
            <Form.Item
              label="Logo"
            >
              <UploadField
                // TODO: Fix type error.
                image={project?.logo ? project.logo : null}
                onRemove={() => removeLogo}
                onUpload={(signedId: string) => {
                  updateProject({ logo: signedId });
                }}
                button={(loading: boolean) => {
                  if (loading) return <Text>Uploading...</Text>;
                  return (
                    <>
                      <Space direction="vertical">
                        <FontAwesomeIcon icon={faUpload} />
                        <Text>Click to upload</Text>
                      </Space>
                    </>
                  );
                }}
              >
                <Button icon={<FontAwesomeIcon icon={faUpload} />}>Click to upload</Button>
              </UploadField>
            </Form.Item>

            <Form.Item
              label="Primary accent color"
              name="primaryColor"
            >
              <ColorInput
                value={project?.primaryColor}
                onChange={(value: string) =>
                  setColorValue('primaryColor', value)
                }
                onDisabledClick={() => { // no function
                }}
              />
            </Form.Item>

            <Form.Item
              label="Supporting palette"
              name="supportingPalette"
            >
              <Radio.Group>
                <Radio.Button value="cool">Cool</Radio.Button>
                <Radio.Button value="neutral">Neutral</Radio.Button>
                <Radio.Button value="warm">Warm</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row justify="space-between" className="buttons-container">
        <Col>
          <Link to={previousUrl}>
            <Button size="small" type="text">Back</Button>
          </Link>
        </Col>
        <Col>
          <Link to={nextUrl}>
            <Button size="large" type="primary">Next</Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col className="step-progress">2/5</Col>
      </Row>
    </>
  );
};

export default CustomizeStep;
