import React from 'react';
import { Form, Input, Modal } from 'antd';
import { ImportanceFeedbackSection } from '@launchnotes/components/Feedback/ImportanceFeedbackSection';
import { OrganizeFeedbackSection } from '@launchnotes/components/Feedback/OrganizeFeedbackSection';
import { feedbackLabel } from '@launchnotes/components/Feedback/utils';
import { useFeedbackModalController } from '@launchnotes/components/Feedback/hooks/useFeedbackModalController';
import { FeedbackableSelect } from '@launchnotes/components/Select/FeedbackableSelect';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import { FeedbackTableFeedback } from '../FeedbackTable';
import { Feedback, UpdateFeedbackModalQuery, useUpdateFeedbackModalQuery } from '../../../generated/graphql';
import { SmallSecondary } from '../../Layout';

const { TextArea } = Input;

type UpdateFeedbackModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  feedback: FeedbackTableFeedback | Feedback;
  successCallback?: () => void;
};

export const UpdateFeedbackModal: React.FC<UpdateFeedbackModalProps> = ({
  visible,
  setVisible,
  feedback,
  successCallback,
}) => {
  const projectId = useProjectId();

  const { data } = useUpdateFeedbackModalQuery({ projectId, feedbackId: feedback.id });

  const announcements = (data?.project.announcements?.nodes || []).filter((node): node is NonNullable<typeof node> => !!node);
  const ideas = (data?.project.ideas?.nodes || []).filter((node): node is NonNullable<typeof node> => !!node);
  const workItems = (data?.project.workItems?.nodes || []).filter((node): node is NonNullable<typeof node> => !!node);

  return (
    <>
      {data && data.feedback && data.project && (
        <UpdateFeedbackModalInternal
          feedback={feedback}
          ideas={ideas}
          workItems={workItems}
          announcements={announcements}
          setVisible={setVisible}
          visible={visible}
          data={data}
          successCallback={successCallback}
        />
      )}
    </>
  );
};

type UpdateFeedbackModalInternalProps =  UpdateFeedbackModalProps & {
  data: UpdateFeedbackModalQuery;
  ideas: { id: string, name: string }[];
  announcements: { id: string, name: string }[];
  workItems: { id: string, name: string }[];
};

const UpdateFeedbackModalInternal: React.FC<UpdateFeedbackModalInternalProps> = ({
  announcements,
  ideas,
  workItems,
  visible,
  setVisible,
  data,
  successCallback
}) => {
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const { state, stateFunctions, mutators } = useFeedbackModalController({
    announcements: announcements,
    ideas: ideas,
    workItems: workItems,
    form: form,
    data: data,
    setVisbility: setVisible,
    successCallback,
  });
  const { importance, feedbackType, options } = state;
  const { updateImportance, updateFeedbackType, onSubmit } = stateFunctions;
  const { updateFeedback } = mutators;

  const feedbackableValue = {
    key: data?.feedback?.feedbackable?.id,
    value: data?.feedback?.feedbackable?.name,
  };

  return (<>
    <Modal
      title={'Update feedback'}
      className="new-feedback-form"
      centered
      open={visible}
      okText={'Update feedback'}
      onCancel={() => setVisible(false)}
      onOk={() => onSubmit()}
      okButtonProps={{ disabled: loading }}
      focusTriggerAfterClose={false}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={data?.feedback ? data.feedback : undefined}
        onFinish={(values) => updateFeedback(values)}
      >
        <Form.Item
          label="Short summary of the feedback"
          name="content"
          rules={[
            {
              type: 'string',
              required: true,
              message: 'Please provide a summary of the feedback.',
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          label="Email address of person whose feedback it is"
          name="affectedCustomerEmail"
        >
          <Input disabled/>
        </Form.Item>

        <Form.Item
          label="Notes"
          name="notes"
          extra={<SmallSecondary.Text>Add any context, links, etc. about this feedback</SmallSecondary.Text>}
        >
          <TextArea rows={4} />
        </Form.Item>

        <ImportanceFeedbackSection
          importance={importance}
          setImportance={updateImportance}
        />

        <Form.Item label="Organize feedback (optional)">
          <OrganizeFeedbackSection
            feedbackableType={feedbackType as LN.Feedbackables | undefined}
            setFeedbackableType={updateFeedbackType}
            preventDeslect={feedbackableValue.key !== undefined}
          />
        </Form.Item>

        {feedbackType && (
          <Form.Item
            className="feedback-modal-select"
            label={`Select ${feedbackLabel(feedbackType).label}`}
            name={'feedbackableId'}
            initialValue={feedbackableValue.key}
            required
            rules={[
              {
                type: 'string',
                required: true,
                message:
                  'Please select an idea, roadmap item, or announcement.',
              },
            ]}
          >
            <FeedbackableSelect
              type={feedbackType}
              options={options}
              loading={loading}
              setLoading={setLoading}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  </>);
};
