import React, { FC, useMemo, useState } from 'react';
import {
  IdeaVote,
  SortEnum,
  useAudienceDomainVotesQuery,
  InputMaybe,
  OrderBy,
} from '../../generated/graphql';
import {
  Tooltip,
  Typography,
} from 'antd';
import {useSubscriberDrawer} from '../Subscribers/DetailDrawer';
import type { ColumnsType } from 'antd/es/table';
import {usePagination} from '../SuperTable/Pagination';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import email from '../SuperTable/columns/email';
import {MinimalTable} from '../SuperTable';
import EmptyTableRenderer from './EmptyTableState';
import { Key, SortOrder } from 'antd/lib/table/interface';
import Time from '../Time';

type TableState = {
  orderBy?: {
    field: string | Key | undefined,
    sort: SortOrder
  }
};

const getSortDirection = (sortDirection?: SortEnum) => {
  if (!sortDirection) {
    return null;
  }

  return sortDirection === SortEnum.Asc ? 'ascend' : 'descend';
};

const DomainVotesTable: FC<{ id: string }> = ({ id }) => {
  const projectId = useProjectId();

  const [tableState, updateTableState] = useState<TableState>();

  const { previousPage, nextPage, updatePageSize, first, before, after } = usePagination();

  let orderBy: InputMaybe<OrderBy> | undefined;

  if (tableState?.orderBy?.field) {
    orderBy = {
      field: tableState.orderBy.field.toString(),
      sort: tableState.orderBy.sort === 'descend' ? SortEnum.Desc : SortEnum.Asc,
    };
  }

  const { subscriberDrawer, showSubscriberDrawer } = useSubscriberDrawer();

  const { data, isLoading } = useAudienceDomainVotesQuery({
    id,
    projectId,
    first,
    before,
    after,
    orderBy: orderBy || {
      field: 'createdAt',
      sort: SortEnum.Desc,
    },
  });

  const columns: ColumnsType<IdeaVote> = useMemo(() => [
    email<IdeaVote>(
      'Email',
      ['subscriber'],
      'email',
      {
        onClick: showSubscriberDrawer,
        subscriberIdIndex: ['subscriber', 'id'],
        width: '35%',
      },
    ),
    {
      title: 'Idea',
      dataIndex: ['id'],
      key: 'id',
      width: '40%',
      render: (_id, { idea: { name, privatePermalink }}) => {
        return (
          <Tooltip title={name}>
            <Typography.Link ellipsis href={privatePermalink}>{name}</Typography.Link>
          </Tooltip>
        );
      },
    },
    {
      title: 'Date Voted',
      dataIndex: ['createdAt'],
      key: 'createdAt',
      sorter: true,
      width: '25%',
      sortOrder: orderBy?.field === 'createdAt' ? getSortDirection(orderBy.sort) : null,
      render: (value: string) => <Time timestamp={value} />,
    },
  ], [showSubscriberDrawer, orderBy]);

  const VotesTable = useMemo(() => MinimalTable<IdeaVote>(), []);

  if (!data?.project?.audienceDomain?.votes) return null;

  return (
    <>
      <VotesTable
        data={data?.project?.audienceDomain?.votes.nodes as IdeaVote[]}
        columns={columns}
        pageInfo={data?.project?.audienceDomain?.votes.pageInfo}
        totalCount={data?.project?.audienceDomain?.votes.totalCount}
        first={first}
        nextPage={nextPage}
        previousPage={previousPage}
        updatePageSize={updatePageSize}
        isLoading={isLoading}
        bordered
        emptyState={EmptyTableRenderer('No Votes')}
        onChange={(_, __, sorting) => {
          let field, sort;
          if (Array.isArray(sorting)) {
            sort = sorting[0].order;
            field = sorting[0].columnKey;
          } else {
            sort = sorting.order;
            field = sorting.columnKey;
          }

          if (sort === undefined) {
            updateTableState({
              ...tableState,
              orderBy: undefined,
            });
          } else {
            updateTableState({
              ...tableState,
              orderBy: { field, sort },
            });
          }
        }}
      />

      {subscriberDrawer}
    </>
  );
};

export default DomainVotesTable;