import React from 'react';
import { useUserContext } from '../AppContext';
import { lnTokens } from '.';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'launchnotes-embed': any;
    }
  }
}

const PRODUCTION_META: string = 'pro_4flZZoOktFBaP';
const PRODUCTION_META_PUBLIC_TOKEN: string = 'public_GXi0fYS3rG58nWDX8t5dJh1G';

const WhatsNew = () => {
  const user = useUserContext();

  return (
    <launchnotes-embed
      project={PRODUCTION_META}
      token={PRODUCTION_META_PUBLIC_TOKEN}
      toggle-selector="#whats-new-launchnotes"
      backend-root-override={null}
      primary-color={lnTokens.cosmos}
      heading-color={lnTokens.astronaut}
      subheading-color={lnTokens.cosmicDust}
      subheading="The latest from the LaunchNotes team"
      unread-background-color={lnTokens.moon}
      unread-text-color={lnTokens.cosmos}
      heading="Product updates"
      anonymous-user-id={user?.id}
      unread-placement="right"
      widget-placement="right-end"
      unread-offset-skidding={-6}
      unread-offset-distance={-70}
      widget-offset-distance={215}
      widget-offset-skidding={0}
      show-unread
    />
  );
};

export default WhatsNew;
