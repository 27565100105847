import useUrlState from "@ahooksjs/use-url-state";
import { faFaceSmile, faFilter } from "@fortawesome/pro-regular-svg-icons";
import useProjectId from "@launchnotes/common-hooks/useProjectId";
import FontAwesomeIcon from "@launchnotes/icons/FontAwesomeIcon";
import HighImportance from "@launchnotes/icons/HighImportance";
import LowImportance from "@launchnotes/icons/LowImportance";
import MediumImportance from "@launchnotes/icons/MediumImportance";
import MehFace from "@launchnotes/icons/MehFace";
import SadFace from "@launchnotes/icons/SadFace";
import { FeedbackOrganizationState } from "@launchnotes/pages/Feedback/FeedbackInbox";
import { useDebounceFn } from "ahooks";
import { Button, Drawer, Form, Input, InputNumber, Select, Space } from "antd";
import React, { FC, useCallback, useMemo, useState } from "react";
import { COHORTS_ROLLOUT, useFeatureFlags } from "../../FeatureFlags";
import {
  AttributeDefinition,
  Importance,
  Reaction,
  useCohortsIndexQuery,
} from "../../generated/graphql";
import FilterDrawer from "../SuperTable/Filters/Drawer";
import FilterItem from "../SuperTable/Filters/FilterItem";

const { Option } = Select;

type Props = {
  attributeDefinitions: AttributeDefinition[];
};

const FeedbackFilterDrawer: FC<Props> = ({ attributeDefinitions = [] }) => {
  const { features } = useFeatureFlags([COHORTS_ROLLOUT]);
  const projectId = useProjectId();

  const [searchParams, updateSearchParams] = useUrlState<Record<string, any>>();

  const debouncedUpdateSearch = useDebounceFn(updateSearchParams, {
    wait: 200,
  });

  const { data, isLoading } = useCohortsIndexQuery({
    projectId,
  });

  return (
      <FilterDrawer filters={searchParams} updateFilters={updateSearchParams}>
        <FilterItem label="Importance">
          <Select
            allowClear
            value={searchParams.importance}
            placeholder="All importances"
            onSelect={(importance: Importance) =>
              updateSearchParams({ importance })
            }
            onClear={() => updateSearchParams({ importance: undefined })}
          >
            <Option value="low" key="low" className="feedback-filter-option">
              <LowImportance /> Nice to have
            </Option>
            <Option
              value="medium"
              key="medium"
              className="feedback-filter-option"
            >
              <MediumImportance /> Somewhat
            </Option>
            <Option value="high" key="high" className="feedback-filter-option">
              <HighImportance /> Extremely
            </Option>
          </Select>
        </FilterItem>

        <FilterItem label="REACTION">
          <Select
            allowClear
            value={searchParams.reaction}
            placeholder="All reactions"
            onSelect={(reaction: Reaction) => updateSearchParams({ reaction })}
            onClear={() => updateSearchParams({ reaction: undefined })}
          >
            <Option
              value="happy"
              key="happy"
              className="feedback-filter-option"
            >
              <FontAwesomeIcon icon={faFaceSmile} className="success-color" />{" "}
              Happy
            </Option>
            <Option value="meh" key="meh" className="feedback-filter-option">
              <MehFace /> Meh
            </Option>
            <Option value="sad" key="sad" className="feedback-filter-option">
              <SadFace className="warn-color" /> Unhappy
            </Option>
          </Select>
        </FilterItem>

        <FilterItem label="ORGANIZED">
          <Select
            allowClear
            style={{ minWidth: "240px" }}
            value={searchParams.organizedState}
            placeholder="Organized state"
            onSelect={(organizedState: string) =>
              updateSearchParams({ organizedState })
            }
            onClear={() => updateSearchParams({ organizedState: undefined })}
          >
            <Option value={FeedbackOrganizationState.Unorganized}>
              Needs organizing
            </Option>
            <Option value={FeedbackOrganizationState.Organized}>
              Organized
            </Option>
            <Option value={FeedbackOrganizationState.Roadmap}>
              Organized to roadmap items
            </Option>
            <Option value={FeedbackOrganizationState.Announcement}>
              Organized to announcements
            </Option>
            <Option value={FeedbackOrganizationState.Idea}>
              Organized to ideas
            </Option>
          </Select>
        </FilterItem>
        
        {features[COHORTS_ROLLOUT] && (
          <FilterItem label="COHORT">
            <Select
              allowClear
              value={searchParams.cohortId}
              placeholder="All Cohorts"
              onSelect={(cohortId: string) =>
                updateSearchParams({ cohortId })
              }
              onClear={() => updateSearchParams({ cohortId: undefined })}
            >
              {data?.project?.cohorts?.nodes?.map((cohort) => (
                <Option
                  value={cohort.id}
                  key={cohort.id}
                  className="feedback-filter-option"
                >
                  {cohort.name}
                </Option>
              ))}
            </Select>
          </FilterItem>
        )}

        
        {attributeDefinitions.map(({ name, key, valueType, description }) => (
          <FilterItem
            label={name.toLocaleUpperCase()}
            tooltip={description}
            name={`attributes.${key}`}
            onReset={() =>
              updateSearchParams({ [`attributes.${key}`]: undefined })
            }
          >
            {valueType === "string" && (
              <Input
                onChange={({ target: { value } }) =>
                  debouncedUpdateSearch.run({ [`attributes.${key}`]: value })
                }
                value={searchParams[`attributes.${key}`]}
              />
            )}
            {valueType === "boolean" && (
              <Select
                onChange={(value) =>
                  debouncedUpdateSearch.run({ [`attributes.${key}`]: value })
                }
                value={searchParams[`attributes.${key}`]}
                allowClear={true}
              >
                <Select.Option value={true}>True</Select.Option>
                <Select.Option value={false}>False</Select.Option>
              </Select>
            )}
            {(valueType === "integer" || valueType === "float") && (
              <>
                <Space direction="horizontal">
                  <InputNumber
                    onChange={(value) =>
                      debouncedUpdateSearch.run({
                        [`attributes.${key}.min`]: value,
                      })
                    }
                    placeholder="Minimum"
                    value={searchParams[`attributes.${key}.min`]}
                  />
                  -
                  <InputNumber
                    onChange={(value) =>
                      debouncedUpdateSearch.run({
                        [`attributes.${key}.max`]: value,
                      })
                    }
                    placeholder="Maximum"
                    value={searchParams[`attributes.${key}.max`]}
                  />
                </Space>
              </>
            )}
          </FilterItem>
        ))}
      </FilterDrawer>
  );
};

export default FeedbackFilterDrawer;
