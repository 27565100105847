import { useRouteMatch } from "react-router-dom";

const useProjectId = () => {
  const match: any = useRouteMatch('/projects/:projectId');
  let projectId;
  if (match) {
    projectId = match.params.projectId;
  }

  return projectId;
};

export default useProjectId;