import React from "react";
import Icon from "@ant-design/icons";

const Svg = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 9 9.7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    x="0px" y="0px"
  >
    <path
      fill="#CFAA34"
      d="M1.3,5l3.2-3.2L7.7,5C7.9,5.2,8,5.2,8.3,5.2c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1L5,0.2
	C4.7-0.1,4.3-0.1,4,0.2L0.2,4c-0.3,0.3-0.3,0.8,0,1.1C0.5,5.3,1,5.3,1.3,5z M5,4.7C4.7,4.4,4.3,4.4,4,4.7L0.2,8.5
	c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0l3.2-3.2l3.2,3.2C7.9,9.7,8,9.7,8.3,9.7c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1
	L5,4.7z"
    />
  </svg>
);

const SelectedSvg = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 9 9.7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    x="0px" y="0px"
  >
    <path
      fill="#E66A47"
      d="M1.3,5l3.2-3.2L7.7,5C7.9,5.2,8,5.2,8.3,5.2c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1L5,0.2
	C4.7-0.1,4.3-0.1,4,0.2L0.2,4c-0.3,0.3-0.3,0.8,0,1.1C0.5,5.3,1,5.3,1.3,5z M5,4.7C4.7,4.4,4.3,4.4,4,4.7L0.2,8.5
	c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0l3.2-3.2l3.2,3.2C7.9,9.7,8,9.7,8.3,9.7c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1
	L5,4.7z"
    />
  </svg>
);

const MediumImportance = (props: any) => <Icon component={Svg} {...props} />;
const SelectedMediumImportance = (props: any) => (
  <Icon component={SelectedSvg} {...props} />
);

export default MediumImportance;
