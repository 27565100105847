import { useCallback } from 'react';

const TOKEN_NAMESPACE = 'launchnotes-goatToken';
const DISMISS_NAMESPACE = "launchnotes-dismissToken"
const EXPIRY_SECONDS = 86400; // 24 hours

// Get, Set, and Clear the GOAT's auth token from local storage.
// Clear the token if it is more than 24 hours old.
const useSessionToken = () => {

  const setLocalToken = useCallback((userToken: string):void => {
    const now = new Date();
    const item = {
      value: userToken,
      expiry: now.getTime() + EXPIRY_SECONDS * 1000, // expiry in milliseconds
    };
    localStorage.setItem(TOKEN_NAMESPACE, JSON.stringify(item));
  }, []);

  const fetchLocalToken = useCallback(():string|undefined => {
    const itemStr = localStorage.getItem(TOKEN_NAMESPACE);

    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(TOKEN_NAMESPACE);
      return null;
    }

    return item.value;
  }, []);

  const clearLocalToken = useCallback(():void => {
    localStorage.removeItem(TOKEN_NAMESPACE);
  }, []);

  const setDismissToken = useCallback((userToken: string):void => {
    const item = {
      value: userToken,
    };
    localStorage.setItem(DISMISS_NAMESPACE, JSON.stringify(item));
  }, []);

  const fetchDismissToken = useCallback(():string|undefined => {
    const itemStr = localStorage.getItem(DISMISS_NAMESPACE);
    if (!itemStr) return null;
    const item = JSON.parse(itemStr);

    return item.value;
  }, []);

  return {
    setLocalToken,
    clearLocalToken,
    fetchLocalToken,
    setDismissToken,
    fetchDismissToken,
  };
};

export default useSessionToken;
