import { GlobalToken } from 'antd/lib/theme';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';

export const projectSelectStyles = (_token: GlobalToken): CSSProperties =>  ({
  width: '165px',
  cursor: 'pointer',
  paddingLeft: 0,
  marginLeft: '-10px'
});

export const dropdownStyles = (_token: GlobalToken): CSSProperties => ({
  paddingBottom: '14px',
});

export const indicator = (_token: GlobalToken) => ({
  marginLeft: 'auto',
  paddingLeft: '14px',
});

export const ProjectSelectWrapper = styled(Select)`
  .ant-select {
    &.ant-select-selector {
    padding: 0;
  }
  }

`;