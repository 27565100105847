/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { Badge, ConfigProvider, Space, Tooltip, Typography } from 'antd';
import { CategoryTagsFragment } from '../../generated/graphql';

const { Text } = Typography;

export type Category = {
  readonly id: string,
  readonly name: string | null,
  readonly backgroundColor: string,
  readonly textColor?: string,
}

type CategoryTagsProps = {
  readonly categories: readonly Category[];
  project?: CategoryTagsFragment | null;
}

export const StaticCategories: FC<{categories: any, hideText?: boolean}> = ({ categories, hideText }) => {

  if (categories.length === 0) return null;

  return (
    <ConfigProvider
      theme={{
        components: {
          Badge: {
            dotSize: 8
          }
        }
      }}
    >
    <Space className={'category-badges'} style={{ rowGap: 0, columnGap: '6px', marginRight: 6 }} wrap>
      {categories?.map(({ id, name, backgroundColor, color}:{id:string,name:string,backgroundColor:string,color:string}) => (
        <Tooltip
          key={id}
          title={name}
        >
          <Badge
            count={hideText ? '' : null}
            dot
            color={backgroundColor || color}
            className={'category-badge'}
            text={
              hideText ? null :
              <Text ellipsis={{tooltip: name}} style={{ maxWidth: 165 }} type='secondary'>
                {name.toLocaleUpperCase()}
              </Text>
            }
            />
        </Tooltip>
      ))}
    </Space>
    </ConfigProvider>
  );
};

const CategoryTags: FC<CategoryTagsProps> = (props) => {
  if (!props.categories || props.categories.length === 0) return null;

  const selectedIds = props.categories.map(({ id }) => id);
  const selectedCategories = props.project?.categories?.nodes?.filter(({ id }: any) => selectedIds.includes(id));

  if (!selectedCategories) return null;

  return <StaticCategories categories={selectedCategories} />;
};

export default CategoryTags;
