import React, { useEffect, useState, useRef } from 'react';
import { Manager, Reference, Popper } from 'react-popper';
import { TwitterPicker } from 'react-color';
import { Button, Space } from 'antd';

type Props = {
  value: string,
  onChange?: (value: string) => void,
  onDisabledClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void,
  disabled?: boolean,
}

const ColorInput = ({ value, onChange, disabled, onDisabledClick }: Props) => {
  const [open, updateOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>): void => {
    if (!node.current) return;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    if ((node as any).current.contains(e.target)) {
      return;
    }
    updateOpen(false);
  };

  const handleChange = (newValue: string) => {
    if (value === newValue) return;
    onChange?.(newValue);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick as any);
    return () => {
      document.removeEventListener('mousedown', handleClick as any);
    };
  }, []);

  if (disabled) {
    return (
      <>
        <Button
          /* Style lock: Required for the color picker, not an override-able style */
          style={{
            backgroundColor: value,
            width: "100%"
          }}
          className="color-field"
          onClick={onDisabledClick}
          block
          disabled
        >&nbsp;</Button>
      </>
    );
  }

  return (
    <Space ref={node} tabIndex={0} onFocus={() => updateOpen(true)} style={{"width": "100%", display: "block"}}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <Button
              /* Style lock: Used for color indicator, not an override-able style */
              style={{
                backgroundColor: value,
                width: "100%"
              }}
              className="color-field"
              ref={ref}
              onClick={() => {
                updateOpen(true);
              }}
              block
            >&nbsp;</Button>
          )}
        </Reference>
        { open
          ? (
            <Popper>
              {({ ref, placement }) => (
                <Space ref={ref} data-placement={placement} style={{ position: "absolute", zIndex: 50, left: 0, top: 38 }}>
                  <TwitterPicker
                    color={value || ''}
                    onChangeComplete={({ hex }) => handleChange(hex)}
                    triangle="hide"
                  />
                </Space>
              )}
            </Popper>
          )
          : null }
      </Manager>
    </Space>
  );
};

export default ColorInput;