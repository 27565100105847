import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import 'react-cmdk/dist/cmdk.css';
import { Command } from 'cmdk';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import { useSearchInProjectQuery } from '../../generated/graphql';
import { useHistory } from 'react-router-dom';
import { useDomainDrawer } from '../Domains/DetailDrawer';
import { useSubscriberDrawer } from '../Subscribers/DetailDrawer';
import { Spin, theme } from 'antd';

import styled from 'styled-components';

const { getDesignToken } = theme;

const globalToken = getDesignToken();

const CMDKWrapper = styled.div`
#cmdk-container {
  position: absolute;
  z-index: 10000;
}

[cmdk-dialog] {
  align-items: center;
  background-color: rgba(150, 150, 150, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100vw;
}

[cmdk-root] {
  max-width: 640px;
  width: 100%;
  padding: 8px;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid ${globalToken.colorBorder};
  box-shadow: var(--cmdk-shadow);
  transition: transform 100ms ease;
}

[cmdk-root] * {
  font-family: Arial;
}

[cmdk-input] {
  border: none;
  width: 100%;
  font-size: 17px;
  padding: 8px 8px 16px 8px;
  outline: none;
  background: var(--bg);
  border-bottom: 1px solid ${globalToken.colorBorder};
  margin-bottom: 16px;
  border-radius: 0;
}

[cmdk-item] {
  content-visibility: auto;

  cursor: pointer;
  height: 48px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  user-select: none;
  will-change: background, color;
  transition: all 150ms ease;
  transition-property: none;

  &[data-selected='true'] {
    background: ${globalToken.colorBgTextHover};
  }

  &[data-disabled='true'] {
    cursor: not-allowed;
  }

  &:active {
    transition-property: background;
    background: ${globalToken.colorBgTextHover};
  }

  & + [cmdk-item] {
    margin-top: 4px;
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

[cmdk-list] {
  max-height: 400px;
  overflow: auto;
  overscroll-behavior: contain;
  transition: 100ms ease;
  transition-property: height;
}

[cmdk-separator] {
  height: 1px;
  width: 100%;
  background: ${globalToken.colorBgTextHover};
  margin: 4px 0;
}

*:not([hidden]) + [cmdk-group] {
  margin-top: 8px;
}

[cmdk-group-heading] {
  user-select: none;
  font-size: 14px;
  font-weight: 800;
  color: ${globalToken.colorText};
  padding: 0 8px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

[cmdk-empty] {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  white-space: pre-wrap;
  color: ${globalToken.colorText};
}

[cmdk-loading] {
  margin: 6px 4px 12px;
}

.loading-spinner {
  margin-right: 8px;
  display: inline-block;
}
`
// To clear the async call to search for debouncing
let timeoutId = null;

const nameMap = {
  'Announcement': 'Announcements',
  'WorkItem': 'Roadmap items',
  'Subscriber': 'Subscribers',
  'Idea': 'Ideas',
  'AudienceDomain': 'Audience domains',
};

const CommandK = () => {
  const projectId = useProjectId();

  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [foundIds, setFoundIds] = useState([]);

  const history = useHistory();

  const { data, refetch } = useSearchInProjectQuery({ projectId, term: search }, { enabled: false });

  const [resultData, setResultData] = useState([]);

  const { domainDrawer, showDomainDrawer } = useDomainDrawer();
  const { subscriberDrawer, showSubscriberDrawer } = useSubscriberDrawer();

  const selectItem = useMemo(() => {
    return (destination: string) => {
      setOpen(false);
      setSearch('');
      history.push(destination);
    };
  }, [history]);

  useMemo(() => {
    clearTimeout(timeoutId);
    setLoading(true);

    if (search !== '') {
      timeoutId = setTimeout(() => {
        refetch();
      }, 500);
    }
  }, [search, refetch, setLoading]);

  useMemo(() => {
    const results = [];
    const ids = [];

    data?.results.forEach((res) => {
      let index = results.findIndex(({ id }) => res.type === id);

      if (index === -1) {
        results.push({
          heading: nameMap[res.type],
          id: res.type,
          items: [],
        });

        index = results.findIndex(({ id }) => res.type === id);
      }

      // cmdk happens to lowercase every value, so there
      // could potentially be a collision, but we need this
      ids.push(res.id.toLowerCase());

      let children: string;
      if (res.type === 'Announcement') {
        children = res.headline || 'New un-named announcement';
      } else if (res.type === 'Subscriber') {
        children = res.email;
      } else if (res.type === 'AudienceDomain') {
        children = res.hostname;
      } else if (res.type === 'WorkItem' || res.type === 'Idea') {
        children = res.name;
      }

      results[index]['items'].push({
        id: res.id,
        children,
        showType: false,
        onClick: () => {
          if (res.type === 'AudienceDomain') {
            showDomainDrawer(res.id);
          } else if (res.type === 'Subscriber') {
            showSubscriberDrawer(res.id);
          } else if (res.type === 'Announcement') {
            if (res.publishedAt) {
              selectItem(`/projects/${projectId}/announcements/${res.id}/published`);
            } else {
              selectItem(`/projects/${projectId}/announcements/${res.id}/content`);
            }
          } else if (res.type === 'WorkItem') {
            selectItem(`/projects/${projectId}/work_items/${res.id}`);
          } else if (res.type === 'Idea') {
            selectItem(`/projects/${projectId}/feedback/ideas/${res.id}`);
          }
        },
      });
    });

    setLoading(false);
    setFoundIds(ids);
    setResultData(results);
  }, [data, projectId, showSubscriberDrawer, showDomainDrawer, selectItem]);

  const filteredItems = useMemo(() => {
    let searchableItems = [
      {
        heading: 'Announcements',
        id: 'announcements',
        items: [
          {
            id: 'all-announcements',
            children: 'All',
            onClick: () => selectItem(`/projects/${projectId}/announcements`),
          },
          {
            id: 'draft-announcements',
            children: 'Draft',
            onClick: () => selectItem(`/projects/${projectId}/announcements?state=draft`),
          },
          {
            id: 'published-announcements',
            children: 'Published',
            onClick: () => selectItem(`/projects/${projectId}/announcements?state=published`),
          },
          {
            id: 'announcements-templates',
            children: 'Templates',
            onClick: () => selectItem(`/projects/${projectId}/announcements/templates`),
          },
          {
            id: 'archived-announcements',
            children: 'Archived',
            onClick: () => selectItem(`/projects/${projectId}/announcements?state=archived`),
          },
        ],
      },
      {
        heading: 'Roadmap',
        id: 'roadmap',
        items: [
          {
            id: 'active-roadmap',
            children: 'Active',
            onClick: () => selectItem(`/projects/${projectId}/roadmap`),
          },
          {
            id: 'archived-roadmap',
            children: 'Archived',
            onClick: () => selectItem(`/projects/${projectId}/roadmap/archived`),
          },
        ],
      },
      {
        heading: 'Audience',
        id: 'audience',
        items: [
          {
            id: 'audience-subscribers',
            children: 'Subscribers',
            onClick: () => selectItem(`/projects/${projectId}/audience/subscribers`),
          },
          {
            id: 'audience-domains',
            children: 'Domains',
            onClick: () => selectItem(`/projects/${projectId}/audience/domains`),
          },
        ],
      },
      {
        heading: 'Ideas & feedback',
        id: 'ideas-and-feedback',
        items: [
          {
            id: 'feedback',
            children: 'Feedback',
            onClick: () => selectItem(`/projects/${projectId}/feedback/inbox`),
          },
          {
            id: 'ideas',
            children: 'Ideas',
            onClick: () => selectItem(`/projects/${projectId}/feedback/ideas`),
          },
        ],
      },
      {
        heading: 'Admin',
        id: 'admin',
        items: [
          {
            id: 'settings',
            children: 'Settings',
            onClick: () => selectItem(`/projects/${projectId}/admin/customize-page`),
          },
        ],
      },
    ];

    if (search === '') {
      searchableItems = [{
        heading: 'Dashboard',
        id: 'dashboard',
        items: [
          {
            id: 'announcement-analytics',
            children: 'Announcements',
            onClick: () => selectItem(`/projects/${projectId}`),
            keywords: ['dashboard', 'analytics', 'announcements'],
          },
          {
            id: 'subscriber-analytics',
            children: 'Subscribers',
            onClick: () => selectItem(`/projects/${projectId}/subscriber-analytics`),
          },
          {
            id: 'page-and-widget-analytics',
            children: 'Page & widget',
            onClick: () => selectItem(`/projects/${projectId}/page-and-widget-analytics`),
          },
          {
            id: 'feedback-analytics',
            children: 'Feedback',
            onClick: () => selectItem(`/projects/${projectId}/feedback-analytics`),
          },
        ],
      }].concat(searchableItems);
    }

    return searchableItems;
  }, [projectId, search, selectItem]);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  const ref = useRef(null);

  useEffect(() => {
    const refNode = ref.current;
    const handleWrapperClick = (event: MouseEvent) => {
      // Necessary so es-lint doesn't complain about attributes
      const target = event.target as HTMLElement;
      if (target.attributes.getNamedItem('cmdk-dialog')) {
        setOpen(false);
      }
    };

    if (refNode) {
      refNode.addEventListener('click', handleWrapperClick);

      return () => {
        refNode.removeEventListener('click', handleWrapperClick);
      };
    }
  }, [ref]);

  const filter = useCallback((value: string, search: string): number => {
    if (search === "") { return 1 }
    if (foundIds.includes(value)) { return 1 }
    if (value.includes(search)) { return 1 }
    return 0
  }, [foundIds])

  return (
    <>
      <Command.Dialog filter={filter} open={open} onOpenChange={setOpen} container={ref.current} label="Global Command Menu" >
        <Command.Input placeholder={'Search'} value={search} onValueChange={setSearch}/>
        <Command.List>
          { !loading && (<Command.Empty>No results...</Command.Empty>) }
          { loading && (<Command.Loading><Spin className='loading-spinner'/>Searching ...</Command.Loading>) }

          { resultData.map((group) => (
            <Command.Group key={`${group.id}-searched`} heading={group.heading}>
              { group.items.map((item) => (
                <Command.Item value={item.id} key={item.id} onSelect={item.onClick}>{item.children}</Command.Item>
              ))}
            </Command.Group>
          ))}

          { !loading && filteredItems.map((group) => (
            <Command.Group key={group.id} heading={group.heading}>
              { group.items.map((item) => (
                <Command.Item value={item.id} key={item.id} onSelect={item.onClick}>{item.children}</Command.Item>
              ))}
            </Command.Group>
          ))}
        </Command.List>
      </Command.Dialog>
      <CMDKWrapper>
        <div id="cmdk-container" ref={ref}></div>
      </CMDKWrapper>
      { domainDrawer }
      { subscriberDrawer }
    </>
  );
};

export default CommandK;
