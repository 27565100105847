import React, { FC, useMemo } from 'react';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import { useSubscriberRoadmapQuery, WorkItem } from '../../generated/graphql';
import { usePagination } from '../SuperTable/Pagination';
import type { ColumnsType } from 'antd/es/table';
import { Typography } from 'antd';
import { MinimalTable } from '../SuperTable';
import EmptyTableRenderer from '../Domains/EmptyTableState';

const SubscriberRoadmapItemsTable: FC<{ id: string }> = ({id}) => {
  const projectId = useProjectId();
  const { previousPage, nextPage, updatePageSize, first, before, after } = usePagination();

  const { data, isLoading } = useSubscriberRoadmapQuery({
    id,
    projectId,
    first,
    before,
    after,
  });

  const columns: ColumnsType<WorkItem> = useMemo(() => [
    {
      title: 'Roadmap item',
      dataIndex: ['name'],
      width: 800,
      render: (name, { privatePermalink }) => {
        return <Typography.Link target="_blank" href={privatePermalink}>{name}</Typography.Link>;
      },
    },
    {
      title: 'Stage',
      width: '40%',
      render: ({ stage: { name }}) => {
        return (
          <Typography.Text>{name}</Typography.Text>
        );
      },
    },
  ],[]);

  const RoadmapTable = MinimalTable<WorkItem> ();

  let subscriber, workItems;
  if (data?.project?.subscribers?.nodes && data?.project?.subscribers.nodes.length > 0) {
    subscriber = data?.project?.subscribers?.nodes[0];
    workItems = subscriber?.workItems?.nodes || [];
  }

  return (
    <RoadmapTable
      data={workItems as [WorkItem]}
      columns={columns}
      pageInfo={subscriber?.workItems.pageInfo}
      first={first}
      nextPage={nextPage}
      previousPage={previousPage}
      updatePageSize={updatePageSize}
      isLoading={isLoading}
      totalCount={subscriber?.workItems.totalCount}
      emptyState={EmptyTableRenderer('No Roadmap Items')}
      bordered
    />
  );
};

export default SubscriberRoadmapItemsTable;