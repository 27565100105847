import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Form, Input, Select, Button, Typography, Row, Col } from 'antd';
import { useUpdateProject } from './hooks/UpdateOnboardingProject';
import { WizardInfoStepFragment } from '../../../generated/graphql';
const { Option } = Select;
const { Text, Title, Paragraph } = Typography;

type Props = {
  project: WizardInfoStepFragment;
};

const InfoStep = (props: Props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const project: WizardInfoStepFragment = props?.project;
  const { updateProject, errors } = useUpdateProject(project);

  useEffect(() => {
    if (errors.length > 0) {
      // TODO: Fix this.
      // form.setFields(errors);
    }
  }, [errors, form]);

  const next = useCallback(() => {
    form.validateFields()
      .then(() => history.push(`/projects/${project.id}/welcome/customize`))
      .catch(() => {
        console.log('error');
      });
  }, [form, history, project.id]);

  return <>
    <Row>
      <Col className="onboarding-fields info">
        <Title level={2}>Welcome to <Text strong>LaunchNotes</Text></Title>
        <Paragraph>You’re on your way to getting more out of every product change.</Paragraph>
        <Paragraph>We just need a few pieces of info to customize your LaunchNotes page.</Paragraph>
        <Form
          layout="vertical"
          form={form}
          initialValues={project.name.endsWith('\'s Launch Pad') ? {} : project}
          onValuesChange={(changedValues) => {
            form.setFields([{name: 'slug', errors: []}]);
            updateProject(changedValues);
          }}
        >
          <Form.Item
            label="What's your company or product name?"
            name="name"
            rules={[
              { required: true, message: 'Company or product name is required' },
            ]}
          >
            <Input
              placeholder="Your company or project name"
            />
          </Form.Item>

          <Form.Item
            label="Where did you first hear about us?"
            name="referralSource"
            rules={[
              { required: true, message: 'Referral source is required' },
            ]}
          >
            <Select
              placeholder="Select one"
            >
              <Option value="search_engine">Search engine</Option>
              <Option value="recommendation">Recommended by a friend or community</Option>
              <Option value="blog_publication">A blog or publication</Option>
              <Option value="powered_by">Saw another LaunchNotes customer's page</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Claim your subdomain"
            name="slug"
            rules={[
              { required: true, message: 'Subdomain is required' },
              { pattern: /^[a-zA-Z0-9_-]*$/, message: 'Only use URL safe special characters (\'-\', \'_\')' },
            ]}
          >
            <Input
              placeholder="project-name"
              addonBefore="https://"
              suffix=".launchnotes.io"
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
    <Row justify="space-between" className="buttons-container">
      <Col />
      <Col>
        <Button size="large" type="primary" onClick={next}>
          Next
        </Button>
      </Col>
    </Row>
    <Row>
      <Col className="step-progress">1/5</Col>
    </Row>
  </>;
};
export default InfoStep;
