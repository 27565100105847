import React, { FC, useState } from 'react';
import {
  Col,
  Divider,
  Drawer,
  Row,
  Space,
  Skeleton,
  Tabs,
  Tooltip,
  Typography,
  theme
} from 'antd';
import { useAudienceDomainShowQuery } from '../../generated/graphql';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import useIsValidPlanLevel from '@launchnotes/common-hooks/useIsValidPlanLevel';
import Time from '../Time';
import SentimentIndicator from '../Sentiment/SentimentIndicator';
import DomainSubscribersTable from './DomainSubscribersTable';
import DomainFeedbacksTable from './DomainFeedbacksTable';
import DomainVotesTable from './DomainVotesTable';
import InvalidPlanPanel from './InvalidPlanPanel';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faArrowUpRightFromSquare,
  faGlobe,
  faCircleQuestion,
} from '@fortawesome/pro-regular-svg-icons'
import useBouncer, { FeatureList } from '@launchnotes/common-hooks/useBouncer';

const { Link } = Typography;
const { useToken } = theme;

export const useDomainDrawer = () => {
  const [visibleDomainID, updateVisible] = useState<string>();

  return {
    domainDrawer: visibleDomainID && (
      <DetailDrawer
        id={visibleDomainID}
        onClose={() => updateVisible(undefined)}
      />
    ),
    showDomainDrawer: updateVisible,
  };
};

const DetailDrawer: FC<{ id: string; onClose: () => void }> = ({
  id,
  onClose,
}) => {
  const projectId = useProjectId();
  const bouncer = useBouncer()
  const { token } = useToken();
  const validPlanLevel = useIsValidPlanLevel(['business', 'enterprise']);

  const { data, isLoading } = useAudienceDomainShowQuery({ id, projectId });

  const [visible, updateVisible] = useState(true);
  const [tab, updateTab] = useState('1');
  const hostname = data?.project?.audienceDomain?.hostname;

  return (
    <Drawer
      title={"Domain"

      }
      placement='right'
      size='large'
      onClose={() => {
        updateVisible(false);
        onClose();
      }}
      open={visible}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <Row>
            <Col span={2}>
              <Row justify={'start'} align={'middle'}>
                <Typography.Text><FontAwesomeIcon icon={faGlobe} size='2x' /></Typography.Text>
              </Row>
            </Col>
            <Col span={10}>
              <Space align='start'>
                <Typography.Title level={3} style={{marginTop: '0'}}>
                  {hostname}
                </Typography.Title>

                {hostname && (
                  <Link href={`//${hostname}`} target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </Link>
                )}
              </Space>

              <Typography.Paragraph type='secondary'>
                First seen{' '}
                <Time timestamp={data?.project?.audienceDomain?.firstSeen} />
              </Typography.Paragraph>
            </Col>
            <Col span={11}>
              <Row>
                <Typography.Text>
                  Average sentiment
                </Typography.Text>
                <Tooltip
                  title={
                    'LaunchNotes applies machine learning to identify the emotional tone behind feedback.'
                  }
                  placement='bottom'
                >
                  <FontAwesomeIcon icon={faCircleQuestion} style={{paddingLeft: '8px', paddingRight: '8px', color: `${token.colorTextTertiary}`}}/>
                </Tooltip>
                <Typography.Link
                  href='https://help.launchnotes.com/article/118-subscriber-and-domain-sentiment'
                  target='_blank'
                >
                    Learn more
                </Typography.Link>
              </Row>

              <Typography.Text>
                {validPlanLevel ? (
                  <SentimentIndicator
                    sentimentScore={
                      data?.project?.audienceDomain?.averageFeedbackSentiment
                    }
                    displayScore
                    titleOverride={`The average sentiment score for this domain is ${data?.project?.audienceDomain?.averageFeedbackSentiment?.toFixed(
                      2,
                    )}.`}
                  />
                ) : (
                  '--'
                )}
              </Typography.Text>
            </Col>
          </Row>

          <Divider />

          <Tabs defaultActiveKey={tab} onChange={updateTab}>
            <Tabs.TabPane
              tab={`Subscribers (${data?.project?.audienceDomain?.subscriberCount})`}
              key='1'
            >
              {validPlanLevel ? <DomainSubscribersTable id={id} /> : <></>}
            </Tabs.TabPane>
            { bouncer(FeatureList.FEEDBACK) &&
              <Tabs.TabPane
                tab={`Feedback (${data?.project?.audienceDomain?.feedbackCount})`}
                key='2'
              >
                {validPlanLevel ? <DomainFeedbacksTable id={id} /> : <></>}
              </Tabs.TabPane>
            }
            { bouncer(FeatureList.VOTING) &&
              <Tabs.TabPane
                tab={`Votes (${data?.project?.audienceDomain?.voteCount})`}
                key='3'
              >
                {validPlanLevel ? <DomainVotesTable id={id} /> : <></>}
              </Tabs.TabPane>
            }
          </Tabs>
          {!validPlanLevel && <InvalidPlanPanel />}
        </>
      )}
    </Drawer>
  );
};

export default DetailDrawer;
