import React, { FC } from "react";
import { Checkbox } from "antd";
type RadioProps = {
  isSelected: boolean;
  setIsSelected: (value: boolean) => void;
  description: JSX.Element;
};

export const SubscribeSection: FC<RadioProps> = ({
  isSelected,
  setIsSelected,
  description
}) => {
  return (
    <Checkbox
      onClick={() => setIsSelected(!isSelected)}
      checked={isSelected}
    >
      {description}
    </Checkbox>
  );
};
