import { message } from "antd";
import { useCallback } from "react";
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import useUpdateUserContext from '@launchnotes/common-hooks/useUpdateUserContext';
import { useWizardQuery, useWizardRemoveCategoryMutation } from '../../../../generated/graphql';
import { useInvalidateQuery } from '@launchnotes/common-hooks/useInvalidateQuery';

export const useRemoveCategory = (categoryId: string) => {
  const projectId = useProjectId();
  const { updateContext } = useUpdateUserContext();
  const [invalidate] = useInvalidateQuery(useWizardQuery.getKey({projectId}));
  const { mutate } = useWizardRemoveCategoryMutation({
    onSuccess: () => {
      updateContext(projectId);
      invalidate();
      message.success("Category removed");
    },
    onError: () => { message.error("Could not remove the category") }
  });

  const onRemove = useCallback(() => {
    mutate({ categoryId: categoryId });
  }, [mutate, categoryId]);

  return [onRemove];
};
