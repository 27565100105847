import React, { useMemo } from 'react';
import { lnTokens } from '../../Router';
import {
  Layout,
  Menu,
  Row,
  Skeleton,
  Space,
  Typography,
  theme,
} from 'antd';
import type { MenuProps } from 'antd/es/menu';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import classnames from 'classnames';
import StateTag from '@launchnotes/components/StateTag';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import WordMark from '@launchnotes/icons/LaunchNotes/WordMark';
import LogoL from '@launchnotes/icons/LaunchNotes/LogoL';
import { wordsToListAmpersand } from '../../utils';
import useKillSession from '@launchnotes/common-hooks/useKillSession';
import { UserAvatar } from '@launchnotes/components/Avatar';
import { ProjectSelect } from '../Select/ProjectSelect';
import { useAuthenticatedUrl } from '@launchnotes/common-hooks/useAuthenticatedUrl';
import {
  AUDIT_LOG,
  DIGESTS,
  FEEDBACK,
  SHAREABLE_VIEWS,
  COHORTS_ROLLOUT,
  useFeatureFlags,
} from '../../FeatureFlags';
import useBouncer, { FeatureList } from '@launchnotes/common-hooks/useBouncer';

import { useOrganizationContext, useProjectContext, useUserContext } from '../../AppContext';
import moment from 'moment';
import 'moment-timezone';
import { FeedbackOrganizationState } from '@launchnotes/pages/Feedback/FeedbackInbox';

import styled from 'styled-components';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon';
import {
  faArrowUpRightFromSquare,
  faArrowRightFromBracket,
  faBook,
  faBoxOpen,
  faBuilding,
  faCalendar,
  faComments,
  faDisplayCode,
  faGaugeHigh,
  faGear,
  faLock,
  faMap,
  faMessageQuestion,
  faMegaphone,
  faSparkles,
  faTelescope,
  faUserGear,
  faUserGroup,
  faWavePulse,
  faBug,
  faLeftToLine,
  faRightToLine,
  faLayerGroup,
} from '@fortawesome/pro-regular-svg-icons';
import { PROJECT_ADMIN_PRIVILEGES } from '../../PermissionConstants';
import useUserPrivileges from '@launchnotes/common-hooks/useUserPrivileges';
import { ErrorBoundary } from 'react-error-boundary';
import { SkeletonTag } from '../Layout/SkeletonTag';

const { Sider } = Layout;
const { Text } = Typography;
const { useToken } = theme;
type MenuItem = Required<MenuProps>['items'][number];

const Sidebar = ({ collapsed, setCollapsed }) => {
  const theme = useToken();
  const projectId = useProjectId();
  const project = useProjectContext();
  const user = useUserContext();
  const bouncer = useBouncer();
  const organization = useOrganizationContext();
  const killSession = useKillSession();
  const history = useHistory();
  const { features } = useFeatureFlags([
    AUDIT_LOG,
    DIGESTS,
    FEEDBACK,
    SHAREABLE_VIEWS,
    COHORTS_ROLLOUT,
  ]);

  const { pathname, search } = useLocation();
  const { addTokenToUrl } = useAuthenticatedUrl();
  const privileges = useUserPrivileges(projectId);
  const isAdmin = PROJECT_ADMIN_PRIVILEGES.every((privilege) =>
    privileges?.includes(privilege),
  );

  const userRollupName = useMemo(() => {
    if (isAdmin) {
      return 'Admin';
    }

    if (privileges?.includes('publish:announcement')) {
      return 'Publisher';
    }

    if (privileges?.includes('create:announcement')) {
      return 'Contributor';
    }

    return 'Agent';
  }, [projectId, privileges, isAdmin]);

  const BASE_PATH = `/projects/${projectId}`;

  // Onboarding
  const ONBOARDING_CHECKLIST_PATH = '/onboarding';

  // Dashboard
  const DASHBOARD_PATH = '';

  // Announcements
  const ANNOUNCEMENTS_PATH = '/announcements';
  const ANNOUNCEMENTS_TEMPLATES_PATH = '/announcements/templates';

  // Digest
  const DIGEST_PATH = "/announcements/digests"

  // Roadmap
  const ROADMAP_PATH = features[SHAREABLE_VIEWS] ? '/roadmap/views' : '/roadmap';
  const ROADMAP_ARCHIVED_PATH = '/roadmap/archived';
  const WORK_ITEMS_PATH = `/projects/${projectId}/work_items`;

  // Audience
  const AUDIENCE_PATH = '/audience';
  const SUBSCRIBERS_PATH = `${AUDIENCE_PATH}/subscribers`;
  const DOMAINS_PATH = `${AUDIENCE_PATH}/domains`;
  const COHORTS_PATH = `${AUDIENCE_PATH}/cohorts`;

  // Feedback
  const FEEDBACK_PATH = '/feedback';
  const FEEDBACK_INBOX_PATH = `${FEEDBACK_PATH}/inbox?organizedState=${FeedbackOrganizationState.Unorganized}`;
  const FEEDBACK_IDEAS_PATH = `${FEEDBACK_PATH}/ideas`;
  const FEEDBACK_ARCHIVE_PATH = `${FEEDBACK_PATH}/archive`;
  const FEEDBACK_ARCHIVE_IDEAS_PATH = `${FEEDBACK_ARCHIVE_PATH}/ideas`;

  // Views
  const VIEWS_PATH = '/views';
  const VIEWS_ARCHIVED_PATH = `${VIEWS_PATH}/archived`;

  // Authorization
  const AUTHORIZATION_PATH = '/authorization';

  // User Menu
  const USER_PROFILE_PATH = '/profile';

  // Admin
  const ADMIN_PATH = '/admin';
  const ADMIN_CATEGORIES_PATH = `${ADMIN_PATH}/categories`;
  const ADMIN_CUSTOMIZE_PAGE_PATH = `${ADMIN_PATH}/customize-page`;
  const ADMIN_EMAIL_SETTINGS_PATH = `${ADMIN_PATH}/email-settings`;
  const ADMIN_API_PATH = useMemo(() => {
    const apiEnabled =
      bouncer(FeatureList.API_MANAGE_TOKENS) ||
      bouncer(FeatureList.API_READ_TOKENS);
    const widgetEnabled = bouncer(FeatureList.WIDGET);

    if (!apiEnabled) {
      if (widgetEnabled) return `${ADMIN_PATH}/api/embed`
      else return `${ADMIN_PATH}/api/rss`
    }

    return `${ADMIN_PATH}/api`
  }, [bouncer])
  const ADMIN_FEEDBACK_PATH = `${ADMIN_PATH}/feedback`;
  const ADMIN_ROADMAP_PATH = `${ADMIN_PATH}/roadmap`;
  const ADMIN_VIEWS_PATH = `${ADMIN_PATH}/views`;
  const ADMIN_AUDIT_LOG_PATH = `${ADMIN_PATH}/audit-log`;
  const ADMIN_BILLING_PATH = `${ADMIN_PATH}/billing`;
  const ADMIN_INTEGRATIONS_PATH = `${ADMIN_PATH}/integrations`;
  const ADMIN_SECURITY_PATH = `${ADMIN_PATH}/security`;
  const ADMIN_USERS_PATH = `${ADMIN_PATH}/page-users`;
  const ADMIN_WEBHOOK_PATH = `${ADMIN_PATH}/webhooks`;

  const DASHBOARD_KEY = 'dashboard-key';
  const ROADMAP_GROUP_KEY = 'roadmap-group-key';
  const ROADMAP_ACTIVE_KEY = 'roadmap-active-key';
  const DIGEST_KEY = 'digest-key';

  const apiMenuEnabled = useMemo(() => (
    bouncer(FeatureList.API_MANAGE_TOKENS) ||
    bouncer(FeatureList.API_READ_TOKENS) ||
    bouncer(FeatureList.RSS_FEED) ||
    bouncer(FeatureList.WIDGET)
  ), [bouncer])

  const apiMenuName = useMemo(() => {
    const api = bouncer(FeatureList.API_MANAGE_TOKENS) ||
      bouncer(FeatureList.API_READ_TOKENS)
      ? 'API' : '';
    const embed = bouncer(FeatureList.WIDGET) ? 'Embed' : '';
    const rss = bouncer(FeatureList.RSS_FEED) ? 'RSS' : '';
    return wordsToListAmpersand([api, embed, rss])
  }, [bouncer])

  const feedbackMenuEnabled = useMemo(() => {
    return features[FEEDBACK] &&
      (bouncer(FeatureList.FEEDBACK) || bouncer(FeatureList.IDEAS))
  }, [bouncer, features])

  const feedbackMenuName = useMemo(() => {
    const feedback = bouncer(FeatureList.FEEDBACK) ? 'Feedback' : '';
    const ideas = bouncer(FeatureList.IDEAS) ? 'Ideas' : '';
    return wordsToListAmpersand([feedback, ideas])
  }, [bouncer])

  let openKeys = [];
  let openKeysPreSet = false;
  let selectedKeys = [];
  let selectedKeyIsExact = false;
  let selectedPathDepth = 0;

  // START: Menu selection edge cases
  if (pathname.includes(WORK_ITEMS_PATH)) {
    openKeys = [ROADMAP_GROUP_KEY];
    openKeysPreSet = true;
    selectedKeys = [ROADMAP_ACTIVE_KEY];
    selectedKeyIsExact = true;
  }

  if (pathname.includes('/subscriber-analytics') ||
      pathname.includes('/page-and-widget-analytics') ||
      pathname.includes('/feedback-analytics')) {
    openKeys = [];
    openKeysPreSet = true;
    selectedKeys = [DASHBOARD_KEY];
    selectedKeyIsExact = true;
  }

  if (pathname.includes(DIGEST_PATH)) {
    openKeys = [];
    openKeysPreSet = true;
    selectedKeys = [DIGEST_KEY];
    selectedKeyIsExact = true;
  }
  // END: Menu selection edge cases

  const WORD_MARK_LINK_PATH = projectId
    ? project?.isOnboarding
      ? ONBOARDING_CHECKLIST_PATH
      : DASHBOARD_PATH
    : '/';

  const remainingTime = useMemo(() => {
    if (project.billingStatus !== 'trial') {
      return '';
    }

    const now = moment.utc().startOf('day');
    const trialExpiresIn = moment
      .utc(project?.trialExpirationDatetime)
      .startOf('day')
      .diff(now, 'days');

    if (trialExpiresIn < 1) {
      return <>Trial ends today</>;
    } else if (trialExpiresIn === 1) {
      return <>Trial ends in <strong>1 day</strong></>;
    } else {
      return <>Trial ends in <strong>{trialExpiresIn} days</strong></>;
    }
  }, [project.billingStatus, project?.trialExpirationDatetime]);

  function setSelectedKeys(selectable:boolean, itemKey:string, subject:string, query = '', verifyQuery = false):boolean {
    if (!selectable || selectedKeyIsExact) {
      return;
    }

    const currentPath = pathname.replace(BASE_PATH, '').replace(/\/$/, '');
    if (currentPath == subject && (!verifyQuery || search == query)) {
      selectedKeys = [itemKey];
      selectedKeyIsExact = true;
      return;
    }

    if (subject.length > 0 && currentPath.startsWith(subject) && (!verifyQuery || search == query)) {
      // prefer deeper url match for item selection
      const matchDepth = subject.split('/').length;
      if (matchDepth > selectedPathDepth) {
        selectedKeys = [itemKey];
        selectedKeyIsExact = false;
        selectedPathDepth = matchDepth;
        return;
      }
    }
  }

  function setOpenKeys(itemKey:string, subject:string) {
    if (openKeysPreSet) {
      return;
    }

    const currentPath = pathname.replace(/\/$/, '');
    if (currentPath.startsWith(subject)) {
      openKeys = [itemKey];
    }
  }

  function getDivider(): MenuItem {
    return { type: 'divider' } as MenuItem;
  }

  function getItem(
    label: React.ReactNode,
    url: string,
    icon?: React.ReactNode,
    dataTest?: string,
    key?: string,
    selectable=true,
    verifyQueryInSelection=false,
    style?: React.CSSProperties,
  ): MenuItem {
    const urlParts = url.split('?', 2);
    const basePath = urlParts[0];
    const queryStr = urlParts.length > 1 ? '?' + urlParts[1] : '';

    const itemLink = BASE_PATH + url;
    const itemKey = key ? key : itemLink + '-item';

    setSelectedKeys(selectable, itemKey, basePath, queryStr, verifyQueryInSelection);

    return {
      key: itemKey,
      icon,
      label: <NavLink className={'nav-text'} to={itemLink}>{label}</NavLink>,
      'data-test': dataTest,
      style: style,
    } as MenuItem;
  }

  function getItemGroup(
    label: React.ReactNode,
    url?: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
    dataTest?: string,
    key?: string,
    type?: 'group',
    props: any = {},
  ): MenuItem {
    let itemKey = key;
    let onClick = null;
    if (url) {
      const itemLink = BASE_PATH + url;
      itemKey = key ? key : itemLink + '-group';
      setOpenKeys(itemKey, itemLink);

      onClick = () =>{
        history.push(itemLink);
      };
    }

    return {
      key: itemKey,
      icon,
      children: children,
      label: label,
      type,
      'data-test': dataTest,
      onTitleClick: onClick,
      ...props,
    } as MenuItem;
  }

  function getScriptedItem(
    label: React.ReactNode,
    icon?: React.ReactNode,
    dataTest?: string,
    key?: string,
    onClick?: MenuProps['onClick'],
    style?: React.CSSProperties,
    props: any = {},
  ): MenuItem {
    return {
      id: key,
      key: key,
      icon,
      label: label,
      style: style,
      'data-test': dataTest,
      onClick: onClick,
      ...props,
    } as MenuItem;
  }

  function getExternalItem(label: React.ReactNode, url: string, icon?: React.ReactNode, dataTest?: string, key?: string, props: any = {}): MenuItem {
    return {
      id: key,
      key: key,
      icon,
      label: <a className={'nav-text'} href={url} id={key} target="_blank" rel="noreferrer">{label}</a>,
      type: 'item',
      'data-test': dataTest,
      ...props,
    } as MenuItem;
  }

  const announcementKeys = useMemo(() => {
    var mainItems = [
      getItem('All', ANNOUNCEMENTS_PATH, null, null, null, true, true),
      getItem('Draft', ANNOUNCEMENTS_PATH + '?state=draft', null, null, null, true, true),
      getItem('Published', ANNOUNCEMENTS_PATH + '?state=published', null, null, null, true, true),
      bouncer(FeatureList.TEMPLATES) ? getItem('Templates', ANNOUNCEMENTS_TEMPLATES_PATH) : null,
      getItem('Archived', ANNOUNCEMENTS_PATH + '?state=archived', null, null, null, true, true),
    ]
    return mainItems;
  }, [features]);

  const mainMenuItems: MenuItem[] = [
    bouncer(FeatureList.ANALYTICS) && getItem('Dashboard', DASHBOARD_PATH, <FontAwesomeIcon icon={faGaugeHigh} />, null, DASHBOARD_KEY),

    bouncer(FeatureList.ANNOUNCEMENTS) && getItemGroup('Announcements', ANNOUNCEMENTS_PATH, <FontAwesomeIcon icon={faMegaphone} />, announcementKeys),

    bouncer(FeatureList.DIGESTS, features[DIGESTS]) && getItem('Digests', DIGEST_PATH, <FontAwesomeIcon icon={faLayerGroup} />, null, DIGEST_KEY),

    bouncer(FeatureList.ROADMAP) && getItemGroup(
      'Roadmap',
      ROADMAP_PATH,
      <FontAwesomeIcon icon={faMap} />,
      [
        getItem('Active', ROADMAP_PATH, null, 'sidebar-menu-roadmap-active', ROADMAP_ACTIVE_KEY),
        getItem('Archived', ROADMAP_ARCHIVED_PATH),
      ],
      'sidebar-menu-roadmap',
      ROADMAP_GROUP_KEY,
    ),

    features[SHAREABLE_VIEWS] && getItemGroup(
      <>Views&nbsp;&nbsp;<SkeletonTag darkMode={true} style={{ fontSize: '11px', padding: '0 4px' }}>BETA</SkeletonTag></>,
      VIEWS_PATH,
      <FontAwesomeIcon icon={faTelescope} />,
      [
        getItem('Active', VIEWS_PATH, null, 'sidebar-menu-views-active'),
        getItem('Archived', VIEWS_ARCHIVED_PATH),
      ],
      'sidebar-menu-views',
    ),

    getItemGroup(
      'Audience',
      AUDIENCE_PATH,
      <FontAwesomeIcon icon={faUserGroup} />,
      [
        getItem('Subscribers', SUBSCRIBERS_PATH, null, 'sidebar-menu-subscribers'),
        getItem('Domains', DOMAINS_PATH, null, 'sidebar-menu-domains'),
        features[COHORTS_ROLLOUT] ? getItem('Cohorts', COHORTS_PATH, null, 'sidebar-menu-cohorts') : null,
      ],
      'sidebar-menu-audience',
    ),

    feedbackMenuEnabled && getItemGroup(
      feedbackMenuName,
      FEEDBACK_PATH,
      <FontAwesomeIcon icon={faComments} />,
      [
        bouncer(FeatureList.FEEDBACK) ? getItem('Feedback inbox', FEEDBACK_INBOX_PATH) : null,
        bouncer(FeatureList.IDEAS) ? getItem('Ideas', FEEDBACK_IDEAS_PATH) : null,
        getItem('Archive', bouncer(FeatureList.FEEDBACK) ? FEEDBACK_ARCHIVE_PATH : FEEDBACK_ARCHIVE_IDEAS_PATH),
      ],
      'sidebar-menu-feedback',
    ),

    project?.secured ? getItem('Authorization', AUTHORIZATION_PATH, <FontAwesomeIcon icon={faLock} />, 'sidebar-menu-authorization') : null,

    isAdmin ? getItemGroup(
      'Settings',
      ADMIN_PATH,
      <FontAwesomeIcon icon={faGear} />,
      [
        bouncer(FeatureList.CATEGORIES) ? getItem('Categorization', ADMIN_CATEGORIES_PATH) : null,
        getItem(features[SHAREABLE_VIEWS] ? 'Project' : 'Customize page', ADMIN_CUSTOMIZE_PAGE_PATH, null, 'sidebar-admin-customize-page'),
        bouncer(FeatureList.EMAIL_NOTIFICATIONS) ? getItem('Email settings', ADMIN_EMAIL_SETTINGS_PATH) : null,
        features[FEEDBACK] && feedbackMenuEnabled ? getItem(feedbackMenuName, ADMIN_FEEDBACK_PATH) : null,
        bouncer(FeatureList.ROADMAP) ? getItem('Roadmap', ADMIN_ROADMAP_PATH, null, 'sidebar-admin-roadmap') : null,
        features[SHAREABLE_VIEWS] ? getItem('Views', ADMIN_VIEWS_PATH, null, 'sidebar-admin-views') : null,
        getDivider(),
        apiMenuEnabled ? getItem(apiMenuName, ADMIN_API_PATH) : null,
        bouncer(FeatureList.WEBHOOKS) ? getItem('Webhooks', ADMIN_WEBHOOK_PATH) : null,
        bouncer(FeatureList.AUDIT_LOG) && features[AUDIT_LOG] ? getItem('Audit log', ADMIN_AUDIT_LOG_PATH) : null,
        getItem('Billing & plan', ADMIN_BILLING_PATH),
        getItem('Integrations', ADMIN_INTEGRATIONS_PATH),
        getItem('Security', ADMIN_SECURITY_PATH),
        getItem('Users', ADMIN_USERS_PATH),
      ],
      'sidebar-menu-settings',
    ) : null,

    bouncer(FeatureList.PUBLISHED_PAGE) && getExternalItem(
      'View your page',
      addTokenToUrl(project.publicLink),
      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />,
      'sidebar-menu-view-page'
    ),
  ];

  const avatarMenuChildren: MenuItem[] = [
    getItem(
      <Space direction='horizontal' align='center' style={{marginLeft: '5px'}}>
        <Text strong style={{ color: lnTokens.cosmicDust }}>{user?.displayName}</Text>
        <StateTag
          state={userRollupName}
          style={{marginRight: 0}}
        />
      </Space>,
      USER_PROFILE_PATH,
      <UserAvatar size={22} user={user} />,
      null,
      'user-avatar',
    ),
    getItem(organization.name, USER_PROFILE_PATH, <FontAwesomeIcon icon={faBuilding} />, 'organization-name', 'organization-name'),
    getItem('User preferences', USER_PROFILE_PATH, <FontAwesomeIcon icon={faUserGear} />),

    getDivider(),
    getExternalItem('Product updates', 'https://updates.launchnotes.com', <FontAwesomeIcon icon={faBoxOpen} />),
    getExternalItem('Help center', 'https://help.launchnotes.com', <FontAwesomeIcon icon={faBook} />),
    getExternalItem('API Documentation', 'https://developer.launchnotes.com/index.html', <FontAwesomeIcon icon={faDisplayCode} />),
    getExternalItem('System status', 'https://status.launchnotes.com', <FontAwesomeIcon icon={faWavePulse} />),
    getDivider(),
    getScriptedItem(
      'Log out',
      <FontAwesomeIcon icon={faArrowRightFromBracket} />,
      'logout-item',
      'logout-item',
      () => killSession(),
    ),
  ];

  const bottomMenuItems: MenuItem[] = [
    getDivider(),
    getScriptedItem(collapsed ? 'Expand menu' : 'Collapse menu', <FontAwesomeIcon icon={collapsed ? faRightToLine : faLeftToLine} />, 'collapse-menu', 'collapse-menu', () => setCollapsed(!collapsed)),
    remainingTime != '' ? getItem(
      remainingTime,
      ADMIN_BILLING_PATH,
      <FontAwesomeIcon icon={faCalendar} />,
      'upgrade-cta',
      null,
      false,
    ) : null,
    getScriptedItem('Support', <FontAwesomeIcon icon={faMessageQuestion} />, 'support-toggle', 'support-toggle'),
    getScriptedItem('What\'s new', <FontAwesomeIcon icon={faSparkles} />, 'sidebar-menu-whats-new', 'whats-new-launchnotes'),
    getItemGroup(
      collapsed ? (<></>) : (<Text ellipsis style={{ paddingLeft: '5px', color: lnTokens.cosmicDust }}>{user?.displayName}</Text>),
      null,
      <UserAvatar size={20} gap={3} user={user} tooltip={false} />,
      avatarMenuChildren,
      'sidebar-menu-user-avatar',
      'sidebar-menu-user-avatar',
    ),
  ];

  const StyledMenu = styled(Menu)`
    .ant-menu-item-selected {
      font-weight: 600;
    }

    ${!collapsed && (`
      .ant-menu-item-selected {
        border-left: 4px solid ${lnTokens.titan};
        padding-left: ${lnTokens.mainMenuIndent - 4}px !important;
      }
      .ant-menu-sub > .ant-menu-item-selected {
        padding-left: ${(2 * lnTokens.mainMenuIndent) - 4}px !important;
      }
    `)}
  }
  `;

  const ErrorMenu = (
    <StyledMenu
      mode='vertical'
      selectable={false}
      inlineIndent={lnTokens.mainMenuIndent}
      items={[{
        key: 'error-menu',
        label: 'Error',
        icon: <FontAwesomeIcon icon={faBug} />,
        onClick: () => {
          window.location.reload();
        },
      }]}
      style={{
        border: 'none',
        overflowY: 'auto',
        width: '100%',
        flex: '0 0 auto',
      }}
    />
  );

  return (
    <>
      <Sider
        id='sidebar'
        className={classnames('sidebar', {
          'private-page': project?.secured,
        })}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          marginRight: collapsed ? 60 : 200,
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 500,
        }}
        collapsible
        collapsed={collapsed}
        collapsedWidth={60}
        onCollapse={(value) => setCollapsed(value)}
        trigger={null}
      >
        <Layout
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: theme.token.Layout.siderBg,
          }}
        >
          <NavLink
            to={WORD_MARK_LINK_PATH}
            className='logo-container'
            style={{
              boxSizing: 'border-box',
              padding: '20px 0',
              height: '60px',
              display: 'block',
              marginLeft: collapsed ? '0' : '10px',
              textAlign: collapsed ? 'center' : 'left'
            }}
          >
            {collapsed ? (
              <LogoL
                style={{width: '20px', height: '20px'}}
              />
            ) : (
              <WordMark
                style={{ paddingLeft: '8px', color: lnTokens.astronaut }} />
            )}
          </NavLink>
          {!collapsed && (
            <Row align={'middle'} justify={'center'}>
              <ProjectSelect requestAccess newPage />
            </Row>
          )}

          {project && projectId ? (
            <>
              <ErrorBoundary fallback={ErrorMenu}>
                <StyledMenu
                  defaultSelectedKeys={selectedKeys}
                  defaultOpenKeys={!collapsed && openKeys}
                  mode='inline'
                  selectable={false}
                  inlineIndent={lnTokens.mainMenuIndent}
                  items={mainMenuItems}
                  inlineCollapsed={collapsed}
                  style={{
                    border: 'none',
                    overflowY: 'auto',
                    flex: '1 1 auto',
                  }}
                />
              </ErrorBoundary>
              <ErrorBoundary fallback={ErrorMenu}>

                <StyledMenu
                  mode={collapsed ? 'inline' : 'vertical'}
                  selectable={false}
                  inlineIndent={lnTokens.mainMenuIndent}
                  items={bottomMenuItems}
                  inlineCollapsed={collapsed}
                  triggerSubMenuAction={collapsed ? 'hover' : 'click'}
                  style={{
                    border: 'none',
                    overflowY: 'auto',
                    width: '100%',
                    flex: '0 0 auto',
                  }}
                />
              </ErrorBoundary>
            </>
          ) : (
            <Space>
              <Skeleton paragraph={{ rows: 8, width: '100%' }} />
            </Space>
          )}
        </Layout>
      </Sider>
    </>
  );
};

export default Sidebar;
