import { createContext, Dispatch, SetStateAction, useContext, useEffect } from 'react';

export type Crumb = {
  title: string,
  path?: string,
}

type ContextShape = {
  updateBreadcrumbs: Dispatch<SetStateAction<Crumb[]>>;
}

const defaultContext: ContextShape = {
  updateBreadcrumbs: () => {}
}

const Context = createContext(defaultContext);

export const useBreadcrumbs = (breadcrumbs: Crumb[], dependencies: React.DependencyList = []) => {
  const { updateBreadcrumbs } = useContext(Context);
  useEffect(() => {
    updateBreadcrumbs(breadcrumbs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export default Context;
