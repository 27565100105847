import React, { FC, useMemo } from 'react';
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Space,
  Typography,
} from 'antd';
import { useSearchParams } from '@launchnotes/common-hooks/useSearchParams';
import { AttributeDefinition, Importance, Reaction, useCohortsIndexQuery } from '../../generated/graphql';
import { SmallSecondary, TableFilter } from '../Layout';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faArrowRotateLeft,
  faChevronDown,
  faTrash,
  faCopy,
  faFolder,
} from '@fortawesome/pro-regular-svg-icons'
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import { COHORTS_ROLLOUT, useFeatureFlags } from '../../FeatureFlags';
import FilterDrawer from './FilterDrawer';

const { Search } = Input;
const { Text } = Typography;

export type BulkActionsProps = {
  attributeDefinitions: AttributeDefinition[];
  selectedCount: number;
  totalCount: number;
  archived: boolean;
  organized: boolean;
  organizedState: string;
  reorganize: boolean;
  onArchive: () => void;
  onCopy: () => void;
  onOrganize: () => void;
  onRestore: () => void;
};

export const BulkActions: FC<BulkActionsProps> = ({
  attributeDefinitions = [],
  selectedCount,
  totalCount,
  archived,
  organized,
  organizedState,
  reorganize,
  onArchive,
  onCopy,
  onOrganize,
  onRestore,
}) => {
  const { features } = useFeatureFlags([COHORTS_ROLLOUT]);
  const projectId = useProjectId();
  const { searchParams, updateSearchParams } = useSearchParams<{
    organizedState: string;
    importance: Importance;
    reaction: Reaction;
    cohortId: string;
    search: string;
  }>();

  const { data, isLoading } = useCohortsIndexQuery({
    projectId
  });

  const content = useMemo(() => {
    const filters = [
      {
        flex: '1 1 200px',
        content: selectedCount > 0 && (
          <Space className='group-actions' direction='horizontal'>
            <Dropdown
              overlay={
                <Menu>
                  {reorganize && (
                    <Menu.Item
                      onClick={() => onOrganize()}
                      icon={<FontAwesomeIcon icon={faFolder} />}
                    >
                      Organize
                    </Menu.Item>
                  )}
                  <Menu.Item
                    onClick={() => onCopy()}
                    icon={<FontAwesomeIcon icon={faCopy} />}
                  >
                    Copy emails
                  </Menu.Item>
                  {archived ? (
                    <Menu.Item
                      onClick={() => onRestore()}
                      icon={<FontAwesomeIcon icon={faArrowRotateLeft} />}
                    >
                      Restore
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      onClick={() => onArchive()}
                      danger
                      icon={<FontAwesomeIcon icon={faTrash} />}
                    >
                      Archive
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <Button>
                <Space>
                  Actions
                  <FontAwesomeIcon icon={faChevronDown} />
                </Space>
              </Button>
            </Dropdown>
            <SmallSecondary.Text>
              {selectedCount} of {totalCount} selected
            </SmallSecondary.Text>
          </Space>
        ),
      },
      {
        flex: '0 2 400px',
        content: (
          <Search
            allowClear
            className='searchbar'
            placeholder='Search'
            defaultValue={searchParams.search}
            onSearch={(value) => updateSearchParams({ search: value })}
          />
        ),
      },
      {
        flex: '0 2 100px',
        content: (
          <FilterDrawer attributeDefinitions={attributeDefinitions} />
        )
      }
    ];

    return filters;
  }, [
    selectedCount,
    totalCount,
    archived,
    organized,
    organizedState,
    reorganize,
    onArchive,
    onCopy,
    onOrganize,
    onRestore,
    searchParams,
    updateSearchParams,
    data,
    isLoading,
    features,
  ]);


  return (
    <TableFilter
      style={{ width: '100%'}}
      content={content}
    />
  );
};
