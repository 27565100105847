import React, { FC } from 'react';
import { Empty } from 'antd';

type EmptyTableStateProps = {
  title: string;
}

export const EmptyTableState: FC<EmptyTableStateProps> = ({ title }) => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={title}
    />
  );
};

export const EmptyTableRenderer = (title: string) => {
  // eslint-disable-next-line react/display-name
  return () => <EmptyTableState title={title} />;
};

export default EmptyTableRenderer;