import React, { FC } from 'react';
import { Row, Col, ColProps } from 'antd';

type ContentType = {
    flex: ColProps['flex'];
    content: JSX.Element;
}

interface Props {
  style?: React.CSSProperties; // Allow passing custom style
  content: ContentType[];
}

export const TableFilter: FC<Props> = ({ style, content }) => {
  const defaultStyle: React.CSSProperties = {
    paddingBottom: 16,
    marginTop: 0,
    marginBottom: 0
  };

  // Merge default style with custom style, if provided
  const mergedStyle = { ...defaultStyle, ...style };

  return (        
  <Row className='filters' justify='end' align={'top'} gutter={[16, 16]} style={mergedStyle}>
    {content.map((element, index) => (
        <Col key={index} flex={element.flex} sm={24} >
            {element.content}
        </Col>
        )
    )}
  </Row>
)
};