import React from "react";
import Icon from "@ant-design/icons";

const Svg = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 9 14.2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#EF4444"
      d="M8.8,13C8.9,13.1,9,13.3,9,13.5c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2
l-3.2-3.2L1.3,14c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2C0.1,13.9,0,13.7,0,13.5c0-0.2,0.1-0.4,0.2-0.5L4,9.2
C4,9.1,4.1,9.1,4.2,9C4.3,9,4.4,9,4.5,9S4.7,9,4.8,9C4.9,9.1,5,9.1,5,9.2L8.8,13z M0.2,9.5C0.1,9.4,0,9.2,0,9
c0-0.2,0.1-0.4,0.2-0.5L4,4.7C4,4.6,4.1,4.6,4.2,4.5c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1C4.9,4.6,5,4.6,5,4.7l3.8,3.8
C8.9,8.6,9,8.8,9,9c0,0.2-0.1,0.4-0.2,0.5C8.6,9.7,8.4,9.7,8.2,9.7c-0.2,0-0.4-0.1-0.5-0.2L4.5,6.3L1.3,9.5
C1.1,9.7,0.9,9.7,0.7,9.7C0.5,9.7,0.4,9.7,0.2,9.5z M0.2,5C0.1,4.9,0,4.7,0,4.5S0.1,4.1,0.2,4L4,0.2C4,0.1,4.1,0.1,4.2,0.1
C4.3,0,4.4,0,4.5,0c0.1,0,0.2,0,0.3,0.1C4.9,0.1,5,0.1,5,0.2L8.8,4C8.9,4.1,9,4.3,9,4.5S8.9,4.9,8.8,5C8.6,5.2,8.4,5.2,8.2,5.2
C8.1,5.2,7.9,5.2,7.7,5L4.5,1.8L1.3,5C1.1,5.2,0.9,5.2,0.7,5.2C0.5,5.2,0.4,5.2,0.2,5z"
    />
  </svg>
);

const SelectedSvg = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 9 14.2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#E66A47"
      d="M8.8,13C8.9,13.1,9,13.3,9,13.5c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2
l-3.2-3.2L1.3,14c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2C0.1,13.9,0,13.7,0,13.5c0-0.2,0.1-0.4,0.2-0.5L4,9.2
C4,9.1,4.1,9.1,4.2,9C4.3,9,4.4,9,4.5,9S4.7,9,4.8,9C4.9,9.1,5,9.1,5,9.2L8.8,13z M0.2,9.5C0.1,9.4,0,9.2,0,9
c0-0.2,0.1-0.4,0.2-0.5L4,4.7C4,4.6,4.1,4.6,4.2,4.5c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1C4.9,4.6,5,4.6,5,4.7l3.8,3.8
C8.9,8.6,9,8.8,9,9c0,0.2-0.1,0.4-0.2,0.5C8.6,9.7,8.4,9.7,8.2,9.7c-0.2,0-0.4-0.1-0.5-0.2L4.5,6.3L1.3,9.5
C1.1,9.7,0.9,9.7,0.7,9.7C0.5,9.7,0.4,9.7,0.2,9.5z M0.2,5C0.1,4.9,0,4.7,0,4.5S0.1,4.1,0.2,4L4,0.2C4,0.1,4.1,0.1,4.2,0.1
C4.3,0,4.4,0,4.5,0c0.1,0,0.2,0,0.3,0.1C4.9,0.1,5,0.1,5,0.2L8.8,4C8.9,4.1,9,4.3,9,4.5S8.9,4.9,8.8,5C8.6,5.2,8.4,5.2,8.2,5.2
C8.1,5.2,7.9,5.2,7.7,5L4.5,1.8L1.3,5C1.1,5.2,0.9,5.2,0.7,5.2C0.5,5.2,0.4,5.2,0.2,5z"
    />
  </svg>
);

const HighImportance = (props: any) => <Icon component={Svg} {...props} />;
const SelectedHighImportance = (props: any) => (
  <Icon component={SelectedSvg} {...props} />
);

export default HighImportance;
