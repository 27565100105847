import React, { FC, useState, useMemo } from 'react';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import { usePagination } from '../SuperTable/Pagination';
import { MinimalTable } from '../SuperTable';
import type { ColumnsType } from 'antd/es/table';
import {
  useSubscriberVotesQuery,
  IdeaVote,
  SortEnum,
  InputMaybe,
  OrderBy,
} from '../../generated/graphql';
import { Typography } from 'antd';
import EmptyTableRenderer from '../Domains/EmptyTableState';
import { Key, SortOrder } from 'antd/lib/table/interface';
import Time from '../Time';

type TableState = {
  orderBy?: {
    field: string | Key | undefined,
    sort: SortOrder
  }
};

const getSortDirection = (sortDirection?: SortEnum) => {
  if (!sortDirection) {
    return null;
  }

  return sortDirection === SortEnum.Asc ? 'ascend' : 'descend';
};

const SubscriberVotesTable: FC<{ id: string }> = ({ id }) => {
  const projectId = useProjectId();

  const [tableState, updateTableState] = useState<TableState>();

  const { previousPage, nextPage, updatePageSize, first, before, after } = usePagination();

  let orderBy: InputMaybe<OrderBy> | undefined;

  if (tableState?.orderBy?.field) {
    orderBy = {
      field: tableState.orderBy.field.toString(),
      sort: tableState.orderBy.sort === 'descend' ? SortEnum.Desc : SortEnum.Asc,
    };
  }

  const { data, isLoading } = useSubscriberVotesQuery({
    id,
    projectId,
    first,
    before,
    after,
    orderBy: orderBy || { field: 'createdAt', sort: SortEnum.Desc },
  });

  const columns: ColumnsType<IdeaVote> =  useMemo(() => [
    {
      title: 'Idea',
      dataIndex: ['id'],
      width: 800,
      key: 'id',
      render: (_, { idea: { name, privatePermalink }}) => {
        return <Typography.Link target="_blank" href={privatePermalink}>{name}</Typography.Link>;
      },
    },
    {
      title: 'Date Voted',
      dataIndex: ['createdAt'],
      key: 'createdAt',
      sorter: true,
      width: '30%',
      sortOrder: orderBy?.field === 'createdAt' ? getSortDirection(orderBy.sort) : null,
      render: (value: string) => <Time timestamp={value} />,
    },
  ], [orderBy]);

  const VotesTable = MinimalTable<IdeaVote>();

  let subscriber;
  if (data?.project?.subscribers?.nodes && data?.project?.subscribers.nodes.length > 0) {
    subscriber = data?.project?.subscribers?.nodes[0];
  }

  return (
    <VotesTable
      data={subscriber?.ideaVotes.nodes || []}
      columns={columns}
      pageInfo={subscriber?.ideaVotes.pageInfo}
      first={first}
      nextPage={nextPage}
      previousPage={previousPage}
      updatePageSize={updatePageSize}
      isLoading={isLoading}
      totalCount={subscriber?.ideaVotes.totalCount}
      emptyState={EmptyTableRenderer('No Votes')}
      rowKey={({ idea }) => idea.id as string}
      bordered
      onChange={(_, __, sorting) => {
        let field, sort;
        if (Array.isArray(sorting)) {
          sort = sorting[0].order;
          field = sorting[0].columnKey;
        } else {
          sort = sorting.order;
          field = sorting.columnKey;
        }

        if (sort === undefined) {
          updateTableState({
            ...tableState,
            orderBy: undefined,
          });
        } else {
          updateTableState({
            ...tableState,
            orderBy: { field, sort },
          });
        }
      }}
    />
  );
};

export default SubscriberVotesTable;