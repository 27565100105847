import {
  Button,
  Dropdown,
  Menu,
  Space,
  Typography,
} from 'antd';
import React, {
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/pro-regular-svg-icons'
import { PageInfo } from '../../../generated/graphql';

type PaginationState = {
  after?: string
  before? : string
}

export const usePagination = (args?: { pageSize?: number }) => {
  const pageSize = args?.pageSize ? args.pageSize : 25;
  const [first, updatePageSize] = useState<number>(pageSize);
  const [{ before, after }, updatePaginationState] = useState<PaginationState>({ after: undefined, before: undefined });

  const resetPage = useCallback(() => {
    updatePaginationState({ before: undefined, after: undefined });
  }, []);

  const previousPage = useCallback((startCursor?: string | null) => {
    updatePaginationState({
      before: startCursor as string,
      after: undefined,
    });
  }, []);

  const nextPage = useCallback((endCursor?: string | null) => {
    updatePaginationState({
      before: undefined,
      after: endCursor as string,
    });
  }, []);

  return {
    previousPage,
    nextPage,
    updatePageSize,
    resetPage,
    first,
    before,
    after,
  };
};

type PaginationProps = {
  pageInfo?: PageInfo
  totalCount: number
  pageSize: number
  onNext: () => void
  onPrevious: () => void
  onUpdatePageSize: (n: number) => void
  showSizeMenu?: boolean
}

const Pagination: FC<PaginationProps> = ({
  pageInfo,
  totalCount,
  pageSize,
  onNext,
  onPrevious,
  onUpdatePageSize,
  showSizeMenu =  true
}) => {
  const paginationMenu = useMemo(() => (
    <Menu>
      <Menu.Item key={25} onClick={() => onUpdatePageSize(25)}>25 / page</Menu.Item>
      <Menu.Item key={50} onClick={() => onUpdatePageSize(50)}>50 / page</Menu.Item>
      <Menu.Item key={100} onClick={() => onUpdatePageSize(100)}>100 / page</Menu.Item>
    </Menu>
  ), [onUpdatePageSize]);

  if (totalCount < pageSize) return null;

  return (
    <Space style={{paddingTop: '16px'}}>
      <Typography.Text type="secondary"><>{totalCount} total records</></Typography.Text>

      { showSizeMenu && (
        <Dropdown overlay={paginationMenu} trigger={['click']}>
          <Button>
            <>
              {pageSize} / Page
            </>
          </Button>
        </Dropdown>
      )}
      
      <Previous
        onClick={onPrevious}
        disabled={!pageInfo?.hasPreviousPage}
      />

      <Next
        onClick={onNext}
        disabled={!pageInfo?.hasNextPage}
      />
    </Space>
  );

};

type ButtonProps = {
  disabled?: boolean
  onClick: () => void
}

const Previous: FC<ButtonProps> = ({ disabled, onClick }) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </Button>
  );
};

const Next: FC<ButtonProps> = ({ disabled, onClick }) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </Button>
  );
};

export default Pagination;