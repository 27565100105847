import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useProjectContext } from '../../../../AppContext';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import { OnboardingTasksQuery, UpdateOnboardingTasksAttributes, useOnboardingTasksQuery, useUpdateOnboardingTasksMutation } from '../../../../generated/graphql';

export const useUpdateOnboardingTasks = () => {
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const project = useProjectContext();

  const { mutate } = useUpdateOnboardingTasksMutation({
    onMutate: async ({ onboardingTasks }) => {
      const queryKey = useOnboardingTasksQuery.getKey({ projectId });
      await queryClient.cancelQueries(queryKey);
      const previousQueryData = queryClient.getQueryData(queryKey);
      queryClient.setQueryData<OnboardingTasksQuery | undefined>(queryKey, (queryData) => {
        if (!queryData) return;
        return {
          ...queryData,
          viewer: {
            ...queryData.viewer,
            project: {
              ...queryData.viewer.project,
              onboardingTasks: {
                ...queryData.viewer.project.onboardingTasks,
                ...onboardingTasks,
              },
            },
          },
        };
      });
      return { previousQueryData };
    },
    onError: (e, _, context) => {
      console.error(e);
      const queryKey = useOnboardingTasksQuery.getKey({ projectId });
      queryClient.setQueryData(queryKey, context?.previousQueryData);
    },
  });

  const updateOnboardingTasks = useCallback((onboardingTasks: UpdateOnboardingTasksAttributes) => {
    if (project?.isOnboarding) {
      mutate({ projectId, onboardingTasks });
    }
  }, [mutate, project?.isOnboarding, projectId]);

  return [updateOnboardingTasks];
};
