import { useCallback } from 'react';
import { useAccessibleFeatures } from '../AppContext';

import {
  BOUNCER,
  useFeatureFlags,
} from '../FeatureFlags';

// TODO: https://linear.app/launchnotes/issue/LN-7028/implement-all-feature-gates
const FeatureList = {
  AI_WRITER: "ai_writer",
  ANALYTICS: "analytics",
  ANNOUNCEMENTS: "announcements",
  API_MANAGE_TOKENS: "api_manage_tokens",
  API_READ_TOKENS: "api_read_tokens",
  AUDIT_LOG: "audit_log",
  BASIC_CUSTOMIZATION: "basic_customization",
  CATEGORIES: "categories",
  CHANGE_TYPES: "change_types",
  COPY_TO_PROJECT: "copy_to_project",
  CUSTOM_CSS_HTML: "custom_css_html",
  CUSTOM_EMPTY_STATES: "custom_empty_states",
  CUSTOM_ESP: "custom_esp",
  CUSTOM_FISCAL_YEAR: "custom_fiscal_year",
  CUSTOMIZE_AUTHOR: "customize_author",
  CUSTOMIZE_CTA: "customize_cta",
  DIGESTS: "digests",
  EMAIL_NOTIFICATIONS: "email_notifications",
  EXCERPT_NOTIFICATIONS: "excerpt_notifications",
  FEEDBACK: "feedback",
  IDEAS: "ideas",
  IDEA_PROMOTION: "idea_promotion",
  IMPORT_FEEDBACK_IDEAS: "import_feedback_ideas",
  LINKED_ITEMS: "linked_items",
  LN_ESP: "ln_esp",
  LOOM_ANNOUNCEMENTS: "loom_announcements",
  PRIVACY_POLICY_TOC: "privacy_policy_toc",
  PUBLISHED_PAGE: "published_page",
  RECORD_FOR_SUBSCRIBER: "record_for_subscriber",
  ROADMAP: "roadmap",
  RSS_FEED: "rss_feed",
  SCHEDULE: "schedule",
  SLACK_NOTIFICATIONS: "slack_notifications",
  STAGE_CHANGE_NOTIFICATIONS: "stage_change_notifications",
  SUB_CATEGORIES: "sub_categories",
  SUBSCRIBER_EXPORT: "subscriber_export",
  SUBSCRIBER_IMPORT: "subscriber_import",
  TEMPLATES: "templates",
  UNPUBLISHED_STAGES: "unpublished_stages",
  VOTING: "voting",
  WEBHOOKS: "webhooks",
  WIDGET: "widget"
}

const useBouncer = () => {
  const { features: enabled } = useFeatureFlags([BOUNCER]);

  const features = useAccessibleFeatures()

  const hasAccess = useCallback((feature: string, flagEnabled: boolean = true) => {
    // Until we roll out the bouncer, we should rely on the feature flag being enabled
    // since it defaults to true, we return true if we encounter bouncer without a flag
    if (!enabled[BOUNCER]) {
      return flagEnabled;
    }

    // If the flag is not on yet, we don't want to display the content
    if (!flagEnabled) {
      return false;
    }

    return features.includes(feature);
  }, [features, enabled]);

  return hasAccess;
}

export { FeatureList }

export default useBouncer;
