import React, { ReactNode, useEffect, useState } from 'react';
import LoadingTakeover from '../components/Loading/Takeover';
import useUpdateUserContext from '../hooks/useUpdateUserContext';
import useProjectId from '../hooks/useProjectId';
import { ContextDataShape, useProjectContext } from '../AppContext';
import { environment } from '../index';
import { usePostHog } from 'posthog-js/react';
import { useOrganizationContext } from '../AppContext/index.tsx';

const UserContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const projectId = useProjectId();
  const posthog = usePostHog();
  const organization = useOrganizationContext()
  const project = useProjectContext();
  const { updateContext } = useUpdateUserContext();
  const [contextSet, updateContextSet] = useState(false);
  const [contextLoading, updateContextLoading] = useState(false);

  useEffect(() => {
    if (contextLoading) return;
    if (!contextSet) {
      updateContextLoading(true);
      updateContext(projectId)
        .then((payload) => {
          updateContextSet(true);
          updateContextLoading(false);

          if (!payload) return;
          const { user, organization, project } = payload as ContextDataShape;

          if (user) {
            /* eslint-disable camelcase */
            (window as Window).Intercom('boot', {
              app_id: (window as Window).INTERCOM_APP_ID,
              email: user.email,
              name: user.name,
              user_id: user.id,
              user_hash: user.intercomIdentityHash,
              avatar: {
                type: 'avatar',
                image_url: user.avatarImage.url,
              },
              project_id: project.id,
              public_project_url: project.publicLink,
              stripe_id: project.stripeId,
              organization_id: organization?.id,
              organization_name: organization?.name,
            });
            /* eslint-enable camelcase */
          }

          if (environment === 'production') {
            if (user) {
              posthog.identify(user.id, {
                email: user.email,
                /* eslint-disable camelcase */
                project_id: project?.id,
                organization_id: project?.organizationId,
                active_tier: project?.activeTier,
                /* eslint-enable camelcase */
              });
            }

            if (project) {
              posthog.register({
                /* eslint-disable camelcase */
                project_id: project.id,
                secure_project: project.secured,
                organization_id: project.organizationId,
                active_tier: project.activeTier,
                /* eslint-enable camelcase */
              });

              posthog.group('Project', project.id, {
                name: project.name,
                /* eslint-disable camelcase */
                secure_project: project.secured,
                active_tier: project.activeTier,
                billing_state: project.billingStatus,
                /* eslint-enable camelcase */
              });
            }

            if (organization) {
              posthog.group('Organization', organization.id);
            }
          }
        })
        .catch(() => { /* TODO */ });
    } else if (project && projectId && project.id !== projectId) {
      updateContextLoading(true);
      updateContext(projectId).then(() => updateContextLoading(false));
    }
  }, [contextLoading, contextSet, posthog, project, projectId, updateContext]);

  return !contextSet ? (
    <LoadingTakeover title="Prepping your seat on the rocket..." />
  ) : contextLoading || (project && projectId && project.id !== projectId) ? (
    <LoadingTakeover title="Switching projects..." />
  ) : (
    <>
      {children}
    </>
  );
};

export default UserContextProvider;
