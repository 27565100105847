import { useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import {
  CreateIdeaMutation,
  useCreateIdeaMutation,
} from '../../../generated/graphql';

export const useCreateIdea = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (
    idea: NonNullable<CreateIdeaMutation['createIdea']>['idea']
  ) => void;
  onError?: (e?: Error) => void;
} = {}) => {
  const queryClient = useQueryClient();

  const { mutate } = useCreateIdeaMutation({
    onSuccess({ createIdea }) {
      queryClient.invalidateQueries({
        queryKey: ['IdeasTable.infinite']
      });
      message.success('Idea successfully created');
      onSuccess?.(createIdea?.idea);
    },
    onError(e?: Error) {
      message.error(`Idea could not be created ${e?.message}`);
      onError?.(e);
    },
  });

  return { createIdea: mutate };
};
