import React from 'react';
import {ColumnType} from 'antd/lib/table';
import { CompareFn } from 'antd/lib/table/interface';
import Time from '../../Time';
import { SortEnum } from '../../../generated/graphql';

const getSortDirection = (sortDirection?: SortEnum) => {
  if (!sortDirection) {
    return null;
  }

  return sortDirection === SortEnum.Asc ? 'ascend' : 'descend';
};

/**
 * General date time column
 * @param title
 * @param dataIndex
 * @param key
 */
const dateTime = <T,>(title: string, dataIndex: string[], key: string, options?: {
  sorter?: CompareFn<T>,
  sortOrder?: SortEnum,
  width?: string | number,
}): ColumnType<T> => {
  return {
    title,
    dataIndex,
    key,
    sorter: options?.sorter || true,
    sortOrder: getSortDirection(options?.sortOrder),
    ellipsis: true,
    width: options?.width ? options?.width : 180,
    render: (value: string) => <Time timestamp={value} />,
  };
};

export default dateTime;