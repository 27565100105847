import React from 'react';
import { Tag } from 'antd';

const StateTag = ({ state, style }: { state: string, style?:React.CSSProperties }) => {
  const className = React.useMemo(() => {
    switch (state) {
    case 'published':
      return 'success';
    case 'draft':
      return 'processing';
    case 'scheduled':
      return 'warning';
    case 'archived':
      return 'error';
    case 'pending':
      return 'warning';
    case 'unpublished':
      return 'processing';
    default:
      return 'default';
    }
  }, [state]);

  return (
    <Tag color={className} style={{ marginTop: 0, ...style}}>{state.charAt(0).toUpperCase() + state.slice(1)}</Tag>
  );
};

export default StateTag;
