import React, { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { SubscriberExportShape } from '@launchnotes/pages/Subscribers';
import {
  Button,
  Checkbox,
  Input,
  Modal,
  Space,
  Spin,
  Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { ExportFeedbackQuery, ExportSubscribersQuery } from '../../generated/graphql';
import { FeedbackExportShape } from '@launchnotes/pages/Feedback/FeedbackInbox';

const { Text } = Typography;

type Props = {
  defaultFilename: string;
  exportData: SubscriberExportShape[] | FeedbackExportShape[];
  title?: string;
  type: "subscriber" | "feedback";
  queryTrigger: (options?: RefetchOptions) => Promise<QueryObserverResult<ExportSubscribersQuery | ExportFeedbackQuery, unknown>>;
}

const ExportLink:React.FC<Props> = ({ defaultFilename, exportData, title, type, queryTrigger }) => {
  const [exportFilename, updateExportFilename] = useState<string>(defaultFilename);
  const [modalVisible, updateModalVisible] = useState<boolean>(false);
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);

  const data = useMemo(() => {
    if (type === "subscriber") return exportData;

    // Just making typescript happy
    const feedbackData = exportData as FeedbackExportShape[]

    return feedbackData.filter((datum: FeedbackExportShape) => {
      // If this is not archived, we always include it
      // If it was archived, return whether we want to include archived
      return !datum.archived || includeArchived;
    })
  }, [includeArchived, exportData]);

  const handleExportClick = () => {
    queryTrigger();
    updateModalVisible(true);
  };

  return (<>
    <Button type="link" className='text-button' onClick={() => handleExportClick()}>
      Export
    </Button>
    <Modal
      title={title || 'Export subscribers'}
      open={modalVisible}
      footer={null}
      onCancel={() => updateModalVisible(false)}
    >
      <Space direction='vertical' style={{ width: '100%' }}>
        <Text>Export filename</Text>
        <Input
          defaultValue={exportFilename}
          placeholder='Export file name'
          onChange={(e) => updateExportFilename(e.target.value)}
        />

        { type === 'feedback' && (
          <Checkbox onChange={(e: CheckboxChangeEvent) => { setIncludeArchived(e.target.checked) }}>
            Include archived feedback
          </Checkbox>
        )}

        <Space className="submit-export" align="center">
          {exportData ?
            <CSVLink
              filename={exportFilename}
              data={data}
              onClick={() => {
                updateModalVisible(false);
              }}
            >
              <Button
                type="primary"
                style={{ marginTop: '1rem' }}
              >
                Download CSV
              </Button>
            </CSVLink>
            :
            <Spin tip="Preparing download..."/>
          }
        </Space>
      </Space>
    </Modal>
  </>);
};

export default ExportLink;
