import React, { FC, PropsWithChildren } from 'react';
import { Form, FormItemProps } from "antd";

type FilterItemProps = {
  label: string;
}

const FilterItem: FC<PropsWithChildren<FilterItemProps | FormItemProps>> = ({ label, children, ...rest }) => {
  return (
    <Form.Item label={label} {...rest}>
      {children}
    </Form.Item>
  );
}

export default FilterItem;
