import React, { FC, useState } from 'react';
import useBouncer, { FeatureList } from '@launchnotes/common-hooks/useBouncer';
import useProjectId from '@launchnotes/common-hooks/useProjectId';
import {
  useSubscriberDrawerShowQuery,
  SubscriberEdge,
} from '../../generated/graphql';
import {
  Divider,
  Drawer,
  Tabs,
} from 'antd';
import { Portal } from 'react-portal';

import SubscriberRoadmapItemsTable from './SubscriberRoadmapItemsTable';
import SubscriberVotesTable from './SubscriberVotesTable';
import SubscriberFeedbackTable from './SubscriberFeedbackTable';
import RecentNotificationsTable from './RecentNotificationsTable';
import SubscriberHeader from './SubscriberHeader';

export const useSubscriberDrawer = () => {
  const [visibleSubscriberID, updateVisible] = useState<string>();

  return {
    subscriberDrawer: visibleSubscriberID && <Portal><DetailDrawer id={visibleSubscriberID} onClose={() => updateVisible(undefined)} /></Portal>,
    showSubscriberDrawer: updateVisible,
  };
};

const DetailDrawer: FC<{ id: string, onClose: () => void }> = ({ id, onClose }) => {
  const projectId = useProjectId();
  const bouncer = useBouncer()
  const { data, isLoading } = useSubscriberDrawerShowQuery({ id, projectId });
  const [visible, updateVisible] = useState(true);
  const [tab, updateTab] = useState('1');

  const edges = data?.project?.subscribers?.edges;

  if (!edges) return null;

  const subscriber = edges[0] as SubscriberEdge;

  if (!subscriber) return null;

  return (
    <Drawer
      placement="right"
      size="large"
      onClose={() => {
        updateVisible(false);
        onClose();
      }}
      open={visible}
      title={"Subscriber"}
    >
      <SubscriberHeader subscriber={subscriber} isLoading={isLoading} />
      <>
        <Divider />

        <Tabs defaultActiveKey={tab} onChange={updateTab}>
          <Tabs.TabPane tab="Recent activity" key="1">
            <RecentNotificationsTable id={id} />
          </Tabs.TabPane>
          { bouncer(FeatureList.FEEDBACK) &&
            <Tabs.TabPane tab={`Feedback (${subscriber?.node.feedbacks?.totalCount})`} key="2">
              <SubscriberFeedbackTable id={id} />
            </Tabs.TabPane>
          }
          { bouncer(FeatureList.IDEAS) && bouncer(FeatureList.VOTING) &&
            <Tabs.TabPane tab={`Votes (${subscriber?.node.ideaVotes?.totalCount})`} key="3">
              <SubscriberVotesTable id={id} />
            </Tabs.TabPane>
          }
          { bouncer(FeatureList.ROADMAP) &&
            <Tabs.TabPane tab={`Roadmap (${subscriber?.node.workItems?.totalCount})`} key="4">
              <SubscriberRoadmapItemsTable id={id}/>
            </Tabs.TabPane>
          }
        </Tabs>
      </>
    </Drawer>
  );
};

export default DetailDrawer;
