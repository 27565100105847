import React, { useMemo } from "react";
import { Button, Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";
import { WizardSubscribersStepFragment } from '../../../generated/graphql';
const { Text, Paragraph } = Typography;

type Props = {
  project: WizardSubscribersStepFragment;
};

const SubscribersStep = (props: Props) => {
  const project: WizardSubscribersStepFragment = props.project;
  const previousUrl = useMemo(() => `/projects/${project.id}/welcome/categories`, [project]);
  const nextUrl = useMemo(() => `/projects/${project.id}/welcome/announcement`, [project]);

  return (
    <>
      <Row>
        <Col className="onboarding-fields subscribers">
          <Paragraph>
            Your customers can subscribe (or you can subscribe them) to get notified when you announce changes.
          </Paragraph>
          <Paragraph>
            <Text strong>Go ahead and click that subscribe button in the top right and subscribe yourself to your page.</Text>
          </Paragraph>
        </Col>
      </Row>
      <Row justify="space-between" className="buttons-container">
        <Col>
          <Link to={previousUrl}>
            <Button size="small" type="text">Back</Button>
          </Link>
        </Col>
        <Col>
          <Link to={nextUrl}>
            <Button
              size="large"
              type="primary"
              disabled={!project.emailProjectSubscription }
            >
              Next
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col className="step-progress">4/5</Col>
      </Row>
    </>
  );
};

export default SubscribersStep;
