import React, {
  ReactNode,
  createContext,
  useState,
  useEffect,
} from 'react';
import useSessionToken from '../hooks/useSessionToken';
import BACKEND_HOST from '../API_URL';
import { useSearchParams } from '@launchnotes/common-hooks/useSearchParams';

// This is the new world replacement for <ProviderAuthenticated />,
// responsible for fetching and storing the token from the GOAT.
type TokenType = string | undefined;

type TokenContextType = {
  token: TokenType;
  clearToken: () => void;
};

export const TokenContext = createContext<TokenContextType | undefined>(undefined);

const TokenAuthenticated = ({ children }: { children: ReactNode }): JSX.Element => {
  const { searchParams, updateSearchParams } = useSearchParams<{
    token: string;
  }>();
  const queryToken = searchParams.token;

  const { fetchLocalToken, setLocalToken } = useSessionToken();
  const localToken = fetchLocalToken();

  const initialToken:TokenType = queryToken || localToken || undefined;
  const [token, setToken] = useState<TokenType>(initialToken);
  setLocalToken(initialToken);

  const initiateLogin = () => {
    const loginUrl = `${BACKEND_HOST}/users/auth/workos/authenticate?original_uri=${window.location.href}`;
    window.location.href = loginUrl;
  };

  const getToken = () => {
    if (queryToken) {
      setToken(queryToken);
      setLocalToken(queryToken);
    } else if (localToken) {
      setToken(localToken);
    }
  };

  const refreshToken = async () => {
    const url = `${BACKEND_HOST}/users/auth/refresh`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();

        if (data.newToken) {
          setToken(data.newToken);
          setLocalToken(data.newToken);
        } else {
          console.error('Token refresh did not return a new token.');
        }
      } else {
        console.error(`Error refreshing token: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error during token refresh: ${error}`);
    }
  };


  const clearToken = () => {
    setLocalToken(undefined);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refreshToken();
    }, 900000); // Refresh every 15 minutes

    return () => clearInterval(interval);
  }, [refreshToken]);

  useEffect(() => {
    if (searchParams && searchParams.token) {
      updateSearchParams({ token: '' });
    }

    if (token) return;

    getToken();
  }, [getToken, searchParams, token, updateSearchParams]);


  if (token) {
    return <TokenContext.Provider value={{ token, clearToken }}>
      {children}
    </TokenContext.Provider>;
  } else {
    initiateLogin();
  }
};

export default TokenAuthenticated;
