import React, { FC } from 'react';
import { Card as AntCard, CardProps } from 'antd';

interface Props extends CardProps {
  title?: React.ReactNode;
  className?: string;
  extra?: JSX.Element;
  children: React.ReactNode;
  style?: React.CSSProperties; // Allow passing custom style
  bodyStyle?: React.CSSProperties; // Allow passing custom style
  hoverable?: boolean
}

export const Card: FC<Props> = ({
  children,
  style,
  bodyStyle,
  title,
  extra,
  className,
  bordered,
  type,
  hoverable
}) => {
  return (
    <AntCard
      className={className}
      style={style}
      title={title}
      extra={extra}
      type={type}
      hoverable={hoverable}
      headStyle={{ borderBottom: 'none', paddingTop: 16 }}
      bordered={bordered}
      bodyStyle={bodyStyle}
    >
      {children}
    </AntCard>
  );
};
