import React, { useMemo, useEffect } from 'react';
import TokenAuthenticated from './TokenAuthenticated';
import UserContextProvider from './UserContextProvider';
import ApplicationRouter from '../Router';
import { useHistory } from 'react-router-dom';
import { environment } from '../index';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CommandK from '@launchnotes/components/CommandK';

const POSTHOG_API_KEY_MAP = {
  development: 'phc_Lv5e7TTI9Yfs27SxNSGqcDq1b4ZexODVuIXXXjvOMOv',
  preview: 'phc_Wqry08U5hUWMLR82ftPvpWFZp8Sj1MpKvVnIP1SvcEq',
  production: 'phc_UlHlA3tIQlE89WRH9NSy0MzlOg1XYiUXnXiYjKBJ4OT',
};

const POSTHOG_HOST_MAP = {
  development: 'https://app.posthog.com',
  preview: 'https://app.posthog.com',
  production: 'https://a.launchnotes.com',
};

/* eslint-disable @typescript-eslint/no-explicit-any */
let prevPath: string | null = null;
const Authenticated = () => {
  const history = useHistory();
  if (environment === 'production') {
    history.listen((location) => {
      if (location.pathname !== prevPath) {
        prevPath = location.pathname;
      }
    });
  }

  useEffect(() => {
    if (environment !== 'development') {
      posthog.init(POSTHOG_API_KEY_MAP[environment], {
        // eslint-disable-next-line camelcase
        api_host: POSTHOG_HOST_MAP[environment],
      });
    }
  }, []);

  const queryClient = useMemo(() => new QueryClient(), []);
  const cypress = (window as Window).Cypress;

  return (
    <TokenAuthenticated>
      <QueryClientProvider client={queryClient}>
        <PostHogProvider client={posthog}>
          <UserContextProvider>
            <CommandK />
            <ApplicationRouter />
          </UserContextProvider>
        </PostHogProvider>
        {!cypress && <ReactQueryDevtools initialIsOpen={true} position='bottom' />}
      </QueryClientProvider>
    </TokenAuthenticated>
  );
};

export default Authenticated;
