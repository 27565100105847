import React, { FC } from 'react';
import { HasEvent, HasNotification, HasEventObject, HasFeedback } from './types';
import { Tooltip, Typography, theme } from 'antd';
import chroma from 'chroma-js';
import MessageIcon from '@launchnotes/icons/MessageIcon';
import Unvote from '@launchnotes/icons/Unvote';
import FontAwesomeIcon from '@launchnotes/icons/FontAwesomeIcon'
import {
  faBell,
  faBellSlash,
  faEnvelope,
  faHeart,
  faUser,
  faUserCheck,
  faUserMinus,
  faUserPlus,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faSlack
} from '@fortawesome/free-brands-svg-icons'

const { useToken } = theme;

const ProjectSubscriptionIndicator: FC<HasEvent> = ({event}) => {
  switch(event.eventType) {
  case 'project_subscription.created':
  case 'project_subscription.resubscribed':
    return <FontAwesomeIcon icon={faUserPlus} />;
  case 'project_subscription.confirmed':
    return <FontAwesomeIcon icon={faUserCheck} />;
  case 'project_subscription.unsubscribed':
    return <FontAwesomeIcon icon={faUserMinus} />;
  default:
    return <FontAwesomeIcon icon={faUser} />;
  }
};

const EventIcon: FC<HasEvent & HasEventObject & HasNotification> = ({ event, eventObject, notification }) => {
  switch(eventObject.__typename) {
  case 'Idea':
    if (event.eventType === 'idea.voted') {
      return <FontAwesomeIcon icon={faHeart} />;
    }

    return <Unvote />;
  case 'Announcement':
    if (notification?.transport.__typename === 'SlackAppTransport') {
      return <FontAwesomeIcon icon={faSlack} />;
    }

    return <FontAwesomeIcon icon={faEnvelope} />;
  case 'Feedback':
    return <MessageIcon />;
  case 'ProjectSubscription':
    return <ProjectSubscriptionIndicator event={event} />;
  case 'WorkItem':
    switch (event.eventType) {
      case 'work_item.unsubscribed':
        return <FontAwesomeIcon icon={faBellSlash} />
      case 'work_item.update_added':
        return <FontAwesomeIcon icon={faEnvelope} />;
      default:
        return <FontAwesomeIcon icon={faBell} />
    }
  default:
    return <></>;
  }
};

const scale = chroma.scale(['C92E34', 'E8D784', '2E9E59']).domain([-1, 1]);

const SentimentIcon: FC<HasFeedback> = ({ feedback }) => {
  const { token } = useToken();

  if (!feedback.sentimentScore) {
    return <></>;
  }

  const backgroundColor = scale(feedback.sentimentScore).hex();

  return (
    <Tooltip title={(<Typography.Paragraph>
      This feedback has a sentiment score of {feedback.sentimentScore}
      <br/><br/>
      <Typography.Text style={{ fontSize: token.fontSizeSM }}>
        Scores are between -1 and 1
      </Typography.Text>
    </Typography.Paragraph>)}>
      {/* Style lock: Computed background based on sentiment */}
      <Typography.Text style={{backgroundColor}}></Typography.Text>
    </Tooltip>
  );
};

const EventRowIndicator: FC<HasEvent & HasEventObject & HasNotification> = ({ event, eventObject, notification }) => {
  return (
    <Typography.Text>
      <EventIcon
        eventObject={eventObject}
        notification={notification}
        event={event}
      />
      { eventObject.__typename === 'Feedback' && (
        <SentimentIcon feedback={eventObject} />
      )}
    </Typography.Text>
  );
};

export default EventRowIndicator;