/**
* Turns a list of strings into a string with an ampersand between the last two words.
* @param words - A list of strings to be joined.
* @returns A string with an ampersand between the last two words.
* @example
* listToAmpersand(['a', 'b', 'c']) // 'a, b & c'
* listToAmpersand(['a', '', 'c']) // 'a & c'
* listToAmpersand(['a', 'b']) // 'a & b'
* listToAmpersand(['a']) // 'a'
* listToAmpersand([]) // ''
*/
export const wordsToListAmpersand = (words: string[]): string => {
  if (words.length <= 0) return ''
  const filtered = words.filter(s => s.length > 0)
  return filtered.length > 1
    ? filtered.slice(0, -1).join(', ') + ' & ' + filtered.slice(-1)
    : filtered[0]
}
